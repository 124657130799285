import { initializeApp } from 'firebase/app'
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore'

// s89324324@gmail.com	valerialera21
const firebaseConfig = {
    apiKey: "AIzaSyCsS9gOX92qn8nTDYMk9DEO8L81oYQ-Btg",
    authDomain: "andersen-charity.firebaseapp.com",
    projectId: "andersen-charity",
    storageBucket: "andersen-charity.appspot.com",
    messagingSenderId: "490588497313",
    appId: "1:490588497313:web:2f4844f1af5b46265417e0"
}

initializeApp(firebaseConfig)
const db = getFirestore()

export const getComments = (commentsType) => {

    return getDocs(collection(db, commentsType))
        .then((snapshot) => {
            let comments = []

            snapshot.docs.forEach((doc) => {
                comments.push({ ...doc.data(), id: doc.id })
            })

            return new Promise(resolve => resolve(comments))
        })
        .catch(err => new Promise((resolve, reject) => reject(err.message)))
}

export const addComment = (data, commentsType) => addDoc(collection(db, commentsType), data)
