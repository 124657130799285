<template>
    <div class="comments">
        <template v-if="comments.length">
            <div class="comments-block">
                <h4 class="comments-title">Comments</h4>
                <ul class="comments-list">
                    <li class="comments-comment" v-for="comment in comments" :key="comment.id">
                        <div class="comments-text">
                            <span class="comments-text__name">{{ comment.name }}</span>
                            <span class="comments-text__date">{{ comment.date }}</span>
                            <p class="comments-text__comment">{{ comment.comment }}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </template>
        <div :class="['comments-add', { 'no-comments': !comments.length }]">
            <form
                class="comments-add-wrapper"
                @submit.prevent="addNewComment"
            >
                <input
                    name="name"
                    required
                    type="text"
                    v-model="name"
                    placeholder="Enter your name"
                />
                <textarea
                    name="comment"
                    required
                    type="text"
                    v-model="comment"
                    placeholder="Enter your comment"
                />
                <button
                    type="submit"
                    :disabled="addingComment"
                    :class="['btn comments__btn', { 'comments__adding': addingComment === true }]"
                >
                    {{ addingComment ? 'Adding ...' : 'Add comment' }}
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import { getComments, addComment } from '../helpers/firebase'

export default {
    name: 'Comments',
    props: {
        commentType: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            comments: [],
            isAddCommentModalOpen: false,
            name: '',
            comment: '',
            addingComment: false
        }
    },
    created() {
        this.fetchComments()
    },
    methods: {
        addNewComment() {
            this.addingComment = true
            const dateOptions = {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
            }
            const today  = new Date();
            const date = today.toLocaleDateString("en-US", dateOptions);

            const data = {
                comment: this.comment,
                name: this.name,
                time: Date.now(),
                date,
            }
            addComment(data, this.commentType)
                .then(() => {
                    setTimeout(() => {
                        this.name = ''
                        this.comment = ''
                        this.fetchComments()
                        this.addingComment = false
                    }, 1000)
                })
                .catch((err) => {
                    console.log(err.message, 'error message');
                    this.addingComment = false
                })
        },
        handleOpeningModal() {
            this.isAddCommentModalOpen = true;
        },
        handleClosingModal() {
            this.isAddCommentModalOpen = false;
        },
        fetchComments() {
            getComments(this.commentType)
                .then(data => {
                    this.comments = data.sort((a, b) => b.time - a.time)
                })
        }
    }
}
</script>
