<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-chernihiv-five :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignChernihivFive from "@/components/CampaignResults/CampaignChernihivFive.vue";

export default {
    name: "AboutChernihivFive",
    components: {
        BaseCampaignPage,
        CampaignChernihivFive
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Chernihiv Psychoneurological Hospital",
                    mainText: "We aimed to provide medical care at the same level as in other medical institutions with the help of essential resources needed to reduce the overload of the staff. With that support, hospitals would be able to focus on each patient and ensure they have received the desired emotional support.",
                    subText: "With our common efforts, we were able to modernize the hospital facilities, providing a more comfortable environment for patients. Thanks to reduced overload, the hospital could improve its services, making them more patient-centered with the help of individualized patient treatment plans and emotional support",
                    amount: "4,989",
                },
                commentType: 'chernihiv-five'
            };
        },
    }
};
</script>