<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-regional-center :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignRegionalCenter from "@/components/CampaignResults/CampaignRegionalCenter.vue";

export default {
    name: "AboutRegionalCenter",
    components: {
        BaseCampaignPage,
        CampaignRegionalCenter
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Chernihiv Regional Center for AIDS Prevention and Control",
                    mainText: "Ukrainian doctors show immense courage in the face of military operations. However, the constraints of limited resources have amplified the need for assistance to an unprecedented level. Andersen has responded to this by offering essential help to Chernihiv Regional Center for AIDS Prevention and Control.",
                    subText: "We have helped the clinic with essential consumables. We express our heartfelt gratitude to our donators. By joining forces, we give healthcare professionals the power to provide patients with qualitative care.",
                    amount: "5,100",
                },
                commentType: 'regional-center'
            };
        },
    }
};
</script>