<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignChernihivThird",
    props: {
        isText: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        BaseCampaignResult
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Chernihiv 3rd City Hospital",
                isText: this.isText,
                text: "While the war is in full swing, hospitals require any help more than ever. Economic difficulties, interruptions in the medical equipment functionality, and moral fatigue of medical staff gave rise to a lack of resources and less efficiency ...",
                photosAmount: 10,
                cssClass: 'chernihiv-third',
                path: 'chernihiv-third',
                isVideoAvailable: false,
                videoProps: {
                    photosCount: 21,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15, 16, 17, 18, 19, 20 ],
                    },
                    srcFotos: 'chernihiv-third',
                }
            }
        }
    },
};
</script>
