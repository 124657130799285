<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignCentralHospital",
    components: {
        BaseCampaignResult,
    },
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Chernihiv central regional hospital",
                isText: this.isText,
                text: "The ongoing war in Ukraine has devastating effects on the country's healthcare system. The need for support and assistance from Andersen Charity and all concerned people are more important now than ever before ...",
                photosAmount: 13,
                cssClass: 'central-hospital',
                isVideoAvailable: false,
                path: 'central-hospital',
                videoProps: {
                    photosCount: 18,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15, 16, 17 ],
                    },
                    srcFotos: 'central-hospital',
                }
            }
        }
    }
};
</script>
