<template>
    <div class="private-donators">
        <div class="private-donators__header">
            <span class="header-num">Transaction number</span>
            <span class="header-email">Email</span>
            <span class="header-amount">Amount, EUR</span>
        </div>
        <ul>
            <li v-for="(item, i) in rawData" :key="i">
                <div class="list-num">{{ item.num }}</div>
                <div class="list-email">{{ item.email }}</div>
                <div class="list-amount">{{ item.amount }}</div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'PrivateDonators',
    data() {
        return {
            rawData: [
                { num: 1661758942947, email: 'ol***2@gmail.com', amount: 10 },
                { num: 1661759939802, email: 'co***9@gmail.com', amount: 50 },
                { num: 1660892275086, email: 'ol***k@gmail.com', amount: 10 },
                { num: 1664283695682, email: '-', amount: 50 },
                { num: 1665126239730, email: '-', amount: 20 },
                { num: 1662646118986, email: '-', amount: 10 },
                { num: 1670677245457, email: '-', amount: 100 },
                { num: 1668591659009, email: '-', amount: 100 },
                { num: 1674055425326, email: '-', amount: 600 },
                { num: 1674498920761, email: '-', amount: 620 },
                { num: 1674629868031, email: '-', amount: 10 },
                { num: 1674629906451, email: '-', amount: 100 },
                { num: 1674630138883, email: '-', amount: 200 },
            ]
        }
    }
}
</script>

<style lang="scss">
.private-donators {
    width: 100%;

    &__header {
        background: #F7F8FA;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        padding: 8px 16px;
        color: #353C45;

        span {
            flex: 1;

            &:not(:first-child) {
                padding-left: 16px;
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        max-height: 600px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgb(211, 211, 211);
        }

        li {
            width: 100%;
            font-size: 14px;
            font-weight: 300;
            line-height: 26px;
            color: #353C45;
            border-bottom: 1px solid #E0E0E0;
            display: flex;
            padding: 8px 0;
            padding-left: 16px;

            .list-num,
            .list-email,
            .list-amount {
                flex: 1;
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .private-donators {
        &__header {
            justify-content: space-between;

            span {
                flex: unset;
                padding-left: 0;

                &:first-child {
                    display: none;
                }

                &:not(:first-child) {
                    padding-left: 0;
                }
            }
        }

        ul li {
            justify-content: space-between;

            .list-num {
                display: none;
            }

            .list-date {
                flex: unset;
            }

            .list-amount {
                padding-right: 16px;
                flex: unset;
            }
        }
    }
}
</style>