<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-children :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignChildren from "@/components/CampaignResults/CampaignChildren";

export default {
    name: "AboutChildren",
    components: {
        BaseCampaignPage,
        CampaignChildren
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Cherkasy Children's Hospital",
                    mainText: `
                        Andersen Charity has put a goal of minimizing the needs of the hospital.
                        We focused on helping to cope with increased load and physiologically
                        unstable children with the help of vital medical supplies.`,
                    subText: `Together we could deliver as much as an entire minibus with crucial
                        medical supplies. The raised funds covered such vital things as plaster bandages,
                        inhalers, and dosing devices. The hospital received the needed support and minimized its lack,
                        and can provide more effective care with less stress.`,
                    amount: "4,711",
                },
                commentType: 'children-comments'
            };
        },
    }
};
</script>