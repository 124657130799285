<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-city-polyclinic :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignCityPolyclinic from "@/components/CampaignResults/CampaignCityPolyclinic.vue";

export default {
    name: "AboutCityPolyclinic",
    components: {
        BaseCampaignPage,
        CampaignCityPolyclinic
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Lviv 3d City Polyclinic",
                    mainText: "Ukrainian doctors are courageously caring for both civilians and military personnel despite continuous hostilities. Embracing the fundamental value of human life and recognizing the significance of aid and unity, Andersen has helped Lviv 3d City Polyclinic. Together, we strive to make a positive impact during these challenging times.",
                    subText: "We have provided the clinic with indispensable medical equipment and essential consumables. The unwavering support of our donators fills us with gratitude. By working together, we support healthcare professionals and help patients receive the care they need.",
                    amount: "4,876",
                },
                commentType: 'city-polyclinic'
            };
        },
    }
};
</script>