<template>
    <div class="donators">
        <div class="container">
            <h3>Our Donators</h3>
            <div class="donators-toggle">
                <span
                    @click="currentComponent = 'PrivateDonation'"
                    :class="{ 'active-option': currentComponent === 'PrivateDonation' }"
                >Our private donation</span>
                <span
                    :class="{ 'active-option': currentComponent === 'BusinessDonation' }"
                    @click="currentComponent = 'BusinessDonation'"
                >Our business donation</span>
            </div>
            <component class="donators-current" :is="currentComponent" />
        </div>
    </div>
</template>

<script>
import PrivateDonation from './PrivateDonation.vue'
import BusinessDonation from './BusinessDonation.vue'

export default {
    name: 'OurDonators',
    components: {
        PrivateDonation,
        BusinessDonation
    },
    data() {
        return {
            currentComponent: 'PrivateDonation'
        }
    }
}
</script>

<style lang="scss">
.donators {
    padding-bottom: 150px;

    .container {
        margin: 0 auto;
        max-width: 1134px;
    }

    h3 {
        font-weight: 400;
        font-size: 32px;
        margin-top: 0;
    }

    &-toggle span {
        font-weight: 500;
        font-size: 16px;
        text-transform: uppercase;
        margin-right: 40px;
        padding-bottom: 8px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
    }

    .active-option {
        border-bottom: 2px solid #feda00;
    }

    &-current {
        margin-top: 40px;
    }
}

@media screen and (max-width: 1200px) {
    .container {
        padding: 0 20px;
    }
}

@media screen and (max-width: 650px) {
    .donators {
        &-toggle {
            display: flex;
            justify-content: space-between;

            span {
                font-size: 14px;
                margin-right: 0;
            }
        }
    }
}

@media screen and (max-width: 490px) {
    .donators {
        padding-bottom: 100px;

        &-toggle {
            span {
                font-size: 11px;
            }
        }

        &-current {
            margin-top: 20px;
        }
    }
}
</style>