var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isOpenVideo)?_c('div',{staticClass:"slider__modal-video"},[_c('div',{staticClass:"slider__modal-video__content"},[_c('div',{staticClass:"content-wrapper"},[_c('button',{staticClass:"slider__close-button white-button",on:{"click":_vm.closeModal}},[_c('img',{attrs:{"src":require("@/assets/icons/close-icon.svg")}})]),(_vm.activeFoto === 0 && _vm.videoLink)?_c('iframe',{staticClass:"slider__video",attrs:{"src":_vm.videoLink,"title":"YouTube video player","frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}):_c('div',{staticClass:"slider__video-photos",style:(`background-image: url('${_vm.getImage(_vm.activeFoto)}')`)})]),_c('div',{staticClass:"slider__photos"},[_c('div',{staticClass:"slider__photos-container"},[(_vm.activeFoto <= 6)?_vm._l((_vm.fotos.firstFotos),function(foto){return _c('active-foto',{key:foto,class:{
                            'slider__photos-item--active':
                                _vm.activeFoto === foto,
                        },attrs:{"type":_vm.srcFotos,"foto":foto},on:{"setActiveFoto":_vm.setActiveFoto}},[(foto === 0 && _vm.videoLink)?_c('svg',{attrs:{"width":"28","height":"28","viewBox":"0 0 28 28","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"opacity":"0.9","x":"28","width":"28","height":"28","rx":"14","transform":"rotate(90 28 0)","fill":"#F3F5F7"}}),_c('path',{attrs:{"d":"M18.0309 12.8328L13.1122 10.0317C12.846 9.8791 12.544 9.79916 12.2366 9.79999C11.9293 9.80083 11.6276 9.88243 11.3623 10.0365C11.097 10.1905 10.8775 10.4116 10.7261 10.6772C10.5746 10.9427 10.4967 11.2434 10.5001 11.5485V17.1708C10.5001 17.6294 10.6836 18.0692 11.0102 18.3935C11.3367 18.7178 11.7797 18.9 12.2415 18.9C12.5472 18.8995 12.8475 18.8193 13.1122 18.6674L18.0309 15.8664C18.2952 15.7145 18.5146 15.4963 18.6672 15.2336C18.8197 14.9709 18.9 14.673 18.9 14.3698C18.9 14.0666 18.8197 13.7687 18.6672 13.506C18.5146 13.2433 18.2952 13.0251 18.0309 12.8732V12.8328Z","fill":"#353C45"}})]):_vm._e()])}):_vm._e(),(_vm.activeFoto > 6 && _vm.activeFoto < 14)?_vm._l((_vm.fotos.secondFotos),function(foto){return _c('active-foto',{key:foto,class:{
                            'slider__photos-item--active':
                                _vm.activeFoto === foto,
                        },attrs:{"type":_vm.srcFotos,"foto":foto},on:{"setActiveFoto":_vm.setActiveFoto}})}):_vm._e(),(_vm.activeFoto > 13)?_vm._l((_vm.fotos.thirdFotos),function(foto){return _c('active-foto',{key:foto,class:{
                            'slider__photos-item--active':
                                _vm.activeFoto === foto,
                        },attrs:{"type":_vm.srcFotos,"foto":foto},on:{"setActiveFoto":_vm.setActiveFoto}})}):_vm._e()],2),_c('div',{staticClass:"slider__photos-actions"},[_c('span',{staticClass:"slider__photos-actions--count"},[_vm._v(" "+_vm._s(_vm.activeFoto + 1)+" of "+_vm._s(_vm.photosCount)+" ")]),_c('button',{staticClass:"slider__photos-actions--left",attrs:{"disabled":_vm.activeFoto === 0},on:{"click":function($event){return _vm.moveToClosestFoto('left')}}},[(_vm.activeFoto === 0)?_c('svg',{attrs:{"width":"7","height":"12","viewBox":"0 0 7 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"opacity":"0.3","d":"M5.49999 1.49999L0.99997 6L5.49999 10.5","stroke":"#353C45","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]):_vm._e(),(_vm.activeFoto > 0)?_c('svg',{attrs:{"width":"7","height":"12","viewBox":"0 0 7 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M5.49999 1.49999L0.99997 6L5.49999 10.5","stroke":"#353C45","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]):_vm._e()]),_c('button',{staticClass:"slider__photos-actions--right",attrs:{"disabled":_vm.activeFoto === _vm.photosCount - 1},on:{"click":function($event){return _vm.moveToClosestFoto('right')}}},[(_vm.activeFoto === _vm.photosCount - 1)?_c('svg',{attrs:{"width":"7","height":"12","viewBox":"0 0 7 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"opacity":"0.3","d":"M1.50001 1.49999L6.00003 6L1.50001 10.5","stroke":"#353C45","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]):_vm._e(),(_vm.activeFoto < _vm.photosCount - 1)?_c('svg',{attrs:{"width":"7","height":"12","viewBox":"0 0 7 12","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M1.50001 1.49999L6.00003 6L1.50001 10.5","stroke":"#353C45","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]):_vm._e()])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }