<template>
  <div id="app">
    <the-header />
    <div :class="['page-container', { 'bg-grey': !isMainRoute }]">
      <router-view />
    </div>
  </div>
</template>

<script>
import TheHeader from "./components/TheHeader";

export default {
  name: "App",
  components: {
    TheHeader,
  },
  computed: {
    isMainRoute() {
      return this.$route.path === "/";
    },
  },
};
</script>

<style lang="scss">
@import "./styles/main.scss";
@import "./styles/the-header.scss";

.bg-grey {
  background-color: #f5f5f5;
}

.page-container {
  padding-top: 75px;
}
</style>
