<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-fourth-lviv :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignFourthLviv from "@/components/CampaignResults/CampaignFourthLviv.vue";

export default {
    name: "AboutFourthLviv",
    components: {
        BaseCampaignPage,
        CampaignFourthLviv
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for  Lviv 4th City Hospital",
                    mainText: `We should remember that suffering from pain and a feeling of life inferiority is no less crucial than the process of recovery. As a result, our goal remains the same: together with everyone, we dream of an accessible healthy future.`,
                    subText: `Andersen Charity has successfully delivered medical equipment, of which the hospital used to dream. It's insufferable to see people with no chance of recovery. With the help of our funds raised, the hospital can relieve suffering, alleviate pain, and give hope for a happy carefree life, even when there are no chances to be healthy again.`,
                    amount: "4,998",
                },
                commentType: 'fourth-lviv-comments'
            };
        },
    }
};
</script>