<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-second :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignSecond from "@/components/CampaignResults/CampaignSecond.vue";

export default {
    name: "AboutChernihivSecond",
    components: {
        BaseCampaignPage,
        CampaignSecond
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Chernihiv Second Hospital",
                    mainText: "Andersen Charity has been aimed at improving hospital needs, especially those that arose as a result of the large-scale war. We focused on helping everyone who tries to give himself completely to his work to save more lives.",
                    subText: "We are very happy to provide the hospital with all the required medicines and medical supplies. We truly believe that any help is a huge step towards the successful functioning of the hospital and a healthy future for Ukraine. Every donation is much appreciated.",
                    amount: "4,846",
                },
                commentType: 'chernihiv-second'
            };
        },
    }
};
</script>