var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"footer__container"},[_c('div',{staticClass:"footer__common-info"},[_vm._m(0),_vm._m(1),(false)?_c('div',{staticClass:"footer__socials"},[_vm._m(2),_vm._m(3),_vm._m(4)]):_vm._e(),_vm._m(5)]),_c('div',{staticClass:"footer__common-info--mobile"},[_vm._m(6),(false)?_c('div',{staticClass:"footer__socials"},[_vm._m(7),_vm._m(8),_vm._m(9)]):_vm._e(),_vm._m(10),_c('hr'),_vm._m(11)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__common-info--first"},[_c('a',{attrs:{"href":"/"}},[_c('img',{staticClass:"andersen-logo",attrs:{"src":require("@/assets/cherity_logo/svg/horizontal_standart_footer.svg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__common-info--third"},[_c('p',{staticClass:"footer-cooperate"},[_vm._v("© 2022 Andersen Charity")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":" https://instagram.com/andersen_charity_?igshid=NDBlY2NjN2I=","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/socials/instagram.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://twitter.com/andersenfund","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/socials/twitter.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":" https://www.facebook.com/andersencharity/","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/socials/facebook.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__common-info--second"},[_c('p',{staticClass:"footer-number"},[_vm._v("suncharity@andersenlab.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__common-info--first"},[_c('a',{attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("@/assets/cherity_logo/svg/vertical_standart.svg")}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":" https://instagram.com/andersen_charity_?igshid=NDBlY2NjN2I=","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/socials/instagram.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://twitter.com/andersenfund","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/socials/twitter.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":" https://www.facebook.com/andersencharity/","target":"_blank"}},[_c('img',{attrs:{"src":require("@/assets/socials/facebook.svg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__common-info--second"},[_c('p',{staticClass:"footer-number"},[_vm._v("suncharity@andersenlab.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__common-info--third"},[_c('p',{staticClass:"footer-cooperate"},[_vm._v("© 2022 Andersen Charity")])])
}]

export { render, staticRenderFns }