<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignMaternityChernihiv",
    components: {
        BaseCampaignResult,
    },
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Chernihiv Maternity Hospital",
                isText: this.isText,
                text: `
                    When a child is sick, it is always a hard trial for parents.
                    But an even more terrible blow for a mother is the birth of a premature baby.
                    Doctors are doing everything in their power to save the life of every newborn.
                    But in the conditions of war, the supply of hospitals leaves much to be desired.
                    Therefore, any help from those who are not indifferent is truly invaluable ...`,
                photosAmount: 18,
                cssClass: 'maternity-chernihiv',
                path: 'maternity-chernihiv',
                videoProps: {
                    photosCount: 21,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15, 16, 17, 18, 19, 20 ],
                    },
                    srcFotos: 'maternity-chernihiv',
                    videoLink: 'https://www.youtube.com/embed/xe1JIzOtq8Q'
                }
            }
        }
    }
};
</script>
