<template>
    <footer class="footer">
        <div class="footer__container">
            <div class="footer__common-info">
                <div class="footer__common-info--first">
                    <a href="/">
                        <img class="andersen-logo" src="@/assets/cherity_logo/svg/horizontal_standart_footer.svg" />
                    </a>
                </div>
                <div class="footer__common-info--third">
                    <p class="footer-cooperate">© 2022 Andersen Charity</p>
                </div>
                <div v-if="false" class="footer__socials">
                    <a
                        href=" https://instagram.com/andersen_charity_?igshid=NDBlY2NjN2I="
                        target="_blank"
                    >
                        <img src="@/assets/socials/instagram.svg" />
                    </a>
                    <a href="https://twitter.com/andersenfund" target="_blank">
                        <img src="@/assets/socials/twitter.svg" />
                    </a>
                    <a
                        href=" https://www.facebook.com/andersencharity/"
                        target="_blank"
                    >
                        <img src="@/assets/socials/facebook.svg" />
                    </a>
                </div>
                <div class="footer__common-info--second">
                    <p class="footer-number">suncharity@andersenlab.com</p>
                </div>
            </div>
            <div class="footer__common-info--mobile">
                <div class="footer__common-info--first">
                    <a href="/">
                        <img src="@/assets/cherity_logo/svg/vertical_standart.svg" />
                    </a>
                </div>
                <div v-if="false" class="footer__socials">
                    <a
                        href=" https://instagram.com/andersen_charity_?igshid=NDBlY2NjN2I="
                        target="_blank"
                    >
                        <img src="@/assets/socials/instagram.svg" />
                    </a>
                    <a href="https://twitter.com/andersenfund" target="_blank">
                        <img src="@/assets/socials/twitter.svg" />
                    </a>
                    <a
                        href=" https://www.facebook.com/andersencharity/"
                        target="_blank"
                    >
                        <img src="@/assets/socials/facebook.svg" />
                    </a>
                </div>
                <div class="footer__common-info--second">
                    <p class="footer-number">suncharity@andersenlab.com</p>
                </div>
                <hr>
                <div class="footer__common-info--third">
                    <p class="footer-cooperate">© 2022 Andersen Charity</p>
                </div>
            </div>
            <!-- <div class="footer-hr" />
            <div class="footer__transfer-info">
                <div class="footer__transfer-info--requisites-first">
                    <div class="mb-20">
                        <p class="requisites-title">Beneficiary</p>
                        <div
                            class="requisites-info-wrapper w-116"
                            @click="copyRequisites('beneficiary')"
                        >
                            <span ref="beneficiary" class="requisites-info">
                                Charity organization «Charitable Foundation «Sun
                                Charity»
                            </span>
                            <copy-icon-svg />
                        </div>
                    </div>
                    <div>
                        <p class="requisites-title">Address</p>
                        <div
                            class="requisites-info-wrapper"
                            @click="copyRequisites('address')"
                        >
                            <span ref="address" class="requisites-info">
                                Ukraine, 18001, Cherkasy region, city of
                                Cherkasy, <br> street Dashkovich Ostafiya, house 3
                            </span>
                            <copy-icon-svg />
                        </div>
                    </div>
                </div>
                <div class="footer__transfer-info--requisites-second">
                    <div class="mb-20">
                        <p class="requisites-title">FOUNDATION IBAN</p>
                        <div
                            class="requisites-info-wrapper w-113"
                            @click="copyRequisites('iban-eur')"
                        >
                            <span ref="iban-eur" class="requisites-info">
                                UA863510050000026008879147764 (EUR)
                            </span>
                            <copy-icon-svg />
                        </div>
                        <div
                            class="requisites-info-wrapper w-113"
                            @click="copyRequisites('iban-uah')"
                        >
                            <span ref="iban-uah" class="requisites-info">
                                UA683510050000026009879147763 (UAH)
                            </span>
                            <copy-icon-svg />
                        </div>
                    </div>
                    <div class="mb-20">
                        <p class="requisites-title">
                            Name of the bank: UKRSIBBANK
                        </p>
                        <div
                            class="requisites-info-wrapper"
                            @click="copyRequisites('bankName')"
                        >
                            <span ref="bankName" class="requisites-info">
                                Andriivska str 2/12, Kyiv, Ukraine
                            </span>
                            <copy-icon-svg />
                        </div>
                    </div>
                    <div>
                        <p class="requisites-title">UKRSIBBANK SWIFT</p>
                        <div
                            class="requisites-info-wrapper"
                            @click="copyRequisites('swift')"
                        >
                            <span ref="swift" class="requisites-info">
                                KHABUA2K
                            </span>
                            <copy-icon-svg />
                        </div>
                    </div>
                </div>
                <div class="footer__transfer-info--requisites-third">
                    <div class="mb-20">
                        <p class="requisites-title">Correspondent bank</p>
                        <div
                            class="requisites-info-wrapper"
                            @click="copyRequisites('correspondentBank')"
                        >
                            <span
                                ref="correspondentBank"
                                class="requisites-info"
                            >
                                BNP PARIBAS SA Paris, France
                            </span>
                            <copy-icon-svg />
                        </div>
                    </div>
                    <div>
                        <p class="requisites-title">
                            Correspondent's bank SWIFT-code
                        </p>
                        <div
                            class="requisites-info-wrapper"
                            @click="copyRequisites('correspondentSwiftCode')"
                        >
                            <span
                                ref="correspondentSwiftCode"
                                class="requisites-info"
                            >
                                BNPAFRPP
                            </span>
                            <copy-icon-svg />
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    methods: {
        async copyRequisites(element) {
            try {
                await navigator.clipboard.writeText(
                    this.$refs[element].innerText
                )
            } catch {
                console.log('Cannot copy')
            }
        }
    }
}
</script>
