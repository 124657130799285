<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignOnco",
    components: {
        BaseCampaignResult,
    },
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Cherkasy Regional Oncological Clinic",
                isText: this.isText,
                text: `After the beginning of the war, the load increased.
                    The hospital provides more than ever surgical operations per day and
                    needs help to cope with all the difficulties with less stress and more quality care ...`,
                photosAmount: 18,
                cssClass: 'onco',
                path: 'onco',
                videoProps: {
                    photosCount: 21,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15, 16, 17, 18, 19, 20 ],
                    },
                    srcFotos: 'onco-hospital',
                    videoLink: 'https://www.youtube.com/embed/3Bs2GW2XrXQ'
                }
            }
        }
    }
};
</script>
