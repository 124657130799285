<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-children-regional :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignChildrenRegional from "@/components/CampaignResults/CampaignChildrenRegional";

export default {
    name: "AboutChildrenRegional",
    components: {
        BaseCampaignPage,
        CampaignChildrenRegional
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Regional Children's Hospital",
                    mainText: `We have set ourselves a goal - to minimize overload in the hospital and increase its efficiency with the help of modern health devices. Raised funds are aimed at children suffering from the consequences of all tragedies during this scary time and will be a support in the recovery process.`,
                    subText: `Andersen has delivered as much as an entire minibus of medical supplies and devices to help the hospital to provide high-quality medical care. Together we have done a good deed and encouraged their medical staff. Times are impossible now, but with small steps, we can achieve global goals such as a peaceful and healthy world.`,
                    amount: "5,013",
                },
                commentType: 'children-regional'
            };
        },
    }
};
</script>