<template>
    <div class="swift-payment">
        <div class="swift-payment__text-wrapper">
            <div class="swift-payment__title">
                <span>Charity organization</span>
            </div>
            <p>Charitable Foundation «Sun Charity»</p>
        </div>
        <div class="swift-payment__text-wrapper">
            <div class="swift-payment__title">
                <span>Acc No</span>
            </div>
            <p>
                UA863510050000026008879147764 (EUR) <br>
                UA683510050000026009879147763 (UAH) <br>
                Ukraine, 18001, Cherkasy region, city of Cherkasy, street Dashkovich Ostafiya, house 3
            </p>
        </div>
        <div class="swift-payment__text-wrapper">
            <div class="swift-payment__title">
                <span>Bank</span>
            </div>
            <p>
                UKRSIBBANK <br>
                Andriivska str 2/12, Kyiv, Ukraine
            </p>
        </div>
        <div class="swift-payment__text-wrapper">
            <div class="swift-payment__title">
                <span>SWIFT</span>
            </div>
            <p>KHABUA2K</p>
        </div>
        <div class="swift-payment__text-wrapper">
            <div class="swift-payment__title">
                <span>Correspondent bank</span>
            </div>
            <p>
                BNP PARIBAS SA <br>
                Paris, France
            </p>
        </div>
        <div class="swift-payment__text-wrapper">
            <div class="swift-payment__title">
                <span>SWIFT</span>
            </div>
            <p>BNPAFRPP</p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
.swift-payment {
    padding: 40px 0 116px 0;

    &__text-wrapper {
        display: flex;
        margin-bottom: 16px;

        p {
            margin: 0;
            padding: 0;
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
        }
    }

    &__title {
        width: 20%;

        span {
            font-weight: 400;
            font-size: 14px;
            color: #647282;
        }
    }
}

@media screen and (max-width: 1100px) {
    .swift-payment {
        padding: 20px 0 40px 0;

        &__text-wrapper {
            flex-direction: column;
            line-height: 24px;
        }
    }
}
</style>
