<template>
    <div class="feature" v-animate-onscroll="'animated fadeInLeft'">
        <img
            :src="require(`@/assets/features/${iconLink}`)"
            alt="icon"
            class="feature__icon"
        />
        <div>
            <h4 class="feature__title title title--h4">{{ title }}</h4>
            <p class="feature__text text">{{ text }}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Feature',
    props: ['iconLink', 'title', 'text']
}
</script>

<style scoped lang="scss">
.feature {
    grid-column: span 7;
    background-color: white;
    margin-top: 5px;
    padding: 35px;
    display: flex;
    align-items: center;
    box-shadow: 0px 0px 52px rgba(154, 168, 196, 0.16);
    border-radius: 16px;

    &:nth-child(4) {
        z-index: 2;
    }

    img {
        width: 145px;
        height: 145px;
        margin-right: 40px;
    }

    &__title {
        margin-top: 10px !important;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        letter-spacing: -0.02em;
        color: #353c45;
    }

    &__subtitle {
        font-style: normal;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 20px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        margin-top: 0;
        color: #9ca8b6;
    }
}

.feature__title {
    margin: 40px 0 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: -0.02em;
    color: #353c45;
    margin-bottom: 10px;
}

.feature__icon {
    max-width: 283px;
}

.feature__text {
    margin: 0;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #647282;
}

@media screen and (max-width: 376px) {
    .feature {
        grid-column: initial;
        max-width: 100%;
        display: block;
        padding-top: 40px;

        &:nth-child(n + 2) {
            margin-top: 15px;
        }

        img {
            margin: 0 auto;
            display: block;
            margin-bottom: 30px;
        }
    }
}

@media screen and (max-width: 950px) {
    .feature {
        grid-column: span 15;
        max-width: 100%;
    }
}

@media screen and (max-width: 640px) {
    .feature {
        grid-column: span 15;
        max-width: 100%;
        display: block;

        img {
            margin: 0 auto;
            display: block;
            margin-bottom: 20px;
        }
    }
}

@media screen and (max-width: 640px) {
    .feature {
        grid-column: span 14;
    }
}

@media screen and (max-width: 590px) {
    .feature {
        grid-column: span 14;
    }
}

@media screen and (max-width: 540px) {
    .feature {
        max-width: 100%;
        display: block;
    }
}

@media screen and (max-width: 470px) {
    .feature {
        grid-column: span 13;
        max-width: 100%;
        display: block;
    }
}

@media screen and (max-width: 440px) {
    .feature {
        grid-column: span 12;
        max-width: 100%;
        display: block;
    }
}

@media screen and (max-width: 440px) {
    .feature {
        grid-column: span 11;
    }
}
</style>
