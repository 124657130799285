<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignSixthLviv",
    components: {
        BaseCampaignResult,
    },
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for  Lviv 6th City Clinic",
                isText: this.isText,
                text: 'Due to the ongoing hostilities, Ukrainian clinics are still operating under an increased load. They treat both regular patients and victims of war. Today, Andersen has delivered consumables to a clinic from Lviv ...',
                photosAmount: 15,
                cssClass: 'sixth-lviv',
                isVideoAvailable: false,
                path: 'sixth-lviv',
                videoProps: {
                    photosCount: 19,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15, 17, 18 ],
                    },
                    srcFotos: 'sixth-lviv',
                }
            }
        }
    }
};
</script>
