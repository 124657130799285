<template>
    <div id="checkout-container" />
</template>

<script>
export default {
    name: 'Payment',
    mounted() {
        if (!this.$route.params.paymentDetails) {
            this.$router.go(-1)
            return
        }
        fondy("#checkout-container", this.$route.params.paymentDetails)
            .$on("success", function(model) {
                console.log('success!');
            })
            .$on("error", function(model) {
                alert('Something went wrong!')
                setTimeout(() => {
                    this.$router.go(-1)
                }, 2000)
            });
    }
}
</script>
