<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-chernihiv-second :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignChernihivSecond from "@/components/CampaignResults/CampaignChernihivSecond.vue";

export default {
    name: "AboutSecondChernihiv",
    components: {
        BaseCampaignPage,
        CampaignChernihivSecond
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Chernihiv 2nd City Hospital",
                    mainText: "Andersen Charity strives to make the lives of Ukrainians a bit more comfortable during these harsh times. While military forces are fighting for our Motherland, our doctors are saving lives of civilians and wounded soldiers. Now, they are working harder than ever before. And they need help. Today, we have delivered humanitarian aid to a hospital in Chernihiv.",
                    subText: "We are happy to provide the hospital with medical devices and consumables. Those are never enough during the war. We express our heartfelt gratitude to all donators who help us in our charity campaign. Together, we are fighting for people's lives and bringing a better future closer.",
                    amount: "4,988",
                },
                commentType: 'chernihiv-city-second'
            };
        },
    }
};
</script>