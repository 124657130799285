<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignMaternityLviv",
    components: {
        BaseCampaignResult,
    },
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Clinical Medical of Obstetrics and Gynecology of Lviv",
                isText: this.isText,
                text: `
                    A short while ago we visited the Clinical Territorial Medical Association of Obstetrics and Gynecology of Lviv to donate some important equipment to them.
                    Our donation included consumables and such an important piece of medical equipment as the Alaris Syringe Module ...`,
                photosAmount: 13,
                cssClass: 'maternity-lviv',
                path: 'maternity-lviv',
                videoProps: {
                    photosCount: 16,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15 ],
                    },
                    srcFotos: 'maternity-lviv',
                    videoLink: 'https://www.youtube.com/embed/rRdee4aubBo'
                    // https://www.youtube.com/watch?v=rRdee4aubBo&ab_channel=AndersenPeople
                }
            }
        }
    }
};
</script>
