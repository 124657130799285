<template>
  <div class="charity-call__mobile-title">
    <h1>
      Andersen company
      <br />
      Charity Foundation
    </h1>
    <div>
      <p class="charity-call__raised">Total raised <span>€215,980</span></p>
      <button @click="isModalOpen = true" class="btn charity-call__btn">
        Donate
      </button>
    </div>
    <a
      href="https://andersenlab.com/"
      target="_blank"
      id="andersen-link"
      class="charity-call__andersen-link link"
    >
      <img src="@/assets/andersen-logo.svg" /> Founded by Andersen
    </a>
    <payment-modal :isOpen="isModalOpen" @closeModal="closePaymentModal" />
  </div>
</template>

<script>
import PaymentModal from "../../modals/PaymentModal.vue";

export default {
  name: "CharityCallMobileTitle",
  components: { PaymentModal },
  data() {
    return {
      isModalOpen: false,
    };
  },
  methods: {
    closePaymentModal() {
      this.isModalOpen = false;
    },
  },
};
</script>

<style lang="scss">
.charity-call__mobile-title {
  .charity-call__andersen-link {
    margin-top: 45px;
  }

  .charity-call__btn {
    width: 100%;
  }
}
</style>
