<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-maternity-chernihiv :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignMaternityChernihiv from "@/components/CampaignResults/CampaignMaternityChernihiv";

export default {
    name: "AboutMaternityLviv",
    components: {
        BaseCampaignPage,
        CampaignMaternityChernihiv
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Chernihiv Maternity Hospital",
                    mainText: `
                        The Andersen Charity has set itself the goal of meeting the current needs of the hospital.
                        We focused on saving the lives of children, and on the advice of doctors, we provided a special device for nursing premature babies,
                        as well as the necessary consumables.
                    `,
                    subText: `
                        We delivered a whole minibus with the necessary medicines.
                        The hospital received consumables and a special device for nursing premature babies.
                        Thus, we have satisfied the current needs of the medical staff.`,
                    amount: "5,010",
                },
                commentType: 'maternity-chernihiv-comments'
            };
        },
    }
};
</script>