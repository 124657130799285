<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-city :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignCity from "@/components/CampaignResults/CampaignCity";

export default {
    name: "AboutCity",
    components: {
        BaseCampaignPage,
        CampaignCity
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Cherkasy Second City Hospital",
                    mainText: ` Treatment is vital, but the recovery process is not less crucial.
                        Andersen focused on improving people's mobility with motor dysfunctions.
                        We aimed at distributing new care and recovery methods to make the hospital more up-to-date.`,
                    subText: `We gave the hospital new recovery systems and equipment such as a  cabin for kinesitherapy,
                            an adjustable wheelchair, and recovery bars. The cabin (spider) can help people stand again on
                            their feet and strengthen muscles to be more mobile. The equipment will improve the recovery
                            process of people after stroke and those who suffered from war.`,
                    amount: "4,750",
                },
                commentType: 'city-hospital'
            };
        },
    }
};
</script>