<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignChernihivFive",
    props: {
        isText: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        BaseCampaignResult
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Chernihiv Psychoneurological Hospital",
                isText: this.isText,
                text: "Individuals with mental health issues require the same care but with an individual approach. That’s why such hospitals require more support than ever to be able to overcome all challenges...",
                photosAmount: 12,
                cssClass: 'chernihiv-fourth',
                path: 'chernihiv-fourth',
                isVideoAvailable: false,
                videoProps: {
                    photosCount: 15,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14 ],
                    },
                    srcFotos: 'chernihiv-fourth',
                }
            }
        }
    },
};
</script>
