<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignMedicalCenter",
    props: {
        isText: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        BaseCampaignResult
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Lviv Military Medical Center of the State Border Guard Service of Ukraine",
                isText: this.isText,
                text: "Board guards are those people who first faced the war and kept standing for their homeland since the very beginning. It's our turn to support them...",
                photosAmount: 15,
                cssClass: 'medical-center',
                path: 'medical-center',
                isVideoAvailable: false,
                videoProps: {
                    photosCount: 18,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15, 16, 17, 18 ],
                    },
                    srcFotos: 'medical-center',
                }
            }
        }
    },
};
</script>
