<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignChernihivSecond",
    components: {
        BaseCampaignResult,
    },
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Chernihiv 2nd City Hospital",
                isText: this.isText,
                text: 'The war has drastically affected Ukrainian hospitals and clinics. They have many more patients and desperately need more equipment, devices, and consumables. Today, Andersen Charity is heading to a hospital in Chernihiv ...',
                photosAmount: 10,
                cssClass: 'chernihiv-city-second',
                isVideoAvailable: false,
                path: 'chernihiv-city-second',
                videoProps: {
                    photosCount: 14,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                    },
                    srcFotos: 'chernihiv-city-second',
                }
            }
        }
    }
};
</script>
