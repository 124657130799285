<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-children-center :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignChildrenCenter from "@/components/CampaignResults/CampaignChildrenCenter.vue";

export default {
    name: "AboutChildrenCenter",
    components: {
        BaseCampaignPage,
        CampaignChildrenCenter
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Western Ukrainian Specialized Children’s Medical Center",
                    mainText: `Andersen Charity has set itself the goal of improving the quality of medical care in Ukrainian hospitals. We have sought to help the center, which is now accepting lots of children across the entire region, to cope with all difficulties on the way: overload, lack of supplies, and overstressed staff.`,
                    subText: `Together we have helped to make many more children's lives a little bit happier and their parents more confident in their abilities. Andersen Charity has delivered a huge set of medical supplies to the center to support its staff in the struggle for children's survival. Children are our future. Let's support our heritage!`,
                    amount: "5,024",
                },
                commentType: 'children-center-comments'
            };
        },
    }
};
</script>