<template>
    <div>
        <charity-call />
        <charity-call-mobile-title />
        <about-section />
        <campaign-results />
        <!-- <hospital-block/> -->
        <what-we-do />
        <nft />
        <quote />
        <foundation-details />
        <our-donators />
        <the-footer />
    </div>
</template>

<script>
import CharityCall from '@/components/CharityCall/CharityCall.vue'
import CharityCallMobileTitle from '@/components/CharityCall/CharityCallMobileTitle.vue'
import AboutSection from '../components/AboutSection.vue'
import CampaignResults from '@/components/CampaignResults/CampaignResults.vue'
import HospitalBlock from '@/components/HospitalBlock.vue'
import WhatWeDo from '@/components/WhatWeDo.vue'
import Nft from '@/components/Nft.vue'
import Quote from '@/components/Quote.vue'
import FoundationDetails from '@/components/FoundationDetails/FoundationDetails.vue'
import OurDonators from '@/components/Donators/OurDonators.vue'
import TheFooter from '@/components/TheFooter.vue'

const VueScrollTo = require('vue-scrollto');

export default {
    name: 'MainPage',
    components: {
        CharityCall,
        CharityCallMobileTitle,
        AboutSection,
        CampaignResults,
        HospitalBlock,
        WhatWeDo,
        Nft,
        Quote,
        FoundationDetails,
        OurDonators,
        TheFooter
    },
    data() {
        return {
            hospitals: [
                { id: 0, class: "default", title: "Children Hospital", image: '3.png' },
                { id: 1, class: "right", title: "Maternity Hospital", image: '2.png' },
                { id: 2, class: "left", title: "Onkological Dispensary", image: '1.png' },
                // { id: 3, class: "on-hidden", title: "Onkological Dispensary", image: '4.jpg' }
            ],
            defaultHospital: 0,
        }
    },
    mounted() {
        if (this.$route.params.linkTo) {
            setTimeout(() => {
                VueScrollTo.scrollTo(this.$route.params.linkTo, 600, this.links[this.$route.params.linkTo].options)
            }, 500)
        }
        this.changeFavicon("https://www.andersencharity.com/favicon.ico");
    },
    computed: {
        links() {
            return {
                '#about': {
                    options: {
                        container: 'body',
                        easing: 'linear',
                        offset: -300,
                    }
                },
                '#results': { options: this.resultsScroll },
                '#what-we-do': { options: this.whatWeDoScroll },
                '#nft': { options: this.nftScroll },
                '#details': {
                    options: {
                        container: 'body',
                        easing: 'linear',
                        offset: -150,
                    }
                },
            }
        },
        whatWeDoScroll() {
            if (window.innerWidth <= 1440) {
                return {
                    container: 'body',
                    easing: 'linear',
                    offset: 0,
                }
            }

            return {
                container: 'body',
                easing: 'linear',
                offset: -100,
            }
        },
        nftScroll() {
            if (window.innerWidth <= 1440) {
                return {
                    container: 'body',
                    easing: 'linear',
                    offset: -280,
                }
            }

            return {
                container: 'body',
                easing: 'linear',
                offset: -370
            }
        },
        resultsScroll() {
            if (window.innerWidth <= 1440) {
                return {
                    container: 'body',
                    easing: 'linear',
                    offset: 50,
                }
            }

            return {
                container: 'body',
                easing: 'linear',
                offset: -50
            }
        }
    },
    methods: {
        navigate(type){
            if(type === 'left') {
                if(this.defaultHospital !== 0) {
                    this.defaultHospital--
                }
            } else {
                if(this.defaultHospital !== this.hospitals.length - 1) {
                    this.defaultHospital++
                }
            }
        },
        changeFavicon(src) {
            const link = document.createElement('link');
            const oldLink = document.getElementById('dynamic-favicon');
            link.id = 'dynamic-favicon';
            link.rel = 'shortcut icon';
            link.href = src;
            if (oldLink) {
                document.head.removeChild(oldLink);
            }
            document.head.appendChild(link);
        }
    }
}
</script>

<style lang="scss">
@import '../styles/sections/index.scss';
</style>
