<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-family :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignFamily from "@/components/CampaignResults/CampaignFamily";

export default {
    name: "AboutOnco",
    components: {
        BaseCampaignPage,
        CampaignFamily
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Chernihiv Family Hospital",
                    mainText: `Andersen Charity has aimed at improving the clinic's efficiency in recovery processes. We have tried to cover every kind of treatment and consider the hospital's impressive capacity. With the support of medical supplies, it will be able to speed up the treatment processes and increase the recovery rate.`,
                    subText: `With the help of donators, Andersen Charity has successfully delivered a lot of consumables and disposable items. Thanks to our manipulation tables, surgical screen, and blood pressure monitors, the clinic doesn't anymore lack medical supplies during these scary times. We delivered what is needed today to make different kinds of treatment successful.`,
                    amount: "4,958",
                },
                commentType: 'family-comments'
            };
        },
    }
};
</script>