<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignPrinceLeo",
    components: {
        BaseCampaignResult,
    },
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Lviv clinic of Prince Leo",
                isText: this.isText,
                text: 'War, suffering, and death are new realities that made the hospitals work in a different way. The medical profession obliges to work not only in peacetime but in harsh conditions. Andersen is here to support the clinic ...',
                photosAmount: 17,
                cssClass: 'prince-leo',
                isVideoAvailable: false,
                path: 'prince-leo',
                videoProps: {
                    photosCount: 21,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15, 17, 18, 19, 20 ],
                    },
                    srcFotos: 'prince-leo',
                }
            }
        }
    }
};
</script>
