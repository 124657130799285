<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignChernihiv",
    components: {
        BaseCampaignResult,
    },
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Chernihiv Regional Hospital",
                isText: this.isText,
                text: `Today is a special day at Chernihiv Regional Hospital: Andersen Charity has delivered valuable supplies. Healthcare professionals and patients are glad to receive our help. Modern equipment frees medical instructors from certain tasks, so they can dedicate more time to patients. For people undergoing treatment, this means enhanced care.`,
                photosAmount: 10,
                cssClass: 'chernihiv',
                path: 'chernihiv',
                videoProps: {
                    photosCount: 14,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ]
                    },
                    srcFotos: 'chernihiv-hospital',
                    videoLink: 'https://www.youtube.com/embed/XTNEPJQZLU8'
                }
            }
        }
    }
};
</script>
