<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-children-clinic :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignChildrenClinic from "@/components/CampaignResults/CampaignChildrenClinic";

export default {
    name: "AboutChildrenClinic",
    components: {
        BaseCampaignPage,
        CampaignChildrenClinic
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Children’s City Hospital of Cherkasy",
                    mainText: `
                    Andersen Charity proved that making children happy is easy. We focused on alleviating the pain of parents who were struggling for a healthy future for their children with the help of our raised funds for extraordinary resources.`,
                    subText: `We delivered the biggest children's hospital a huge amount of required medical supplies. The hospital employees and patients are very thankful for all funds we raised together. The resources we gave are an incredible aid for all children on their way to recovery. Giving happiness is easy, so join us to support a healthy future and accessible care.`,
                    amount: "4,946",
                },
                commentType: 'children-clinic-comments'
            };
        },
    }
};
</script>