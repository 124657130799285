<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-first-cherkasy :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignFirstCherkasy from "@/components/CampaignResults/CampaignFirstCherkasy.vue";

export default {
    name: "AboutFirstCherkasy",
    components: {
        BaseCampaignPage,
        CampaignFirstCherkasy
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for 1st City Cherkasy Hospital",
                    mainText: `As the country's economy is suffering, Andersen Charity focused on delivering medical supplies of first necessity and minimizing the lack of medicines. We have set a goal of supporting the hospital that receives patients with the most severe cases. With its help, we will be able to reduce the staff stress.`,
                    subText: `With our funds raised, we could deliver a crucial number of essential medicines and medical supplies. In today's reality, every hospital suffers from a serious lack of resources and looks for those who can do anything to help. We believe that we have eased the work of care in the hospital and helped to overcome all difficulties!`,
                    amount: "5,065",
                },
                commentType: 'first-cherkasy-comments'
            };
        },
    }
};
</script>