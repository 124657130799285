<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-gynecology-association :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignGynecologyAssociation from "@/components/CampaignResults/CampaignGynecologyAssociation.vue";

export default {
    name: "AboutGynecologyAssociation",
    components: {
        BaseCampaignPage,
        CampaignGynecologyAssociation
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Lviv Medical Association of Obstetrics and Gynecology",
                    mainText: "Medical centers in Ukraine are suffering now from damage. With every passing day, they require more and more medical supplies and modern equipment, with one word, our help to overcome the staff shortage, workload, and financial problems. Andersen Charity has focused on providing all required support to the Lviv Medical Association of Obstetrics and Gynecology.",
                    subText: "Ukrainian people are suffering now as a result of the ongoing conflict. Andersen Charity is happy to deliver to the hospital Medicine Refrigerator HYC-290, Qingdao Haier Biomedical Co., Ltd., and a huge amount of consumables. By providing much-needed medical supplies, and equipment, we can help to alleviate people's pain in every corner of Ukraine and ensure that they receive all required drugs on time and get a great chance to live a healthy life again.",
                    amount: "4,770",
                },
                commentType: 'gynecology-association'
            };
        },
    }
};
</script>