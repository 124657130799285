<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-chernihiv :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignChernihiv from "@/components/CampaignResults/CampaignChernihiv.vue";

export default {
    name: "AboutChernihiv",
    components: {
        BaseCampaignPage,
        CampaignChernihiv
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Chernihiv Regional Hospital",
                    mainText: `During the war, we must take care of those who need urgent medical help. Therefore, Andersen Charity provides healthcare facilities with valuable devices, supplies, and consumables. Our goal is to deliver equipment for the early rehabilitation of patients suffering from strokes and other severe neurological conditions.`,
                    subText: `Andersen Charity has provided Chernihiv Regional Hospital with modern equipment to optimize clinical workflow. In times of desperate need, any extra item is of vital importance. That’s why doctors are truly grateful for this valuable donation. Patients are also happy to use convenient tools, for example, walkers. With new high-quality equipment, their treatment and recovery will be much faster are more efficient.`,
                    amount: "5,021",
                },
                commentType: 'chernihiv-comments'
            };
        },
    }
};
</script>