<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignChildrenRegional",
    props: {
        isText: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        BaseCampaignResult
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Regional Children's Hospital",
                isText: this.isText,
                text: "The hospital serves 450 little patients. But when the bomb hit the cardio center, all the victims were transferred here.  High-quality medical care and supplies - are what children's hospital needs now ...",
                photosAmount: 15,
                cssClass: 'children-regional',
                path: 'children-regional',
                videoProps: {
                    photosCount: 18,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15, 16, 17 ],
                    },
                    srcFotos: 'children-regional',
                    videoLink: 'https://www.youtube.com/embed/sLmKQSGN9FA'
                }
            }
        }
    },
};
</script>
