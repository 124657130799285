<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignGynecologyAssociation",
    components: {
        BaseCampaignResult,
    },
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Lviv Medical Association of Obstetrics and Gynecology",
                isText: this.isText,
                text: "The shortages of essential supplies and equipment have horrific consequences on the hospital's workload and care accessibility.  During these difficult times, clinics require not just supplies but the replacement of non-functioning equipment ...",
                photosAmount: 17,
                cssClass: 'gynecology-association',
                isVideoAvailable: false,
                path: 'gynecology-association',
                videoProps: {
                    photosCount: 19,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15, 16, 17, 18 ],
                    },
                    srcFotos: 'gynecology-association',
                }
            }
        }
    }
};
</script>
