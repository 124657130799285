<template>
    <section class="quote">
        <div class="section__quote">
            <div class="section__quote__handlebar">
                <div class="section__quote__handlebar__rotate"></div>
                <div class="section__quote__handlebar__text">
                    Andersen Charity is a light way to start saving lives. Every
                    contribution, no matter how much you donate, means a lot and
                    can give hope to people in Ukraine for a healthier and
                    brighter future. Stand together for peace!
                </div>
                <div class="section__quote__handlebar__straight"></div>
            </div>
            <div class="section__quote__author">— CTO of Andersen Company</div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 270">
            <path fill="#fff" fill-opacity="1" d="M0,224L120,234.7C240,245,480,267,720,266.7C960,267,1200,245,1320,234.7L1440,224L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
        </svg>
    </section>
</template>

<script>
export default {
    name: 'Quote'
}
</script>
