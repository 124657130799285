<template>
  <section id="charity" class="charity-call">
    <div class="charity-call__container">
      <div class="charity-call__wrapper">
        <h1>
          Andersen company
          <br />
          Charity Foundation
        </h1>
        <div>
          <p class="charity-call__raised">Total raised <span>€215,980</span></p>
          <button @click="isModalOpen = true" class="btn charity-call__btn">
            Donate
          </button>
        </div>
      </div>
    </div>
    <img
      id="image-charity"
      class="charity-call__image"
      src="../../assets/png/2560_1440-min.png"
    />
    <payment-modal :isOpen="isModalOpen" @closeModal="closePaymentModal" />
  </section>
</template>
<script>
import PaymentModal from "../../modals/PaymentModal.vue";

export default {
  components: { PaymentModal },
  name: "CharityCall",
  data() {
    return {
      isModalOpen: false,
    };
  },
  methods: {
    closePaymentModal() {
      this.isModalOpen = false;
    },
  },
};
</script>
