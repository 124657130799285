<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-central-hospital :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignCentralHospital from "@/components/CampaignResults/CampaignCentralHospital.vue";

export default {
    name: "AboutCentralHospital",
    components: {
        BaseCampaignPage,
        CampaignCentralHospital
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Chernihiv central regional hospital",
                    mainText: "The importance of helping Ukrainian hospitals during this time of war can’t be overstated. That's why saving lives is our main goal. The central regional hospital is struggling to cope with the high demand, as the number of injured people is only increasing, which has resulted in shortages of essential medical supplies, equipment, and personnel. Andersen Charity is now in Chernihiv to help the hospital overcome all difficulties.",
                    subText: "By donating to Ukrainian hospitals together, Andersen Charity and every donator can help to ensure that patients receive the high-quality care they need. Today we have assisted the hospital with all required supplies and successfully delivered electrocardiographs and a huge amount of consumables, which will help to improve the quality of care. Be sure today we have saved more lives.",
                    amount: "4,980",
                },
                commentType: 'central-hospital'
            };
        },
    }
};
</script>