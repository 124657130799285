<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignFirstCherkasy",
    components: {
        BaseCampaignResult,
    },
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for 1st City Cherkasy Hospital",
                isText: this.isText,
                text: `The number of patients in the hospital has enormously increased after February 24th. Everyone was confident that the work schedule would be overloaded, but no one expected so many people with serious injuries ...`,
                photosAmount: 13,
                cssClass: 'first-cherkasy',
                path: 'first-cherkasy',
                videoProps: {
                    photosCount: 16,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15 ],
                    },
                    srcFotos: 'first-cherkasy',
                    videoLink: 'https://www.youtube.com/embed/MODt63URW6E'
                }
            }
        }
    }
};
</script>
