<template>
    <div class="socials--list">
        <ShareNetwork
            style="padding-right: 15px"
            network="facebook"
            url=" https://www.facebook.com/andersencharity/"
            title="Let us unite for peace by raising funds together!"
            hashtags="andersen,charity"
        >
            <div class="socials--item gray-button">
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M20 10C20 4.47715 15.5229 0 10 0C4.47715 0 0 4.47715 0 10C0 14.9912 3.65684 19.1283 8.4375 19.8785V12.8906H5.89844V10H8.4375V7.79688C8.4375 5.29063 9.93047 3.90625 12.2146 3.90625C13.3084 3.90625 14.4531 4.10156 14.4531 4.10156V6.5625H13.1922C11.95 6.5625 11.5625 7.3334 11.5625 8.125V10H14.3359L13.8926 12.8906H11.5625V19.8785C16.3432 19.1283 20 14.9912 20 10Z"
                        fill="#353C45"
                    />
                </svg>
            </div>
        </ShareNetwork>
        <ShareNetwork
            network="twitter"
            url="https://twitter.com/andersenfund"
            title="Let us unite for peace by raising funds together!"
            hashtags="andersen,charity"
        >
            <div class="socials--item gray-button">
                <img src="@/assets/socials/twitter.png" />
            </div>
        </ShareNetwork>
    </div>
</template>

<script>
export default {
    name: "BaseSocials",
};
</script>

<style lang="scss">
.socials {
    &--item {
        padding: 15px !important;
        background: #e8ecef;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            background-color: #d0d1d2;
        }

        &:nth-child(n + 2) {
            margin-left: 15px;
        }

        img {
            width: 16px;
        }
    }

    &--list {
        display: flex;
        align-items: center;
    }
}
</style>