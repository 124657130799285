<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignCityClinic",
    components: {
        BaseCampaignResult,
    },
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Lviv Family City Clinic",
                isText: this.isText,
                text: `Every life is crucial. Family is the most important thing in this life. That's why we need to support the hospital where the lives are being born...`,
                photosAmount: 9,
                cssClass: 'city-clinic',
                path: 'city-clinic',
                isVideoAvailable: false,
                videoProps: {
                    photosCount: 12,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11 ],
                    },
                    srcFotos: 'city-clinic',
                }
            }
        }
    }
};
</script>
