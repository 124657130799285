import Vue from "vue";
import VueAnimateOnScroll from "vue-animate-onscroll";
import VueRouter from "vue-router";
import VueCarousel from "vue-carousel";
import App from "./App.vue";
import MainStyles from "./pages/MainStyles.vue";
import MainPage from "./pages/MainPage.vue";
import NftDetails from "./pages/NftDetails.vue";
import AboutOnco from "./pages/AboutOnco.vue";
import AboutMaternity from "./pages/AboutMaternity.vue";
import AboutMaternityLviv from "./pages/AboutMaternityLviv.vue";
import AboutMaternityChernihiv from "./pages/AboutMaternityChernihiv.vue";
import AboutChildren from "./pages/AboutChildren.vue";
import AboutChildrenRegional from "./pages/AboutChildrenRegional.vue";
import AboutChildrenClinic from "./pages/AboutChildrenClinic.vue";
import AboutInfectious from "./pages/AboutInfectious.vue";
import AboutCity from "./pages/AboutCity.vue";
import AboutRailway from "./pages/AboutRailway.vue";
import AboutFamily from "./pages/AboutFamily.vue";
import AboutFirstCherkasy from "./pages/AboutFirstCherkasy.vue";
import AboutFourthLviv from "./pages/AboutFourthLviv.vue";
import AboutSixthLviv from "./pages/AboutSixthLviv.vue";
import AboutChildrenCenter from "./pages/AboutChildrenCenter.vue";
import AboutEight from "./pages/AboutEight.vue";
import AboutChernihiv from "./pages/AboutChernihiv.vue";
import AboutChernihivSecond from "./pages/AboutChernihivSecond.vue";
import AboutChernihivThird from "./pages/AboutChernihivThird.vue";
import AboutPrinceLeo from "./pages/AboutPrinceLeo.vue";
import AboutSecondChernihiv from "./pages/AboutSecondChernihiv.vue";
import AboutCherkasyDispensary from "./pages/AboutCherkasyDispensary.vue";
import AboutCentralHospital from "./pages/AboutCentralHospital.vue";
import AboutGynecologyAssociation from "./pages/AboutGynecologyAssociation.vue";
import AboutChildrenPolyclinic from "./pages/AboutChildrenPolyclinic.vue";
import AboutCityPolyclinic from "./pages/AboutCityPolyclinic.vue";
import AboutRegionalCenter from "./pages/AboutRegionalCenter.vue";
import AboutChernihivFourth from "./pages/AboutChernihivFourth.vue";
import AboutChernihivFive from "./pages/AboutChernihivFive.vue";
import AboutMedicalCenter from "./pages/AboutMedicalCenter.vue";
import AboutMilitaryHospital from "./pages/AboutMilitaryHospital.vue";
import AboutCityClinic from "./pages/AboutCityClinic.vue";
import Payment from "./pages/Payment.vue";
import "normalize.css";
import VueSocialSharing from "vue-social-sharing";
const VueScrollTo = require("vue-scrollto");

Vue.use(VueSocialSharing);
Vue.use(VueRouter);
Vue.use(VueCarousel);
Vue.use(VueAnimateOnScroll);
Vue.use(VueScrollTo);

Vue.config.productionTip = false;

Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

const routes = [
  { path: "/", name: "main", component: MainPage },
  { path: "/nft", component: NftDetails },
  { path: "/about-onco", component: AboutOnco },
  { path: "/about-maternity", component: AboutMaternity },
  { path: "/about-children", component: AboutChildren },
  { path: "/about-children-regional", component: AboutChildrenRegional },
  { path: "/about-children-clinic", component: AboutChildrenClinic },
  { path: "/about-maternity-lviv", component: AboutMaternityLviv },
  { path: "/about-maternity-chernihiv", component: AboutMaternityChernihiv },
  { path: "/about-city", component: AboutCity },
  { path: "/about-railway", component: AboutRailway },
  { path: "/about-family", component: AboutFamily },
  { path: "/about-infectious", component: AboutInfectious },
  { path: "/payment", name: "payment", component: Payment },
  { path: "/about-first-cherkasy", component: AboutFirstCherkasy },
  { path: "/about-fourth-lviv", component: AboutFourthLviv },
  { path: "/about-children-center", component: AboutChildrenCenter },
  { path: "/about-eight", component: AboutEight },
  { path: "/about-chernihiv", component: AboutChernihiv },
  { path: "/about-chernihiv-second", component: AboutChernihivSecond },
  { path: "/about-chernihiv-third", component: AboutChernihivThird },
  { path: "/about-sixth-lviv", component: AboutSixthLviv },
  { path: "/about-prince-leo", component: AboutPrinceLeo },
  { path: "/about-chernihiv-city-second", component: AboutSecondChernihiv },
  { path: "/about-cherkasy-dispensary", component: AboutCherkasyDispensary },
  { path: "/about-central-hospital", component: AboutCentralHospital },
  {
    path: "/about-gynecology-association",
    component: AboutGynecologyAssociation,
  },
  { path: "/about-children-polyclinic", component: AboutChildrenPolyclinic },
  { path: "/about-city-polyclinic", component: AboutCityPolyclinic },
  { path: "/about-regional-center", component: AboutRegionalCenter },
  { path: "/about-chernihiv-fourth", component: AboutChernihivFourth },
  { path: "/about-chernihiv-five", component: AboutChernihivFive },
  { path: "/about-medical-center", component: AboutMedicalCenter },
  { path: "/about-military-hospital", component: AboutMilitaryHospital },
  { path: "/about-city-clinic", component: AboutCityClinic },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
