<template>
    <header class="header" :class="{ headerScrolled: isScrolled }">
        <div class="header__container">
            <div>
                <a href="/">
                    <img
                        class="andersen-logo"
                        src="@/assets/cherity_logo/svg/horizontal_standart.svg"
                    />
                </a>
            </div>
            <ul class="header-links">
                <template v-if="isMainRoute">
                    <li
                        v-for="link in linksScroll"
                        :key="link.title"
                    >
                        <a
                            @click="setRoute(link.scrollProps)"
                            v-scroll-to="link.scrollProps"
                        >{{ link.title }}</a>
                    </li>
                </template>
                <template v-else>
                    <li
                        v-for="link in links"
                        :key="link.title"
                        @click="setRoute(link.linkTo)"
                    >
                        <a>{{ link.title }}</a>
                    </li>
                </template>
            </ul>
            <a
                href="https://andersenlab.com/"
                target="_blank"
                id="andersen-link"
                class="charity-call__andersen-link link"
            >
                {{ isTablet ? 'Founded by Andersen' : 'Founded by Andersen IT Company'}}
            </a>
            <!-- <a
                href="https://andersenlab.com/"
                target="_blank"
                id="andersen-link tablet"
                class="charity-call__andersen-link link"
            >
                By Andersen
            </a> -->
            <button
                @click="isOpen = !isOpen"
                class="btn btn-menu"
            >
                <img v-if="!isOpen" src="@/assets/icons/burger.svg" />
                <img v-if="isOpen" src="@/assets/icons/close-icon.svg" />
            </button>
            <div v-if="isOpen" class="mobile-menu">
                <div class="mobile-menu__links">
                    <ul class="mobile-header-links">
                        <li
                            v-for="link in links"
                            :key="link.title"
                        >
                            <a
                                @click="handleClose(link.linkTo)"
                                :href="`/#${link.linkTo}`"
                            >{{ link.title }}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: "TheHeader",
    data() {
        return {
            isScrolled: false,
            isOpen: false,
            isTablet: false,
        };
    },
    computed: {
        isMainRoute() {
            return this.$route.path === '/'
        },
        linksScroll() {
            return [
                {
                    title: 'About',
                    scrollProps: {
                        el: '#about',
                        container: 'body',
                        duration: 400,
                        easing: 'linear',
                        offset: -300,
                    }
                },
                { title: 'Results', scrollProps: this.resultsScroll },
                { title: 'What we do', scrollProps: this.whatWeDoScroll },
                { title: 'NFT', scrollProps: this.nftScroll },
                {
                    title: 'Details',
                    scrollProps: {
                        el: '#details',
                        container: 'body',
                        duration: 400,
                        easing: 'linear',
                        offset: window.innerWidth < 490 ? -300 : -150,
                    }
                },
            ]
        },
        links() {
            return [
                { title: 'About', linkTo: 'about' },
                { title: 'Results', linkTo: 'results' },
                { title: 'What we do', linkTo: 'what-we-do' },
                { title: 'NFT', linkTo: 'nft' },
                { title: 'Details', linkTo: 'details' }
            ]
        },
        whatWeDoScroll() {
            if (window.innerWidth <= 1440) {
                return {
                    el: '#what-we-do',
                    container: 'body',
                    duration: 400,
                    easing: 'linear',
                    offset: 0,
                }
            }

            return {
                el: '#what-we-do',
                container: 'body',
                duration: 400,
                easing: 'linear',
                offset: -100,
            }
        },
        nftScroll() {
            if (window.innerWidth <= 1440) {
                return {
                    el: '#nft',
                    container: 'body',
                    duration: 400,
                    easing: 'linear',
                    offset: -280,
                }
            }

            return {
                el: '#nft',
                container: 'body',
                duration: 400,
                easing: 'linear',
                offset: -370
            }
        },
        resultsScroll() {
            if (window.innerWidth <= 1440) {
                return {
                    el: '#results',
                    container: 'body',
                    duration: 400,
                    easing: 'linear',
                    offset: 50,
                }
            }

            return {
                el: '#results',
                container: 'body',
                duration: 400,
                easing: 'linear',
            }
        }
    },
    mounted() {
        document.addEventListener("scroll", () => {
            if (window.pageYOffset) {
                this.isScrolled = true;
            } else {
                this.isScrolled = false;
            }
        });

        window.addEventListener('resize', () => {
            if (window.innerWidth <= 650) {
                this.isTablet = true
            } else {
                this.isTablet = false
            }
        })
    },
    methods: {
        handleOpen() {
            this.isOpen = true;
        },
        handleClose(link) {
            if (window.innerWidth < 950) {
                let distance
                switch (link) {
                    case 'details':
                        distance = -100
                        break;
                    case 'about':
                        distance = -80
                        break;
                    case 'results':
                        distance = -40
                        break;
                    case 'what-we-do':
                        distance = -60
                        break;
                    case 'nft':
                        distance = 420
                        break;

                    default: 0
                        break;
                }
                setTimeout(() => {
                    window.scrollBy({
                        top: distance,
                        behavior: 'instant'
                    })
                }, 0)
            }
            this.isOpen = false;
        },
        setRoute(route) {
            if (!this.isMainRoute) {
                this.$router.push({
                    name: 'main',
                    params: { linkTo: '#' + route }
                })
            }
        }
    },
};
</script>
