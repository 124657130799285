<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignCherkasyDispensary",
    components: {
        BaseCampaignResult,
    },
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Cherkasy Regional Dermatology and Venereology Dispensary",
                isText: this.isText,
                text: 'As the war rages on, Ukrainian healthcare facilities are still under enormous pressure. Now, they need more equipment and consumables than ever before. Today, we are heading for a dispensary in Cherkasy ...',
                photosAmount: 10,
                cssClass: 'cherkasy-dispensary',
                isVideoAvailable: false,
                path: 'cherkasy-dispensary',
                videoProps: {
                    photosCount: 13,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12 ],
                    },
                    srcFotos: 'cherkasy-dispensary',
                }
            }
        }
    }
};
</script>
