<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignEight",
    components: {
        BaseCampaignResult,
    },
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Lviv Eighth Hospital",
                isText: this.isText,
                text: `During the war, the clinic has to deal with more injuries. Unfortunately, every 4th patient is internally displaced. Those large-scale injuries require specific treatment and longer rehabilitation. With the help of our funds raised, we can help its patients to overcome difficulties ...`,
                photosAmount: 8,
                cssClass: 'eight',
                path: 'eight',
                videoProps: {
                    photosCount: 12,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11 ],
                    },
                    srcFotos: 'eight-hospital',
                    videoLink: 'https://www.youtube.com/embed/ZNB8Qi03ilQ'
                }
            }
        }
    }
};
</script>
