<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignRegionalCenter",
    components: {
        BaseCampaignResult,
    },
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Chernihiv Regional Center for AIDS Prevention and Control",
                isText: this.isText,
                text: "As the war rages on, Ukrainian clinics desperately need equipment and consumables. Today, our journey leads us to Chernihiv, where we extend our support to the Regional Center for AIDS Prevention and Control, offering vital aid in their mission ...",
                photosAmount: 8,
                cssClass: 'regional-center',
                isVideoAvailable: false,
                path: 'regional-center',
                videoProps: {
                    photosCount: 11,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11 ],
                        thirdFotos: [ ],
                    },
                    srcFotos: 'regional-center',
                }
            }
        }
    }
};
</script>
