<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignChildrenCenter",
    components: {
        BaseCampaignResult,
    },
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Western Ukrainian Specialized Children’s Medical Center",
                isText: this.isText,
                text: `The medical center in Lviv, which is recognized as a HUB, has always assisted children with oncologic diseases. Now all the children, who were being treated in Kyiv, were evacuated to this hub. The war continues, and with that, treating is becoming increasingly difficult ...`,
                photosAmount: 13,
                cssClass: 'children-center',
                path: 'children-center',
                videoProps: {
                    photosCount: 16,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15 ],
                    },
                    srcFotos: 'children-center',
                    videoLink: 'https://www.youtube.com/embed/YCW-ym0QZNs'
                }
            }
        }
    }
};
</script>
