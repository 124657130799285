<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignMaternity",
    components: {
        BaseCampaignResult,
    },
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Cherkasy city maternity hospital",
                isText: this.isText,
                text: `While Ukraine is fighting for peace, lives across the
                    country do not just continue ‐ they are being born.
                    Childbirth is the most challenging test. However, the
                    hospital needs support to improve the newborns' future ...`,
                photosAmount: 15,
                cssClass: 'maternity',
                path: 'maternity',
                videoProps: {
                    photosCount: 18,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15, 16, 17 ],
                    },
                    srcFotos: 'maternity-hospital',
                    videoLink: 'https://www.youtube.com/embed/Zf8agmWYdeQ'
                }
            }
        }
    }
};
</script>
