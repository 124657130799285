<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-military-hospital :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignMilitaryHospital from "@/components/CampaignResults/CampaignMilitaryHospital.vue";

export default {
    name: "AboutMilitaryHospital",
    components: {
        BaseCampaignPage,
        CampaignMilitaryHospital
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Lviv Military Hospital",
                    mainText: " We focused not only on improving the capabilities of Lviv Military Hospital but also on modernizing the center. Our primary goal was to make sure every patient received the needed emotional support through rehabilitation programs. Overall, we wanted to improve the outcomes of the hospital with advanced medical technologies.",
                    subText: "Together, we have provided the hospital with financial support, enabling the acquisition of essential medical equipment, supplies, and resources necessary for providing quality care. With the funds raised through the campaign, the hospital can now undertake infrastructure improvements and renovations to enhance its medical facilities.",
                    amount: "5,345",
                },
                commentType: 'military-hospital'
            };
        },
    }
};
</script>