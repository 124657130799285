<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignMilitaryHospital",
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    components: {
        BaseCampaignResult,
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Lviv Military Hospital",
                isText: this.isText,
                text: `In a world marked by challenges and compassion, it's crucial to give a chance for a healthy and peaceful future to everyone. The military requires your support to be able to receive that chance….`,
                photosAmount: 15,
                cssClass: 'military-hospital',
                path: 'military-hospital',
                isVideoAvailable: false,
                videoProps: {
                    photosCount: 18,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15, 16, 17 ],
                    },
                    srcFotos: 'military-hospital',
                }
            }
        }
    }
};
</script>
