<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignSecond",
    props: {
        isText: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        BaseCampaignResult
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Chernihiv Second Hospital",
                isText: this.isText,
                text: "The hospital began to work in a different mode as the workload and the number of patients with complex injuries increased. The war changed not only the medical centers but also our personalities. Each of us is trying now to find a way to help as much  as possible...",
                photosAmount: 17,
                cssClass: 'second-hospital',
                path: 'chernihiv-second',
                isVideoAvailable: false,
                videoProps: {
                    photosCount: 14,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ]
                    },
                    srcFotos: 'second-hospital',
                }
            }
        }
    },
};
</script>
