<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-chernihiv-fourth :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignChernihivFourth from "@/components/CampaignResults/CampaignChernihivFourth.vue";

export default {
    name: "AboutChernihivFourth",
    components: {
        BaseCampaignPage,
        CampaignChernihivFourth
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Chernihiv Regional Hospital for War Veterans",
                    mainText: "Our Charity Foundation aimed at improving the recovery journey of those who suffered from the war. Our mission was to ensure medical care, and rehabilitation was provided at the highest level so that the healing and reintegration in their normal lives would be effective.",
                    subText: "Through generous contributions, we can now ensure that the hospital is well-equipped to offer crucial medical treatments, essential rehabilitation programs, and a nurturing environment where veterans can find solace and strength on their journey to recovery.",
                    amount: "5,298",
                },
                commentType: 'chernihiv-fourth'
            };
        },
    }
};
</script>