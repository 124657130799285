<template>
    <div class="aboutus-page">
        <div @click="goBack" class="aboutus-page__back-link">
            <span class="breadcrumbs-link"
                ><img src="@/assets/icons/left-arrow-link.png" /> Go Back</span
            >
        </div>
        <div class="aboutus-page__container">
            <h1 class="aboutus-page__title">
                {{ text.title }}
            </h1>
            <div class="aboutus-page__info">
                <div class="aboutus-page__info--text">
                    <p class="text-description">
                        {{ text.mainText }}
                    </p>
                    <div class="aboutus-page__raised">
                        <span class="aboutus-page__raised--amount"
                            >€{{ text.amount }}</span
                        >
                        <p class="aboutus-page__raised--goal">
                            raised of €{{ text.amount }} GOAL
                        </p>
                        <div class="aboutus-page__raised--line"></div>
                    </div>
                    <p class="text-description">
                        {{ text.subText }}
                    </p>
                </div>
                <div class="aboutus-page__info--media">
                    <slot name="campaign" />
                </div>
            </div>
        </div>
        <comments :commentType="commentType" />
        <div v-if="false" class="aboutus-page__socials socials-mobile">
            <p class="aboutus-page__socials--title">Share</p>
            <base-socials />
        </div>
        <footer class="aboutus-page__footer">
            <div class="aboutus-page__footer--container">
                <div v-if="false" class="aboutus-page__socials socials-desktop">
                    <p class="aboutus-page__socials--title">Share</p>
                    <base-socials />
                </div>
                <div class="aboutus-page__footer--action">
                    <button
                        @click="isModalOpen = true"
                        class="btn donation__btn"
                    >
                        Donate
                    </button>
                </div>
            </div>
        </footer>
        <payment-modal :isOpen="isModalOpen" @closeModal="closePaymentModal" />
    </div>
</template>

<script>
import Comments from "@/components/Comments.vue";
import BaseSocials from "@/components/Base/BaseSocials.vue";
import PaymentModal from "../../modals/PaymentModal.vue";

export default {
    name: "AboutChildren",
    components: {
        Comments,
        BaseSocials,
        PaymentModal,
    },
    props: {
        text: {
            type: Object,
            required: true
        },
        commentType: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            isOpenVideo: false,
            isModalOpen: false,
            activeSlide: 1,
        };
    },
    methods: {
        goBack() {
            this.$router.go(-1);
        },
        closePaymentModal() {
            this.isModalOpen = false;
        },
        handleOpenBecome() {
            this.isOpenBecomeModal = true;
        },
        handleClose() {
            this.isOpenVideo = false;
        },
    },
    mounted() {
        window.scrollTo(0, 0);
    },
};
</script>

<style scoped lang="scss">
.breadcrumbs-link {
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #9ca8b6;
}

.aboutus-page {
    padding-top: 40px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 1134px;
    margin: 0 auto;
    min-height: calc(100vh - (75px + 82px));

    &__info {
        display: grid;
        grid-template-columns: 2fr 3fr;
        grid-gap: 32px;

        &--text {
            display: flex;
            flex-direction: column;

            .text-description {
                font-weight: 300;
                font-size: 14px;
                margin-bottom: 0;
                margin-top: 0;
                line-height: 26px;
            }

            .text-title {
                font-weight: 400;
                font-size: 16px;
                margin-bottom: 16px;
            }
        }

        &--media {
            .campaigns-results__container {
                margin-top: 0;
            }
        }
    }

    &__raised {
        display: flex;
        flex-direction: column;
        padding: 25px 0;

        &--amount {
            font-weight: 300;
            font-size: 38px;
        }

        &--goal {
            font-weight: 500;
            font-size: 10px;
            text-transform: uppercase;
            color: #647282;
            margin-bottom: 16px;
        }

        &--line {
            height: 12px;
            background: linear-gradient(270deg, #ffd02c 0%, #fff095 100%);
            border-radius: 8px;
        }
    }

    &__back-link {
        align-self: flex-start;
        cursor: pointer;
    }

    &__video-slider {
        position: relative;
        height: 300px;
        width: 510px;
        display: flex;
        align-items: center;
        overflow: hidden;
        border-radius: 16px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        &__menu {
            position: absolute !important;
            left: 0;
            right: 0;
            bottom: 0;
            height: 50px;
            border-radius: 16px;
            margin-bottom: 0 !important;
            padding-left: 10px;
            padding-right: 10px;
            padding-bottom: 10px;
            background: linear-gradient(
                356.68deg,
                #000000 -20.53%,
                rgba(0, 0, 0, 0) 70.06%
            );
            display: flex;
            align-items: center;
            justify-content: space-between;

            &--navigation {
                display: flex;
                align-items: center;
                margin-bottom: 0 !important;

                span {
                    font-style: normal;
                    margin-right: 10px;
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 20px;
                    /* identical to box height, or 182% */

                    letter-spacing: 0.02em;
                    text-transform: uppercase;

                    color: #ffffff;
                }

                .play-button {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: rgba(255, 255, 255, 0.779);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;

                    &.disabled {
                        cursor: not-allowed;

                        &::after {
                            content: "";
                            border-radius: 50%;
                            position: absolute;
                            left: 0;
                            right: 0;
                            top: 0;
                            bottom: 0;
                            background-color: rgba(
                                241,
                                241,
                                241,
                                0.348
                            ) !important;
                        }
                    }

                    &:nth-child(n + 2) {
                        margin-left: 10px;
                    }

                    img {
                        width: 8px !important;
                    }
                }
            }

            .play-button {
                background-color: transparent;
                border: none;
                cursor: pointer;
                display: flex;
                align-items: center;

                img {
                    width: 40px;
                    height: 40px;
                }

                span {
                    margin-left: 10px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 11px;
                    line-height: 20px;
                    /* identical to box height, or 182% */

                    letter-spacing: 0.02em;
                    text-transform: uppercase;

                    color: #ffffff;
                }
            }
        }
    }

    @keyframes show {
        from {
            transform: scale(0);
        }
        to {
            transform: scale(1);
        }
    }

    &__modal-video {
        background-color: rgba(0, 0, 0, 0.3);
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;

        iframe {
            border-radius: 16px;
            animation-name: show;
            animation-duration: 0.5s;
        }

        &__header {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 15px;
            button {
                cursor: pointer;
                width: 48px;
                height: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                border-radius: 50%;
            }
        }
    }

    &__socials {
        display: flex;
        align-items: center;

        &--title {
            color: #9ca8b6;
            font-size: 11px;
            margin-right: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
        }
    }

    .socials-mobile {
        display: none;
    }

    &__description {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: -0.02em;
        color: #647282;
    }

    &__subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.02em;
        color: #353c45;
        margin-top: 30px;
    }

    &__title {
        font-weight: 400;
        color: #353c45;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;
        letter-spacing: -0.02em;
        margin-bottom: 65px;
    }

    &__footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: white;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 10px 0px 52px rgba(154, 168, 196, 0.4);

        &--action {
            display: flex;
            align-items: center;
            img {
                margin-right: 30px;
            }
        }

        &--container {
            display: flex;
            align-items: center;
            max-width: 1134px;
            width: 100%;
            justify-content: space-between;
        }
    }

    &__left-column {
        padding-right: 120px;
    }

    &__right-column {
        margin-top: 25px;

        div {
            margin-bottom: 15px;
            position: relative;
        }

        @keyframes showImage {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }

        img {
            width: 100%;
            border-radius: 16px;
            min-height: 300px;
            animation-name: showImage;
            animation-duration: 0.5s;
        }
    }

    &__container {
        width: 100%;
        max-width: 1134px;
        display: flex;
        flex-direction: column;
    }
}

@media screen and (max-width: 1200px) {
    .aboutus-page {
        &__back-link {
            padding-left: 15px;
        }

        &__title {
            text-align: center;
        }

        .comments {
            padding: 0 20px;
        }

        &__container {
            flex-direction: column;
            padding: 20px;
        }

        &__info {
            display: flex;
            flex-direction: column;

            &--media {
                order: -1;
            }
        }

        &__left-column {
            padding-right: 0;
            padding: 0 20px;

            h1 {
                text-align: center;
                font-size: 24px;
            }
        }

        &__right-column {
            align-self: center;
            order: -1;
        }
    }
}

@media screen and (max-width: 550px) {
    .aboutus-page {
        flex-direction: column;
        align-items: center;

        &__container {
            max-width: 490px;
        }

        &__video-slider {
            width: 470px;
        }
    }
}

@media screen and (max-width: 490px) {
    .aboutus-page {
        flex-direction: column;
        align-items: center;

        &__container {
            max-width: 375px;
            // flex-direction: column-reverse;
        }

        .comments {
            padding: 0 35px;
        }

        &__back-link {
            padding-left: 20px;
        }

        &__title {
            font-size: 24px;
            margin-top: 0;
            margin-bottom: 25px;
            line-height: 35px;
        }

        &__left-column {
            padding: 0 10px;
        }

        &__right-column {
            height: 280px;
            order: 0;
            margin-top: 20px;
        }

        &__video-slider {
            width: 350px;
            height: 250px;

            &__menu--navigation {
                span {
                    width: 55px;
                }
            }

            img {
                width: 100%;
                height: 100%;
            }
        }

        &__socials {
            display: unset;
            align-self: flex-start;
            padding-left: 35px;

            &--title {
                color: #3e4956;
                font-size: 18px;
                margin-right: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: 20px;
                letter-spacing: 0.02em;
                text-transform: unset;
            }
        }

        .socials-mobile {
            display: none;
        }

        .socials-desktop {
            display: none;
        }

        &__footer {
            &--action {
                width: 100%;

                .donation__btn {
                    width: 100%;
                    height: 56px;
                }
            }
        }
    }
}

@media screen and (max-width: 375px) {
    .aboutus-page {
        &__container {
            max-width: 340px;
        }
    }
}
</style>