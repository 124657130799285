<template>
    <div class="business-donators">
        <div class="business-donators__header">
            <span>Month</span>
            <span class="header-amount">Amount, EUR</span>
        </div>
        <ul>
            <li v-for="(item, i) in rawData" :key="i">
                <div class="list-date">{{ item.date }}</div>
                <div class="list-amount">{{ item.amount }}</div>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'BusinessDonators',
    data() {
        return {
            rawData: [
                { date: 'June, 2022', amount: '19,100' },
                { date: 'July, 2022', amount: '10,000' },
                { date: 'July, 2022', amount: '15,000' },
                { date: 'August, 2022', amount: '10,000' },
                { date: 'August, 2022', amount: '15,000' },
                { date: 'September, 2022', amount: '10,000' },
                { date: 'September, 2022', amount: '15,000' },
                { date: 'October, 2022', amount: '5,000' },
                { date: 'October, 2022', amount: '5,000' },
                { date: 'November, 2022', amount: '5,000' },
                { date: 'November, 2022', amount: '5,000' },
                { date: 'December, 2022', amount: '5,000' },
                { date: 'December, 2022', amount: '5,000' },
                { date: 'January, 2023', amount: '5,000' },
                { date: 'January, 2023', amount: '5,000' },
                { date: 'February, 2023', amount: '5,000' },
                { date: 'February, 2023', amount: '5,000' },
                { date: 'March, 2023', amount: '5,000' },
                { date: 'March, 2023', amount: '5,000' },
                { date: 'April, 2023', amount: '5,000' },
                { date: 'April, 2023', amount: '5,000' },
                { date: 'May, 2023', amount: '5,000' },
                { date: 'May, 2023', amount: '5,000' },
                { date: 'June, 2023', amount: '5,000' },
                { date: 'July, 2023', amount: '5,000' },
                { date: 'August, 2023', amount: '5,000' },
                { date: 'September, 2023', amount: '5,000' },
                { date: 'October, 2023', amount: '5,000' },
                { date: 'November, 2023', amount: '5,000' },
            ]
        }
    }
}
</script>

<style lang="scss">
.business-donators {
    width: 100%;

    &__header {
        background: #F7F8FA;
        border-radius: 4px;
        display: flex;
        align-items: center;
        font-size: 14px;
        padding: 8px 16px;
        color: #353C45;

        span {
            flex: 1
        }

        .header-amount {
            padding-left: 16px;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        max-height: 600px;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgb(211, 211, 211);
        }

        li {
            width: 100%;
            font-size: 14px;
            font-weight: 300;
            line-height: 26px;
            color: #353C45;
            border-bottom: 1px solid #E0E0E0;
            display: flex;
            padding: 8px 0;

            .list-date {
                padding-left: 16px;
                flex: 1;
            }

            .list-amount {
                flex: 1;
            }
        }
    }
}

@media screen and (max-width: 650px) {
    .business-donators {
        &__header {
            justify-content: space-between;

            span {
                flex: unset;
            }
        }

        ul li {
            justify-content: space-between;

            .list-date {
                flex: unset;
            }

            .list-amount {
                padding-right: 16px;
                flex: unset;
            }
        }
    }
}
</style>