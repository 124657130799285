<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignFourthLviv",
    components: {
        BaseCampaignResult,
    },
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for  Lviv 4th City Hospital",
                isText: this.isText,
                text: `We have to keep in mind: not every patient has a chance of recovery, as some stay in the hospitals and wait for death. Their lives, as much as any other, require medical support to feel the pleasure of living again ...`,
                photosAmount: 13,
                cssClass: 'fourth-lviv',
                path: 'fourth-lviv',
                videoProps: {
                    photosCount: 16,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15 ],
                    },
                    srcFotos: 'fourth-lviv',
                    videoLink: 'https://www.youtube.com/embed/mTI3Sb3mn7U'
                }
            }
        }
    }
};
</script>
