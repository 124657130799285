<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-maternity-lviv :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignMaternityLviv from "@/components/CampaignResults/CampaignMaternityLviv";

export default {
    name: "AboutMaternityLviv",
    components: {
        BaseCampaignPage,
        CampaignMaternityLviv
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Clinical Medical of Obstetrics and Gynecology of Lviv",
                    mainText: `
                        Now sick children will get everything they need for a speedy recovery.
                        Our goal is to support those who, due to the current circumstances, cannot take care of themselves.
                    `,
                    subText: `
                            We always remember those who feel insecure and vulnerable. The war makes their situation even more dangerous.
                            Therefore, Andersen Charity is trying to help.
                            The medical staff also expresses their gratitude to the participants of the Andersen Charity.
                        `,
                    amount: "5,022",
                },
                commentType: 'maternity-lviv-comments'
            };
        },
    }
};
</script>