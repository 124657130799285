<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignChildrenPolyclinic",
    components: {
        BaseCampaignResult,
    },
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Chernihiv Children's Polyclinic",
                isText: this.isText,
                text: "In the midst of an ongoing war, Ukrainian clinics face overwhelming challenges. The demand for equipment and consumables has escalated to unprecedented levels. Today, our destination is a children's clinic in Chernihiv ...",
                photosAmount: 14,
                cssClass: 'children-polyclinic',
                isVideoAvailable: false,
                path: 'children-polyclinic',
                videoProps: {
                    photosCount: 17,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15, 16, 17 ],
                    },
                    srcFotos: 'children-polyclinic',
                }
            }
        }
    }
};
</script>
