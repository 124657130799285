<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignInfectious",
    components: {
        BaseCampaignResult,
    },
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Cherkasy Infectious Diseases Hospital",
                isText: this.isText,
                text: `In autumn, the number of patients always increases. But now, when there is a war, it is difficult to provide high-quality medical care.
                    Unfortunately, there are not enough medicines. Sometimes people go to inpatient treatment with whole families.
                    In such circumstances, any help is invaluable ...`,
                photosAmount: 17,
                cssClass: 'infectious',
                path: 'infectious',
                videoProps: {
                    photosCount: 20,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15, 16, 17, 18, 19 ],
                    },
                    srcFotos: 'infectious-hospital',
                    videoLink: 'https://www.youtube.com/embed/BieOJTtHKO0'
                }
            }
        }
    }
};
</script>
