<template>
    <div class="nft-page">
        <div class="nft-page__container">
            <div class="nft-go-back">
                <a class="breadcrumbs-link" href="/"
                    ><img src="../assets/icons/left-arrow-link.png" /> Go
                    Back</a
                >
            </div>
            <div class="row">
                <div class="nft-page__left-column">
                    <div @click="goBack" class="nft-page__back-link">
                        <span class="breadcrumbs-link"
                            ><img src="../assets/icons/left-arrow-link.png" />
                            Go Back</span
                        >
                    </div>
                    <h1 class="nft-page__title">Traditional NFT art</h1>
                    <p class="nft-page__description">
                        With this NFT, you will accomplish two things
                        simultaneously. First, you will help save the lives of
                        Ukrainians. All the proceeds will go to the Andersen
                        Charity foundation, which is supplying equipment to
                        healthcare providers operating in Ukraine; we will
                        deliver it to where it is needed most. Second, you will
                        obtain a piece of unique Ukrainian culture. It will
                        remind you that there aren’t only horrible things in
                        this world, there are also beautiful ones!
                    </p>

                    <div class="nft-page__options nft-desktop">
                        <button
                            @click="handleChooseOption(option.id)"
                            v-for="(option, index) in options"
                            :key="index"
                            :class="{ active: option.id === activeOptionId }"
                        >
                            {{ option.label }}
                        </button>
                    </div>
                    <hr />
                    <h2 class="nft-page__subtitle nft-desktop">
                        {{ getActiveOption().subtitle }}
                    </h2>
                    <p class="nft-page__description nft-desktop">
                        {{ getActiveOption().text }}
                    </p>
                </div>
                <div class="nft-page__right-column">
                    <div @mousemove="handleMouseMove" class="nft-page__nft-bg">
                        <img
                            v-if="getActiveOption()"
                            :style="{
                                top: `calc(50% - ${200 + offsetY / 30}px)`,
                                left: `calc(50% - ${200 + offsetX / 30}px)`,
                            }"
                            :src="getActiveOption().img"
                        />
                    </div>
                    <!--  -->
                    <div class="nft-page__options nft-mobile">
                        <button
                            @click="handleChooseOption(option.id)"
                            v-for="option in options"
                            :key="option.id"
                            :class="{ active: option.id === activeOptionId }"
                        >
                            {{ option.label }}
                        </button>
                    </div>
                    <h2 class="nft-page__subtitle nft-mobile">
                        {{ getActiveOption().subtitle }}
                    </h2>
                    <p class="nft-page__description nft-mobile">
                        {{ getActiveOption().text }}
                    </p>
                    <!--  -->
                    <div v-if="false" class="nft-page__socials nft-mobile">
                        <p class="nft-page__socials--title">Share</p>
                        <div class="nft-page__socials--list">
                            <base-socials />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <footer class="nft-page__footer">
            <div class="nft-page__footer--container">
                <div v-if="false" class="nft-page__socials nft-desktop">
                    <p class="nft-page__socials--title">Share</p>
                    <div class="nft-page__socials--list">
                        <base-socials />
                    </div>
                </div>
                <div class="nft-page__footer--action">
                    <img src="../assets/opensea-logo.png" />
                    <a
                        href="https://opensea.io/SunCharitybyAndersen"
                        target="_blank"
                        class="btn donation__btn"
                        >Go to NFT market</a
                    >
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import BaseSocials from "@/components/Base/BaseSocials.vue";

export default {
    name: "NftDetails",
    components: { BaseSocials },
    data() {
        return {
            options: [
                {
                    id: 1,
                    label: "PYSANKA",
                    img: require("../assets/nft/nft-egg.png"),
                    subtitle: "Pysanka",
                    text: "Pysanka is an Easter egg with an ornamental pattern made with wax. In the city of Kolomyia, Ivano-Frankivsk region, there is a Pysanka Museum. There, you can find a collection of more than 12,000 eggs from different regions of Ukraine and other countries. The Ukrainians used to attribute magical powers to Easter eggs in the past: the ability to heal the sick, put out a fire, find a missing cow or even a hidden treasure. Who knows, perhaps, NFTs can also carry ancient magic powers?",
                },
                {
                    id: 2,
                    label: "Motanka",
                    img: require("../assets/nft/nft-doll.png"),
                    subtitle: "Motanka",
                    text: "Our motanka doll is an amazing talisman. It might simul-taneously bring happiness to its owner and save the lives of many Ukrainians. Motanka dolls are made of pieces of cloth sewn together with knots. Dolls of this type were found at ancient sites of the region of Chernihiv. It means this doll type is at least 5000 years old! Motanka is a symbol of good and prosperity, a symbol of hopes for a better future. We assume we need it now more than ever!",
                },
            ],
            activeOptionId: 1,
            offsetY: 0,
            offsetX: 0,
        };
    },
    methods: {
        getActiveOption() {
            return (
                this.options.find(
                    (option) => option.id === this.activeOptionId
                ) || null
            );
        },

        handleChooseOption(id) {
            this.activeOptionId = id;
        },

        handleMouseMove(e) {
            this.offsetY = e.offsetY;
            this.offsetX = e.offsetX;
        },

        goBack() {
            this.$router.go(-1);
        },
    },
    mounted() {
        window.scrollTo(0, 0);
    },
};
</script>

<style scoped lang="scss">
.breadcrumbs-link {
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #9ca8b6;
}

.nft-go-back {
    display: none;
    margin-bottom: 20px;
}

.nft-mobile {
    display: none;
}

.nft-page {
    padding-top: 40px;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
    min-height: calc(100vh - (75px + 82px));

    &__back-link {
        cursor: pointer;
    }

    &__footer {
        z-index: 5;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: white;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 10px 0px 52px rgba(154, 168, 196, 0.4);

        &--action {
            display: flex;
            align-items: center;
            img {
                margin-right: 30px;
            }
        }

        &--container {
            display: flex;
            align-items: center;
            max-width: 1134px;
            width: 100%;
            justify-content: space-between;
        }
    }

    &__socials {
        display: flex;
        align-items: center;

        &--title {
            color: #9ca8b6;
            font-size: 11px;
            margin-right: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.02em;
            text-transform: uppercase;
        }

        &--item {
            padding: 15px;
            background: #e8ecef;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                background-color: #d0d1d2;
            }

            &:nth-child(n + 2) {
                margin-left: 15px;
            }

            img {
                width: 16px;
            }
        }

        &--list {
            display: flex;
            align-items: center;
        }
    }

    &__left-column {
        padding-right: 120px;
    }

    &__right-column {
        .nft-mobile {
            display: none;
        }
    }

    hr {
        border: 1px solid #ebebeb;
        margin: 0;
        transform: translateY(-1px);
    }

    &__options {
        display: flex;
        align-items: center;
        margin-top: 40px;

        button {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            // line-height: 20px;
            // letter-spacing: 0.02em;
            text-transform: uppercase;
            color: #647282;
            padding: 3px;
            gap: 8px;
            z-index: 2;
            // background: #e8ecef;
            // border-radius: none;
            border: none;
            background-color: unset;
            border-bottom: 2px solid transparent;
            cursor: pointer;
            text-transform: uppercase;

            &:nth-child(n + 2) {
                margin-left: 20px;
            }

            // &:hover {
            //     background: #d7d9da;
            // }

            &.active {
                border-bottom: 2px solid #ffdb5d;
                // background: #ffdb5d;
                // color: #353c45;

                // &:hover {
                //     background: #eac74a;
                // }
            }
        }
    }

    @keyframes show {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    &__nft-bg {
        background-color: #3a3e45;
        width: 455px;
        height: 566px;
        border-radius: 13.2605px;
        background-image: url("~@/assets/nft/lines.png");
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
            position: absolute;
            top: calc(50% - 200px);
            left: calc(50% - 200px);

            animation-name: show;
            animation-duration: 0.5s;
        }
    }

    &__container {
        width: 100%;
        max-width: 1134px;
    }

    &__description {
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: -0.02em;
        color: #647282;
    }

    &__subtitle {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.02em;
        color: #353c45;
        margin-top: 30px;
    }

    &__title {
        font-weight: 400;
        color: #353c45;
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 48px;
        letter-spacing: -0.02em;
    }
}

@media screen and (max-width: 1180px) {
    .nft-page__container {
        padding: 20px;
    }

    .nft-page__container .row {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .breadcrumbs-link {
            display: none;
        }

        .nft-page__left-column {
            order: 2;
            padding-right: 0;
            text-align: center;
        }

        .nft-page__options {
            justify-content: center;
        }

        .nft-page__right-column {
            order: 1;
        }
    }

    .nft-go-back {
        display: block;
    }
}

@media screen and (max-width: 650px) {
    .nft-page {
        padding-top: 10px;

        &__container {
            .row {
                margin-bottom: 40px;
            }
        }
    }

    .nft-page__footer {
        &--container {
            flex-direction: column;
            justify-content: center;
        }

        &--action {
            margin-top: 20px;
        }
    }
}

@media screen and (max-width: 490px) {
    .nft {
        &-desktop {
            display: none;
        }

        &-mobile {
            display: block;
        }

        &-go-back {
            margin-bottom: 0;
        }

        &-page {
            &__container {
                .row {
                    .nft-page__left-column {
                        order: 1;
                        padding-right: 0;

                        .nft-page__title {
                            font-size: 24px;
                        }
                    }
                }
            }

            &__left-column {
                text-align: left !important;
            }

            &__right-column {
                .nft-mobile {
                    display: block;
                }

                .nft-page__options {
                    margin-top: 30px;
                    display: flex;
                    justify-content: space-between;
                }

                .nft-page__socials {
                    display: none;
                    margin-bottom: 40px;

                    &--title {
                        color: #3e4956;
                        font-size: 18px;
                        margin-right: 20px;
                        font-style: normal;
                        font-weight: 300;
                        line-height: 20px;
                        letter-spacing: 0.02em;
                        text-transform: unset;
                    }
                }
            }

            &__nft-bg {
                width: 350px;
                height: 400px;
            }

            &__footer {
                &--action {
                    width: 100%;
                    flex-direction: column;

                    .donation__btn {
                        margin-top: 15px;
                        width: 100%;
                    }

                    img {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}
</style>
