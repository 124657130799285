<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-sixth-lviv :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignSixthLviv from "@/components/CampaignResults/CampaignSixthLviv.vue";

export default {
    name: "AboutSixthLviv",
    components: {
        BaseCampaignPage,
        CampaignSixthLviv
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for  Lviv 6th City Clinic",
                    mainText: 'Andersen Charity strongly believes that solidarity is vital during the war. Together, we are fighting for a better future, and everyone can take noble action. Our company seeks to provide supplies to Ukrainian healthcare facilities. They are operating under an increased load and urgently need humanitarian aid. Our today’s goal is to help a clinic from Lviv.',
                    subText: 'Andersen Charity has provided the clinic with vital medical consumables. During the war, each donation is valuable. Syringes, gloves, bandages, containers – any Ukrainian clinic direly needs them nowadays. Now, the hospital staff can feel a little more secure when treating patients.',
                    amount: "4,853",
                },
                commentType: 'sixth-lviv-comments'
            };
        },
    }
};
</script>