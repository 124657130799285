<template>
    <section id="results">
        <div class="hospitals-top"></div>
        <div class="hospitals">
            <div class="hospitals-titles">
                <div class="hospitals-header">
                    Campaigns Results
                </div>
                <div class="hospitals-subheader">
                    {{hospitals[selectedHospital].title}}
                </div>
            </div>

            <TransitionGroup name="list" tag="div" class="hospitals-slider">
                <img
                v-for="(img, index) in shownHospitals" :key="img.id"
                :src="require(`@/assets/hospital-images/${img.image}`)"
                class="hospitals-slider__item"
                :class="calcImgClass(index)"
                >
            </TransitionGroup>
            <div class="hospitals-children-block">
                <img class="hospitals-balloons" src="@/assets/hospital-images/balloons.png">
                <img class="hospitals-children" src="@/assets/hospital-images/children.png">
            </div>
            <router-link :to="hospitals[selectedHospital].to" class="hospitals-details">
                View details
            </router-link>
            <div class="hospitals-step-bar">
                <div
                    v-for="step in hospitals.length" :key="step"
                    class="hospitals-step-bar__item"
                    :class="{'active': step === selectedHospital + 1}"
                />
            </div>
            <div class="hospitals-arrows">
                <div v-if="selectedHospital !== 0" class="hospitals-arrows__arrow left" @click="navigate('left')">
                    <img src="@/assets/hospital-images/arrow-left.svg" alt="" srcset="">
                </div>
                <div v-if="selectedHospital !== hospitals.length - 1" class="hospitals-arrows__arrow right" @click="navigate('right')">
                    <img src="@/assets/hospital-images/arrow-right.svg" alt="" srcset="">
                </div>
            </div>
        </div>
    </section>
</template>


<script>
const MOBILE_WIDTH = 640

export default {
    data() {
        return {
            resizeObserver: null,
            isMobile: false,
            selectedHospital: 0,
            hospitals: [
                { id: 0, title: "Infectious Hospital", image: '3.png', to: '/about-infectious' },
                { id: 1, title: "Maternity Hospital", image: '2.png', to: '/about-maternity-chernihiv' },
                { id: 2, title: "Children's clinic", image: '1.png', to: '/about-children-clinic' },
                { id: 3, title: "Maternity Clinic", image: '3.png', to: 'maternity-lviv' },
                { id: 4, title: "City Hospital", image: '2.png', to: '/about-city' },
                { id: 5, title: "Children's Hospital", image: '1.png',to: '/about-children' },
                { id: 6, title: "Maternity Hospital", image: '3.png', to: '/about-maternity' },
                { id: 7, title: "Oncological Clinic", image: '2.png', to: '/about-onco' },
            ],
        }
    },
    mounted() {
        this.resizeObserver = new ResizeObserver(this.checkMobile);
        this.resizeObserver.observe(document.querySelector('html'));
    },
    beforeDestroy() {
        if (this.resizeObserver) {
            this.resizeObserver.unobserve(document.querySelector('html'));
        }
    },
    computed: {
        shownHospitals() {
            let hospitals = JSON.parse(JSON.stringify(this.hospitals))
            if(this.hospitals.length === 3 && this.selectedHospital === 0) {
                const createdHospital = {...hospitals[hospitals.length - 1]}
                createdHospital.id = -1
                hospitals.push(createdHospital)
            } else if(this.hospitals.length === 3 && this.selectedHospital === hospitals.length - 1) {
                const createdHospital = {...hospitals[0]}
                createdHospital.id = hospitals.length
                hospitals.push(createdHospital)
            }
            const currentHospital = hospitals[this.selectedHospital]
            const prevHospital = this.selectedHospital === 0 ? hospitals[hospitals.length - 1] : hospitals[this.selectedHospital - 1]
            const nextHospital = this.selectedHospital === hospitals.length - 1 ? hospitals[0] : hospitals[this.selectedHospital + 1]

            return [prevHospital, currentHospital, nextHospital]
        }
    },
    methods: {
        calcImgClass(index) {
            if(index === 0) {
                return 'left'
            } else if(index === 1) {
                return 'center'
            } else {
                return 'right'
            }
        },
        checkMobile() {
            this.isMobile = window.innerWidth <= MOBILE_WIDTH;
        },
        navigate(type){
            if(type === 'left') {
                if(this.selectedHospital !== 0) {
                    this.selectedHospital--
                }
            } else {
                if(this.selectedHospital !== this.hospitals.length - 1) {
                    this.selectedHospital++
                }
            }
        },
    }
}
</script>




<style lang="scss" scoped>
//Colors
$text-color: #353C45;
$primary-color: #FFDB5D;
$secondary-color: #E0E0E0;

//Media
$mobile-xs: 450px;
$mobile: 640px;
$tablet-book: 961px;
$tablet-album: 1024px;
$lg: 1279px;
$xl: 1439px;

.hospitals-top {
    background-image: url("~@/assets/backgounds/bg-grey-2.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 105%;
    height: 400px;
    margin-bottom: -200px;
    @media(max-width: $tablet-album) {
        height: 300px;
        margin-bottom: -150px;
    }

    @media(max-width: $tablet-book) {
        height: 250px;
        margin-bottom: -120px;
    }

    @media(max-width: $mobile) {
        height: 150px;
        margin-bottom: -60px;
    }
}


.hospitals {
    overflow: hidden;
    height: 750px;
    background-image: url('../assets/hospital-images/2560_1440.png');
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;



    @media(max-width: $lg) {
        height: 600px;
    }

    @media(max-width: $tablet-book) {
        height: 450px;
    }

    @media(max-width: $mobile) {
        height: 350px;
        background-position: bottom;

    }

    .hospitals-slider {
        position: absolute;
        bottom: 270px;
        @media(max-width: $lg) {
            bottom: 220px;
        }
        @media(max-width: $tablet-book) {
            bottom: 150px;
        }
        @media(max-width: $mobile) {
            display: flex;
            bottom: 60px;
        }
    }


    .hospitals-slider__item {
        height: 200px;
        transition: all .5s;

        @media(max-width: $xl) {
            height: 160px;
        }

        @media(max-width: $tablet-album) {
            height: 160px;
        }

        @media(max-width: $tablet-book) {
            height: 100px;
        }

        @media(max-width: $mobile) {
            height: 20vw;
            right: -25px;
        }

        &.left {
            top: 140px;
            position: relative;
            transform: translate(-40%, 0) rotate(-25deg);
            @media(max-width: $tablet-book) {
                top: 60px;
            }
            @media(max-width: $mobile) {
                top: 5px;
                transform: none;
                left: -100%;
            }
            &.list-enter {
                opacity: 0;
                transform: translate(-100%, 50%) rotate(-40deg);
            }
            &.list-leave-to {
                opacity: 0;
                transform: translate(-100%, 50%) rotate(-60deg);
                @media(max-width: $tablet-book) {
                    transform: translate(-100%, 50%) rotate(-60deg);
                }
            }
            &.list-leave-active {
                position: absolute;
            }
        }
        &.center {
            top: 40px;
            position: relative;
            @media(max-width: $tablet-book) {
                top: 20px;
            }
            @media(max-width: $mobile) {
                top: 5px;
                &.list-enter {
                    opacity: 0;
                    transform: translate(-100%, 100%) rotate(-40deg);
                }
                &.list-leave-to {
                    opacity: 0;
                    transform: translate(-100%, 200%) rotate(-60deg);
                    @media(max-width: $tablet-book) {
                        transform: translate(-100%, 300%) rotate(-60deg);
                    }
                }
                &.list-leave-active {
                    position: absolute;
                }
            }
        }
        &.right {
            top: 140px;
            position: relative;
            transform: translate(40%, 0) rotate(25deg);
            @media(max-width: $tablet-book) {
                top: 60px;
            }
            @media(max-width: $mobile) {
                top: 5px;
                transform: none;
                right: -100%;
            }
            &.list-enter {
                opacity: 0;
                transform: translate(100%, 50%) rotate(40deg);
            }
            &.list-leave-to {
                opacity: 0;
                transform: translate(0, 50%) rotate(60deg);
                @media(max-width: $tablet-book) {
                    transform: translate(0, 50%) rotate(60deg);
                }
            }
            &.list-leave-active {
                position: absolute;
            }
        }
    }

    .hospitals-arrows {
        max-width: 1440px;
        width: 100%;
        display: block;
        position: relative;
    }

    .hospitals-arrows__arrow {
        border: 1px solid $primary-color;
        width: 64px;
        height: 64px;
        position: absolute;
        border-radius: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media(max-width: $tablet-album) {
            width: 48px;
            height: 48px;
        }

        @media(max-width: $tablet-book) {
            width: 48px;
            height: 48px;
        }
        @media(max-width: $mobile) {
            width: 36px;
            height: 36px;
            top: 60px;
        }

        &.left {
            left: 64px;
            @media(max-width: $tablet-book) {
                left: 32px;
            }
        }
        &.right {
            right: 64px;
            @media(max-width: $tablet-book) {
                right: 32px;
            }
        }

        img {
            width: 30px;
            @media(max-width: $tablet-book) {
                width: 24px;
            }
            @media(max-width: $mobile) {
                width: 20px;
            }
        }
    }

    .hospitals-children-block {
        position: absolute;
        top: 50%;
    }

    .hospitals-children {
        width: 180px;
        position: absolute;
        top: 50px;
        left: -270px;

        @media(max-width: $xl) {
            width: 160px;
        }

        @media(max-width: $tablet-album) {
            width: 140px;
            top: 50px;
            left: -210px;
        }
        @media(max-width: $tablet-book) {
            width: 110px;
            top: 50px;
            left: -160px;
        }
        @media(max-width: $mobile) {
            width: 17vw;
            bottom: 100px;
            left: -28vw;
            top: 70px;
        }
    }

    .hospitals-balloons {
        width: 90px;
        position: absolute;
        top: -140px;
        left: -255px;
        animation: up-n-down-desktop 3s infinite;

        @media(max-width: $xl) {
            width: 85px;
            left: -260px;
        }

        @media(max-width: $tablet-album) {
            width: 70px;
            animation: up-n-down-tablet-album 3s infinite;
            left: -198px;
        }
        @media(max-width: $tablet-book) {
            width: 60px;
            animation: up-n-down-tablet-book 3s infinite;
            left: -153px;
        }
        @media(max-width: $mobile) {
            width: 7vw;
            animation: up-n-down-tablet-mobile 3s infinite;
            left: -25vw;
        }
        @media(max-width: $mobile-xs) {
            width: 7vw;
            animation: up-n-down-tablet-mobile-xs 3s infinite;
            left: -25vw;
        }
    }

    .hospitals-details {
        position: absolute;
        outline: none;
        border: none;
        background-color: $primary-color;
        width: 186px;
        height: 52px;
        bottom: 48px;
        border-radius: 8px;
        font-size: 14px;
        color: $text-color;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background: #F5CE42;
        }

        &:active {
            background: #F1C62E;
        }

        &:disabled {
            background: #F9DC76;
            color: #BBA557;
        }

        @media(max-width: $lg) {
            bottom: 40px;
        }

        @media(max-width: $tablet-album) {
            bottom: 40px;
        }
        @media(max-width: $tablet-book) {
            bottom: 40px;
        }
        @media(max-width: $mobile) {
            bottom: 8px;
            height: 40px;
            border-radius: 5px;
        }
    }

    .hospitals-step-bar {
        display: flex;
        gap: 0 8px;
        position: absolute;
        bottom: 24px;
        @media(max-width: $tablet-album) {
            bottom: 16px;
        }
        @media(max-width: $tablet-book) {
            bottom: 16px;
        }
        @media(max-width: $mobile) {
            bottom: 4px;
            display: none;
        }

    .hospitals-step-bar__item {
        width: 8px;
        height: 8px;
        background-color: $secondary-color;
        border-radius: 60px;

            &.active {
                background-color: $primary-color;
            }
        }
    }

    .hospitals-titles {
        position: absolute;
        top: 5vh;
        color: $text-color;
        text-align: center;

        .hospitals-header {
            font-size: 32px;
            margin-bottom: 25px;
        }
        .hospitals-subheader {
            font-size: 18px;
        }
        @media(max-width: $mobile) {
            top: 5vh;
        }
    }

    @keyframes up-n-down-desktop {
        0%   {top: -140px}
        50%  {top: -120px}
        100% {top: -140px}
    }

    @keyframes up-n-down-tablet-album {
        0%   {top: -80px}
        50%  {top: -100px}
        100% {top: -80px}
    }

    @keyframes up-n-down-tablet-book {
        0%   {top: -70px}
        50%  {top: -90px}
        100% {top: -70px}
    }

    @keyframes up-n-down-tablet-mobile {
        0%   {top: 1vh}
        50%  {top: -1vh}
        100% {top: 1vh}
    }

    @keyframes up-n-down-tablet-mobile-xs {
        0%   {top: 2vh}
        50%  {top: 0}
        100% {top: 2vh}
    }
}
</style>