var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{ref:"nft",staticClass:"section nft",attrs:{"id":"nft"}},[_c('img',{staticClass:"nft-bg",attrs:{"src":require("@/assets/backgounds/nft-bg.png")}}),_c('img',{staticClass:"nft-bg nft-bg-svg",attrs:{"src":require("@/assets/backgounds/nft-bg.svg")}}),(!_vm.animateScroll)?_c('img',{staticClass:"nft-lines",staticStyle:{"z-index":"2","top":"-20%"},attrs:{"id":"nft-lines","src":require("@/assets/lines.svg")}}):_vm._e(),(_vm.isWide)?_c('div',{staticClass:"section__container"},[(_vm.animateScroll)?_c('img',{staticClass:"fade-left",attrs:{"src":require("@/assets/backgounds/fade-left.svg")}}):_vm._e(),(_vm.animateScroll)?_c('img',{staticClass:"fade-right",attrs:{"src":require("@/assets/backgounds/fade-right.svg")}}):_vm._e(),(_vm.animateScroll)?_c('img',{staticClass:"nft-lines",staticStyle:{"z-index":"2","top":"-20%"},attrs:{"id":"nft-lines","src":require("@/assets/lines.png")}}):_vm._e(),_c('img',{staticClass:"nft-egg",style:({
                top: `${
                    _vm.animateScroll ? _vm.nftEggParallax + 50 - _vm.eggTopReplace : 80
                }px`,
            }),attrs:{"src":require("@/assets/nft/nft-egg.png")}})]):_vm._e(),(!_vm.isWide)?[(_vm.animateScroll)?_c('img',{staticClass:"fade-left",attrs:{"src":require("@/assets/backgounds/fade-left.svg")}}):_vm._e(),(_vm.animateScroll)?_c('img',{staticClass:"fade-right",attrs:{"src":require("@/assets/backgounds/fade-right.svg")}}):_vm._e(),(_vm.animateScroll)?_c('img',{staticClass:"nft-lines",staticStyle:{"z-index":"2","top":"-20%"},attrs:{"id":"nft-lines","src":require("@/assets/lines.png")}}):_vm._e(),_c('img',{staticClass:"nft-egg",style:({
                top: `${
                    _vm.animateScroll ? _vm.nftEggParallax + 50 - _vm.eggTopReplace : 80
                }px`,
            }),attrs:{"src":require("@/assets/nft/nft-egg.png")}})]:_vm._e(),_c('div',{staticClass:"section__container"},[_c('h2',{staticClass:"nft__title title title--color--white"},[_vm._v(" Traditional NFT art ")]),_c('p',{staticClass:"nft__text text text--color--white"},[_vm._v(" Our featured digital assets are embodying the spirit of humanity. Each digital artwork is a symbol of a common healthy future. Every contribution is to help in the implementation of our main mission - to improve the lives of people living. ")]),_vm._m(0),_vm._m(1),_vm._m(2),_c('a',{staticClass:"nft__btn btn",staticStyle:{"width":"186px","padding":"16px 0"},attrs:{"href":"https://opensea.io/SunCharitybyAndersen","target":"_blank"}},[_vm._v("Go to OpenSea")]),_c('router-link',{staticClass:"nft__link link link--color--white",attrs:{"to":"/nft"}},[_vm._v("Read MORE")])],1),_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 1440 295"}},[_c('path',{attrs:{"fill":"#F7F8FA","fill-opacity":"1","d":"M0,224L120,234.7C240,245,480,267,720,266.7C960,267,1200,245,1320,234.7L1440,224L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"}})])],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nft__counter"},[_c('div',{staticClass:"nft__counter-number"},[_vm._v("100")]),_c('div',{staticClass:"nft__counter-text"},[_vm._v("New NFT per day")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nft__counter"},[_c('div',{staticClass:"nft__counter-number"},[_vm._v("20+")]),_c('div',{staticClass:"nft__counter-text"},[_vm._v("Unique NFT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nft__counters"},[_c('div',{staticClass:"nft__counter"},[_c('div',{staticClass:"nft__counter-number"},[_vm._v("100")]),_c('div',{staticClass:"nft__counter-text"},[_vm._v("New NFT per day")])]),_c('div',{staticClass:"nft__counter"},[_c('div',{staticClass:"nft__counter-number"},[_vm._v("20+")]),_c('div',{staticClass:"nft__counter-text"},[_vm._v("Unique NFT")])])])
}]

export { render, staticRenderFns }