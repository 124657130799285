<template>
    <section id="about" class="about-section">
        <div class="about-section__container">
            <div>
                <h2 class="about-section__title">About Andersen</h2>
            </div>
            <div class="about-section__text-wrapper">
                <p class="about-section__text">
                    Andersen is an international IT company with a strong focus on
                    technology-intensive industries. For over 15 years, we have been
                    helping enterprises all over the world to transform business by
                    creating effective digital solutions with the use of innovative
                    technologies. Over 1000 of our IT family members are suffering
                    the consequences of the war now.
                </p>
                <p class="about-section__text">
                    We are part of it, and our main task is to help our colleagues
                    and every Ukrainian as much as possible. Oleksandr Orlov, CTO at
                    Andersen and a founder at Andersen Charity, stands together with
                    the company for peace.
                </p>
            </div>
            <hr />
            <div>
                <div class="about-section__info">
                    <img src="../assets/ceo.png" width="72" height="72" alt="ceo">
                    <div class="about-section__info--titles">
                        <div class="titles-img-wrapper">
                            <span class="titles-name">Oleksandr Orlov</span>
                            <a href="https://www.linkedin.com/in/alexandr-orlov/" target="_blank" rel="noopener noreferrer">
                                <img src="../assets/linkedin.png" width="24" height="24" alt="">
                            </a>
                        </div>
                        <span class="titles-position">Charity Founder</span>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "AboutSection",
};
</script>
