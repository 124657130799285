<template>
    <section
        ref="nft"
        id="nft"
        class="section nft"
    >
        <img class="nft-bg" src="@/assets/backgounds/nft-bg.png" />
        <img class="nft-bg nft-bg-svg" src="@/assets/backgounds/nft-bg.svg" />
        <img
            v-if="!animateScroll"
            class="nft-lines"
            id="nft-lines"
            src="@/assets/lines.svg"
            style="z-index: 2; top: -20%"
        />
        <div v-if="isWide" class="section__container">
            <img
                v-if="animateScroll"
                class="fade-left"
                src="@/assets/backgounds/fade-left.svg"
            />
            <img
                v-if="animateScroll"
                class="fade-right"
                src="@/assets/backgounds/fade-right.svg"
            />
            <img
                v-if="animateScroll"
                id="nft-lines"
                class="nft-lines"
                src="@/assets/lines.png"
                style="z-index: 2; top: -20%"
            />
            <img
                src="@/assets/nft/nft-egg.png"
                :style="{
                    top: `${
                        animateScroll ? nftEggParallax + 50 - eggTopReplace : 80
                    }px`,
                }"
                class="nft-egg"
            />
        </div>
        <template v-if="!isWide">
            <img
                v-if="animateScroll"
                class="fade-left"
                src="@/assets/backgounds/fade-left.svg"
            />
            <img
                v-if="animateScroll"
                class="fade-right"
                src="@/assets/backgounds/fade-right.svg"
            />
            <img
                v-if="animateScroll"
                id="nft-lines"
                class="nft-lines"
                src="@/assets/lines.png"
                style="z-index: 2; top: -20%"
            />
            <img
                src="@/assets/nft/nft-egg.png"
                :style="{
                    top: `${
                        animateScroll ? nftEggParallax + 50 - eggTopReplace : 80
                    }px`,
                }"
                class="nft-egg"
            />
        </template>
        <div class="section__container">
            <h2 class="nft__title title title--color--white">
                Traditional NFT art
            </h2>
            <p class="nft__text text text--color--white">
                Our featured digital assets are embodying the spirit of
                humanity. Each digital artwork is a symbol of a common healthy
                future. Every contribution is to help in the implementation of
                our main mission - to improve the lives of people living.
            </p>
            <div class="nft__counter">
                <div class="nft__counter-number">100</div>
                <div class="nft__counter-text">New NFT per day</div>
            </div>
            <div class="nft__counter">
                <div class="nft__counter-number">20+</div>
                <div class="nft__counter-text">Unique NFT</div>
            </div>

            <div class="nft__counters">
                <div class="nft__counter">
                    <div class="nft__counter-number">100</div>
                    <div class="nft__counter-text">New NFT per day</div>
                </div>
                <div class="nft__counter">
                    <div class="nft__counter-number">20+</div>
                    <div class="nft__counter-text">Unique NFT</div>
                </div>
            </div>
            <a
                href="https://opensea.io/SunCharitybyAndersen"
                style="width: 186px; padding: 16px 0"
                target="_blank"
                class="nft__btn btn"
                >Go to OpenSea</a
            >
            <router-link to="/nft" class="nft__link link link--color--white"
                >Read MORE</router-link
            >
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 295">
            <path fill="#F7F8FA" fill-opacity="1" d="M0,224L120,234.7C240,245,480,267,720,266.7C960,267,1200,245,1320,234.7L1440,224L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"></path>
        </svg>
    </section>
</template>

<script>
export default {
    name: 'Nft',
    data() {
        return {
            animateScroll: true,
            nftEggParallax: 0,
            eggTopReplace: 0,
            isWide: false
        }
    },
    mounted() {
        if (window.innerWidth > 1920) {
            this.isWide = true
        } else {
            this.isWide = false
        }
        this.handleNftScroll()
    },
     methods: {
        handleNftScroll() {
            if (window.innerWidth <= 376) {
                this.animateScroll = false
            } else {
                this.animateScroll = true
            }

            if (window.innerWidth <= 1080) {
                this.eggTopReplace = -100
            } else if (window.innerWidth <= 1200 && window.innerWidth > 1180) {
                this.eggTopReplace = 80
            } else {
                this.eggTopReplace = 0
            }

            document.addEventListener('scroll', this.scrollHandler)

            window.addEventListener('resize', () => {
                if (window.innerWidth <= 376) {
                    this.animateScroll = false
                } else {
                    this.animateScroll = true
                }

                if (window.innerWidth <= 1080) {
                    this.eggTopReplace = -100
                } else if (window.innerWidth <= 1200 && window.innerWidth > 1180) {
                    this.eggTopReplace = 80
                } else {
                    this.eggTopReplace = 0
                }

                if (window.innerWidth > 1920) {
                    this.isWide = true
                } else {
                    this.isWide = false
                }
            })
        },
        scrollHandler() {
            if (this.isVisibleElement(this.$refs.nft)) {
                this.nftEggParallax = -(window.pageYOffset / 20 - 150)
            } else {
                this.nftEggParallax = 0
            }
        },
        isVisibleElement(target, diffSize) {
            const targetPosition = {
                top: window.pageYOffset + target?.getBoundingClientRect()?.top,
                left: window.pageXOffset + target?.getBoundingClientRect()?.left,
                right: window.pageXOffset + target?.getBoundingClientRect()?.right,
                bottom: window.pageYOffset + target?.getBoundingClientRect()?.bottom
            }
            // Получаем позиции окна
            const windowPosition = {
                top: window.pageYOffset,
                left: window.pageXOffset,
                right: window.pageXOffset + document.documentElement.clientWidth,
                bottom: window.pageYOffset + document.documentElement.clientHeight
            }

            if (targetPosition.bottom > windowPosition.top && // Если позиция нижней части элемента больше позиции верхней чайти окна, то элемент виден сверху
                targetPosition.top + (diffSize ?? 300) < windowPosition.bottom && // Если позиция верхней части элемента меньше позиции нижней чайти окна, то элемент виден снизу
                targetPosition.right > windowPosition.left && // Если позиция правой стороны элемента больше позиции левой части окна, то элемент виден слева
                targetPosition.left < windowPosition.right) { // Если позиция левой стороны элемента меньше позиции правой чайти окна, то элемент виден справа
                // Если элемент полностью видно, то запускаем следующий код
                return true
            }

            return false
        }
    }

}
</script>
