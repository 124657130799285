<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-medical-center :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignMedicalCenter from "@/components/CampaignResults/CampaignMedicalCenter.vue";

export default {
    name: "AboutChernihivFourth",
    components: {
        BaseCampaignPage,
        CampaignMedicalCenter
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Lviv Military Medical Center of the State Border Guard Service of Ukraine",
                    mainText: "Our goal was to provide the hospital with the crucial medical equipment to deliver rapid and effective care and to ensure their staff is not overloaded and has all the needed skills and knowledge to address the unique healthcare challenges.",
                    subText: "Through your support, the hospital can now provide better medical services with improved equipment and a wide range of medical supplies. With your help, patients can now benefit from high-quality psychological support, enhanced rehabilitation facilities, and advanced medical training.",
                    amount: "4,765",
                },
                commentType: 'medical-center'
            };
        },
    }
};
</script>