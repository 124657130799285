<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-onco :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignOnco from "@/components/CampaignResults/CampaignOnco";

export default {
    name: "AboutOnco",
    components: {
        BaseCampaignPage,
        CampaignOnco
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Cherkasy Regional Oncological Clinic",
                    mainText: ` Andersen Charity led a crucial campaign to deliver
                        monitoring systems. Our goal was to increase the number of patients
                        with quality care access and decrease the load in the hospital.`,
                    subText: `We successfully delivered two systems for patient condition
                        monitoring and helped our surgeons to perform more surgical operations
                        per day with less stress. Together we improved the outcomes
                        of the hospital and increased the efficiency of
                        postoperative care by providing needed equipment.`,
                    amount: "10,000",
                },
                commentType: 'onco-comments'
            };
        },
    }
};
</script>