<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignChildrenClinic",
    props: {
        isText: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        BaseCampaignResult
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Children’s City Hospital of Cherkasy",
                isText: this.isText,
                text: `The children's hospital receives patients from the entire region. It gives emergency medical care and helps all children to recover. It is an immense pleasure to see their happy eyes again ...`,
                photosAmount: 18,
                cssClass: 'children-clinic',
                path: 'children-clinic',
                videoProps: {
                    photosCount: 21,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15, 16, 17, 18, 19, 20 ],
                    },
                    srcFotos: 'children-clinic',
                    videoLink: 'https://www.youtube.com/embed/Fzmooz8lyQ8'
                }
            }
        }
    },
};
</script>
