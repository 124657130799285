<template>
    <section id="what-we-do" class="section features">
        <div class="section__container">
            <h2 class="section__title title title--h2">What we do</h2>
            <div class="feature-list">
                <Feature
                    v-for="(feature, index) in features"
                    :key="index"
                    :iconLink="feature.iconLink"
                    :title="feature.title"
                    :text="feature.text"
                />
            </div>
        </div>
    </section>
</template>

<script>
import Feature from './Feature.vue'

export default {
    name: 'WhatWeDo',
    components: { Feature },
    data() {
        return {
            features: [
                {
                    iconLink: 'medical-equipment.svg',
                    title: 'To modernize health care',
                    text: 'Ukrainian hospitals  should go forward in step up with  the time with the help of modern equipment.'
                },
                {
                    iconLink: 'medicines-help.svg',
                    title: 'To respond to the needs',
                    text: 'Our goal is to inspire hope and contribute to health with the help of an extraordinary array of medical resources.'
                },
                {
                    iconLink: 'supplies.svg',
                    title: 'To make the care accessible',
                    text: 'We focus on creating an affordable reality with exceptional medical care for everyone by contributing to a common healthy future.'
                },
                {
                    iconLink: 'care-service.svg',
                    title: 'To give security and hope',
                    text: 'The needs of the patient come first. We focus on improving outcomes by giving resources for quality care to healthcare institutions.'
                }
            ]
        }
    }
}
</script>
