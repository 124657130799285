<template>
    <div v-if="isOpen" class="base-modal">
        <div
            class="base-modal__content"
            :style="{ minWidth: minWidth + 'px'}"
        >
            <div class="row justify-end">
                <button
                    @click="$emit('closeModal')"
                    class="base-modal__close-btn"
                >
                    <img src="@/assets/icons/close-icon.svg" />
                </button>
            </div>
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'BaseModal',
    props: {
        isOpen: {
            type: Boolean,
            default: false
        },
        minWidth: {
            type: Number,
            default: 550
        }
    }
};
</script>

<style scoped lang="scss">
.row {
    display: flex;
}

.justify-end {
    justify-content: flex-end;
}

.base-modal {
    @keyframes show {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;

    &__content {
        padding: 15px 30px;
        background: #ffffff;
        box-shadow: 0px 0px 52px rgba(154, 168, 196, 0.16);
        border-radius: 16px;
        animation-name: show;
        animation-duration: 0.5s;

        @media screen and (max-width: 420px) {
            min-width: 370px !important;

            button.btn {
                width: 100% !important;
                margin-bottom: 0;
            }
        }
    }

    &__close-btn {
        border: none;
        background: transparent;
        cursor: pointer;
    }
}
</style>
