<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-children-polyclinic :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignChildrenPolyclinic from "@/components/CampaignResults/CampaignChildrenPolyclinic.vue";

export default {
    name: "AboutChildrenPolyclinic",
    components: {
        BaseCampaignPage,
        CampaignChildrenPolyclinic
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Chernihiv Children's Polyclinic",
                    mainText: "As the turmoil of military actions continues, doctors are delivering care to both civilians and soldiers under increased pressure. With a firm belief in the value of human life and recognizing the importance of support and solidarity during these challenging times, Andersen has given a helping hand to a children's polyclinic in Chernihiv.",
                    subText: "We have supplied the essential items, medications, and pills necessary for a smooth clinical process. We are immensely grateful to all those who back our efforts. Joining forces, we are equipping healthcare professionals, aiding patients, and striving for a better future.",
                    amount: "5,043",
                },
                commentType: 'children-polyclinic'
            };
        },
    }
};
</script>