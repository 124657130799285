<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignChernihivFourth",
    props: {
        isText: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        BaseCampaignResult
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Chernihiv Regional Hospital for War Veterans",
                isText: this.isText,
                text: "The Chernihiv Regional Hospital for War Veterans serves as a refuge for those who bravely defended their homeland, bearing the heavy burden of war, and now need our collective support ...",
                photosAmount: 17,
                cssClass: 'chernihiv-five',
                path: 'chernihiv-five',
                isVideoAvailable: false,

                videoProps: {
                     photosCount: 20,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15, 16, 17, 18, 19, 20  ],
                    },
                    srcFotos: 'chernihiv-five',
                }

            }
        }
    },
};
</script>
