<template>
    <div class="campaigns-results__container">
        <div class="media-block">
            <div
                @click="onOpenVideo"
                :class="['media-block__video-'+cssClass, onHoverCssClass]"
            >
                <button
                    v-if="!isHovered && isVideoAvailable"
                    :class="'media-block__video-'+cssClass+'--btn'"
                >
                    <img src="@/assets/icons/play.svg" />
                </button>
            </div>
            <div class="media-block__photos">
                <div
                    :class="'media-block__photos--first-'+cssClass"
                    @mouseenter="() => (hoveredFirstFoto = true)"
                    @mouseleave="() => (hoveredFirstFoto = false)"
                />
                <div
                    :class="'media-block__photos--second-'+cssClass"
                    @mouseenter="() => (hoveredSecondFoto = true)"
                    @mouseleave="() => (hoveredSecondFoto = false)"
                />
                <div
                    class="media-block__photos--third"
                    :class="'media-block__photos--third-'+cssClass"
                    @click="onOpenVideo('open')"
                    @mouseenter="() => (hoveredThirdFoto = true)"
                    @mouseleave="() => (hoveredThirdFoto = false)"
                >
                    +{{ photosAmount }} photos
                </div>
            </div>
        </div>
        <div v-if="isText" class="text-block">
            <div>
                <p class="text-block__title">{{ title }}</p>
                <p class="text-block__description">{{ text }}</p>
                <button class="btn donation__btn" @click="goToAboutUs">
                    Read more
                </button>
            </div>
        </div>
        <base-video-modal
            :isOpenVideo="isOpenVideo"
            :onClose="handleClose"
            v-bind="videoProps"
        />
    </div>
</template>

<script>
import BaseVideoModal from "@/components/Base/BaseVideoModal.vue";

export default {
    name: "BaseCampaignResult",
    components: {
        BaseVideoModal,
    },
    props: {
        title: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        photosAmount: {
            type: Number,
            required: true
        },
        cssClass: {
            type: String,
            required: true
        },
        path: {
            type: String,
            required: true
        },
        isText: {
            type: Boolean,
            default: () => true,
        },
        videoProps: {
            type: Object,
            required: true
        },
        isVideoAvailable: {
            type: Boolean,
            default: () => true
        }
    },
    data() {
        return {
            hoveredFirstFoto: false,
            hoveredSecondFoto: false,
            hoveredThirdFoto: false,
            isOpenVideo: false,
            isClickable: false
        };
    },
    computed: {
        isHovered() {
            return (
                this.hoveredFirstFoto ||
                this.hoveredSecondFoto ||
                this.hoveredThirdFoto
            );
        },
        onHoverCssClass() {
            return {
                 [`media-block__video-${this.cssClass}--on-hover-first`]: this.hoveredFirstFoto,
                 [`media-block__video-${this.cssClass}--on-hover-second`]: this.hoveredSecondFoto,
                 [`media-block__video-${this.cssClass}--on-hover-third`]: this.hoveredThirdFoto,
            }
        }
    },
    methods: {
        onOpenVideo(open) {
            if (open === 'open') {
                this.isOpenVideo = true
            }

            if (this.isClickable) {
                this.isOpenVideo = true;
            }

            if (this.isVideoAvailable) {
                this.isOpenVideo = true;
            }
        },
        handleClose() {
            this.isOpenVideo = false;
        },
        goToAboutUs() {
            this.$router.push(`/about-${this.path}`);
        },
    },
    mounted() {
        if (window.innerWidth <= 500) {
            this.isClickable = true
        }

        window.addEventListener('resize', () => {
            if (window.innerWidth <= 500) {
                this.isClickable = true
            } else {
                this.isClickable = false
            }
        })
    }
};
</script>
