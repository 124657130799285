<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-city-clinic :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignCityClinic from "@/components/CampaignResults/CampaignCityClinic.vue";

export default {
    name: "AboutCityClinic",
    components: {
        BaseCampaignPage,
        CampaignCityClinic
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Lviv Family City Clinic",
                    mainText: " The campaign could help improve access to healthcare services for families in Lviv by providing financial support for medical treatments, medications, and necessary equipment and contribute to supporting medical research initiatives aimed at finding innovative solutions, treatments, or preventive measures for specific health issues affecting families.",
                    subText: "The funds raised through the campaign can be now utilized to upgrade and enhance the medical facilities and allocated towards improving the infrastructure. We have successfully provided the hospital with the necessary equipment and medical supplies and ensured that its outcomes are improved.",
                    amount: "5,308",
                },
                commentType: 'city-clinic'
            };
        },
    }
};
</script>