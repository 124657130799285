var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"foundation-details",attrs:{"id":"details"}},[_c('div',{staticClass:"foundation-details-container"},[_c('h4',{staticClass:"foundation-details__title"},[_vm._v("Foundation details")]),_c('span',{class:[
                'foundation-details__option',
                { 'active-option': _vm.paymentComponent === 'ByCreditCard' },
            ],on:{"click":function($event){return _vm.setPaymentComponent('ByCreditCard')}}},[_vm._v("Pay by credit card")]),_c('span',{class:[
                'foundation-details__option',
                { 'active-option': _vm.paymentComponent === 'BySwift' },
            ],on:{"click":function($event){return _vm.setPaymentComponent('BySwift')}}},[_vm._v("SWIFT")]),_c(_vm.paymentComponent,{tag:"component"})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }