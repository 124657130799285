<template>
    <div v-if="isOpenVideo" class="slider__modal-video">
        <div class="slider__modal-video__content">
            <div class="content-wrapper">
                <button
                    class="slider__close-button white-button"
                    @click="closeModal"
                >
                    <img src="@/assets/icons/close-icon.svg" />
                </button>
                <iframe
                    v-if="activeFoto === 0 && videoLink"
                    class="slider__video"
                    :src="videoLink"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                />
                <div
                    v-else
                    class="slider__video-photos"
                    :style="`background-image: url('${getImage(activeFoto)}')`"
                ></div>
            </div>
            <div class="slider__photos">
                <div class="slider__photos-container">
                    <template v-if="activeFoto <= 6">
                        <active-foto
                            v-for="foto in fotos.firstFotos"
                            :key="foto"
                            :type="srcFotos"
                            :foto="foto"
                            @setActiveFoto="setActiveFoto"
                            :class="{
                                'slider__photos-item--active':
                                    activeFoto === foto,
                            }"
                        >
                            <svg
                                v-if="foto === 0 && videoLink"
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect
                                    opacity="0.9"
                                    x="28"
                                    width="28"
                                    height="28"
                                    rx="14"
                                    transform="rotate(90 28 0)"
                                    fill="#F3F5F7"
                                />
                                <path
                                    d="M18.0309 12.8328L13.1122 10.0317C12.846 9.8791 12.544 9.79916 12.2366 9.79999C11.9293 9.80083 11.6276 9.88243 11.3623 10.0365C11.097 10.1905 10.8775 10.4116 10.7261 10.6772C10.5746 10.9427 10.4967 11.2434 10.5001 11.5485V17.1708C10.5001 17.6294 10.6836 18.0692 11.0102 18.3935C11.3367 18.7178 11.7797 18.9 12.2415 18.9C12.5472 18.8995 12.8475 18.8193 13.1122 18.6674L18.0309 15.8664C18.2952 15.7145 18.5146 15.4963 18.6672 15.2336C18.8197 14.9709 18.9 14.673 18.9 14.3698C18.9 14.0666 18.8197 13.7687 18.6672 13.506C18.5146 13.2433 18.2952 13.0251 18.0309 12.8732V12.8328Z"
                                    fill="#353C45"
                                />
                            </svg>
                        </active-foto>
                    </template>
                    <template v-if="activeFoto > 6 && activeFoto < 14">
                        <active-foto
                            v-for="foto in fotos.secondFotos"
                            :key="foto"
                            :type="srcFotos"
                            :foto="foto"
                            @setActiveFoto="setActiveFoto"
                            :class="{
                                'slider__photos-item--active':
                                    activeFoto === foto,
                            }"
                        />
                    </template>
                    <template v-if="activeFoto > 13">
                        <active-foto
                            v-for="foto in fotos.thirdFotos"
                            :key="foto"
                            :type="srcFotos"
                            :foto="foto"
                            @setActiveFoto="setActiveFoto"
                            :class="{
                                'slider__photos-item--active':
                                    activeFoto === foto,
                            }"
                        />
                    </template>
                </div>
                <div class="slider__photos-actions">
                    <span class="slider__photos-actions--count">
                        {{ activeFoto + 1 }} of {{ photosCount }}
                    </span>
                    <button
                        class="slider__photos-actions--left"
                        @click="moveToClosestFoto('left')"
                        :disabled="activeFoto === 0"
                    >
                        <svg
                            v-if="activeFoto === 0"
                            width="7"
                            height="12"
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                opacity="0.3"
                                d="M5.49999 1.49999L0.99997 6L5.49999 10.5"
                                stroke="#353C45"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <svg
                            v-if="activeFoto > 0"
                            width="7"
                            height="12"
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M5.49999 1.49999L0.99997 6L5.49999 10.5"
                                stroke="#353C45"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </button>
                    <button
                        class="slider__photos-actions--right"
                        @click="moveToClosestFoto('right')"
                        :disabled="activeFoto === photosCount - 1"
                    >
                        <svg
                            v-if="activeFoto === photosCount - 1"
                            width="7"
                            height="12"
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                opacity="0.3"
                                d="M1.50001 1.49999L6.00003 6L1.50001 10.5"
                                stroke="#353C45"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <svg
                            v-if="activeFoto < photosCount - 1"
                            width="7"
                            height="12"
                            viewBox="0 0 7 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1.50001 1.49999L6.00003 6L1.50001 10.5"
                                stroke="#353C45"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ActiveFoto from "@/components/ActiveFoto.vue";

export default {
    name: "BaseVideoModal",
    props: {
        isOpenVideo: {
            type: Boolean,
            default: () => false,
        },
        onClose: {
            type: Function,
            required: true,
        },
        videoLink: {
            type: String,
            required: false,
        },
        fotos: {
            type: Object,
            required: true,
        },
        srcFotos: {
            type: String,
            required: true,
        },
        photosCount: {
            type: Number,
            required: true,
        },
    },
    components: {
        ActiveFoto,
    },
    data() {
        return {
            activeFoto: 0,
            transformSum: 0,
            moreThan9: 0,
        };
    },
    methods: {
        closeModal() {
            this.activeFoto = 0;
            this.onClose();
        },
        setActiveFoto(foto) {
            this.activeFoto = foto;
        },
        moveToClosestFoto(direction) {
            if (direction === "right") {
                this.activeFoto += 1;
            }

            if (direction === "left") {
                this.activeFoto -= 1;
            }
        },
        getImage(num) {
            console.log(this.srcFotos, num);
            console.log('====================================');
            console.log('====================================');
            return require(`@/assets/${this.srcFotos}/pic_${num}.jpg`);
        },
    },
};
</script>

<style scoped lang="scss">
.slider__close-button {
    position: absolute;
    top: -55px;
    right: 10px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
}

.slider {
    @keyframes show {
        from {
            transform: scale(0);
        }
        to {
            transform: scale(1);
        }
    }

    &__modal-video {
        background-color: rgba(0, 0, 0, 0.3);
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;

        &__content {
            margin-top: 65px;
            height: 80%;
            align-self: self-start;

            .content-wrapper {
                position: relative;
                height: 100%;
            }

            .slider__video-photos {
                width:1130px;
                max-width: 1130px;
                height: 100%;
                border-radius: 0;
                border-top-right-radius: 16px;
                border-top-left-radius: 16px;
                background-size: cover;
                // background-position: center;
                background-repeat: no-repeat;

                img {
                    width:1130px;
                    border-top-right-radius: 16px;
                    border-top-left-radius: 16px;
                    max-width: 1130px;
                    height: 100%;
                }
            }
        }

        iframe {
            border-radius: 0;
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
            animation-name: show;
            animation-duration: 0.5s;
            width: 1130px;
            height: 100%;
        }

        &__header {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 15px;
            button {
                cursor: pointer;
                width: 48px;
                height: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                border-radius: 50%;
            }
        }
    }

    &__photos {
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
        background-color: #3a3a3a;
        height: 85px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            max-width: 970px;
            padding: 0 5px;
            overflow: hidden;
        }

        &-img-wrapper {
            width: 105px;
            height: 70px;
            cursor: pointer;
            position: relative;
            border: 2px solid transparent;
            border-radius: 8px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            svg {
                position: absolute;
                height: 100%;
                left: 50%;
                transform: translate(-50%, 0);
            }
        }

        &-item {
            &--active {
                border: 2px solid #ffdb5d;
            }
        }

        &-actions {
            display: flex;
            align-items: center;

            &--count {
                font-weight: 200;
                font-size: 11px;
                line-height: 20px;
                color: white;
                text-transform: uppercase;
            }

            &--left,
            &--right {
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                background: #f3f5f7;
                opacity: 0.9;
                border-radius: 50%;
                cursor: pointer;
                border: 0;

                &:hover {
                    opacity: 0.8;
                }
            }

            &--left {
                margin: 0 8px 0 8px;
            }
            &--right {
                margin-right: 12px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .slider__modal-video__content {
        .content-wrapper {
            width: 800px !important;
            height: 600px !important;
        }
    }

    .slider__photos {
        width: 800px;
    }

    .slider__video-photos {
        width: 800px;
        height: 600px;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;

        img {
            width: 800px !important;
            height: 600px !important;
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
        }
    }

    .slider__photos-actions {
        &--count {
            min-width: 40px;
            margin-left: 7px !important;
        }
    }

    iframe {
        width: 800px !important;
        height: 600px !important;
    }

    .slider__photos {
        justify-content: center;
        &-container {
            display: none;
        }
    }

    .slider__photos-actions--left {
        margin: 0 30px 0 20px;
    }
}

@media screen and (max-width: 850px) {
    .slider__modal-video__content {
        .content-wrapper {
            width: 600px !important;
            height: 400px !important;
        }
    }

    .slider__photos {
        width: 600px;
    }

    .slider__video-photos {
        width: 600px;
        height: 400px;

        img {
            width: 600px !important;
            height: 400px !important;
        }
    }

    .slider__photos-actions {
        &--count {
            min-width: 50px;
            margin-left: 7px !important;
        }
    }

    iframe {
        width: 600px !important;
        height: 400px !important;
    }
}

@media screen and (max-width: 650px) {
    .slider__modal-video__content {
        .content-wrapper {
            width: 480px !important;
            height: 400px !important;
        }
    }

    .slider__photos {
        width: 480px;
    }

    .slider__video-photos {
        width: 480px;
        height: 400px;

        img {
            width: 480px !important;
            height: 400px !important;
        }
    }

    iframe {
        width: 480px !important;
        height: 400px !important;
    }
}

@media screen and (max-width: 490px) {
    .slider__modal-video__content {
        width: 90%;
        align-self: center;
        height: unset;
        margin-top: 0;

        .content-wrapper {
            width: 100% !important;
            height: 300px !important;
        }
    }

    .slider__photos {
        justify-content: center;
        width: 100% !important;

        &-container {
            display: none;
        }
    }

    .slider__video-photos {
        width: 100% !important;
        height: 100% !important;
        border-top-right-radius: 16px;
        border-top-left-radius: 16px;

        img {
            width: 100% !important;
            height: 100% !important;
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
        }
    }

    .slider__photos-actions--left {
        margin: 0 20px 0 20px;
    }

    iframe {
        width: 100% !important;
        height: 300px !important;
    }
}

@media only screen and (max-height: 400px) and (orientation: landscape) {
    .slider__modal-video__content {
        margin-top: 50px;

        .content-wrapper {
            width: 80vw !important;
            height: 65vh !important;
        }
    }

    .slider__close-button {
        width: 30px;
        height: 30px;
        top: -37px;

        img {
            width: 20px;
            height: 20px;
        }
    }

    .slider__photos {
        width: 80vw !important;
        height: 60px;
    }

    .slider__video-photos {
        width: 100% !important;
        height: 100% !important;

        img {
            width: 100% !important;
            height: 100% !important;
        }
    }

    .slider__photos-actions {
        &--count {
            min-width: 50px !important;
            margin-left: 7px !important;
        }
    }

    iframe {
        width: 100% !important;
        height: 100% !important;
    }
}
</style>
