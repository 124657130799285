<template>
    <div class="credit-card">
        <p class="credit-card__main-text">Quick donation with your card</p>
        <p class="credit-card__sub-text">Each contribution matters</p>
        <div class="credit-card__btn-wrapper">
            <button
                v-for="(amount, index) in amountOptions"
                :key="index"
                :class="[
                    'btn',
                    { 'active-amount': chosenAmount === amount },
                    { 'btn-hovered-amount': chosenAmount !== amount }
                ]"
                @click="setAmount(amount)"
            >
                {{ amount }}
            </button>
            <button
                v-if="chosenAmount !== 'Other'"
                :class="[
                    'btn payment-btn',
                    { 'active-amount': chosenAmount === 'Other' },
                    { 'btn-hovered-amount': chosenAmount !== 'Other' }
                ]"
                @click="setAmount('Other')"
            >
                Other
            </button>
            <div class="credit-card__other-input" v-else>
                <input
                    ref="otherAmountInput"
                    @input="enterAmount"
                    v-model.trim="otherAmount"
                    placeholder="Amount"
                />
                <p
                    v-if="isOtherAmountEmptyError"
                    class="credit-card__no-amount"
                >
                    Please enter amount you would like to donate
                </p>
                <p
                    v-if="isAmountZeroError"
                    class="credit-card__no-amount"
                >
                    Please enter a valid sum
                </p>
            </div>
        </div>
        <button class="btn credit-card__btn" @click="donate">Donate</button>
    </div>
</template>

<script>
export default {
    name: "ByCreditCard",
    data() {
        return {
            amountOptions: ["€10", "€20", "€50", "€100"],
            chosenAmount: "€10",
            otherAmount: "",
            isOtherAmountEmptyError: false,
            isAmountZeroError: false,
        };
    },
    computed: {
        amount() {
            const isDefaultOption =
                this.chosenAmount !== "Other" &&
                this.otherAmount === "" &&
                this.amountOptions.includes(this.chosenAmount);

            if (isDefaultOption) {
                return +`${this.chosenAmount}00`.replace(/\D/g, "");
            }

            return +`${this.otherAmount}00`
        },
        isOtherAmountEmpty() {
            return this.chosenAmount === "Other" && (this.otherAmount === "" || Number(this.otherAmount) === 0);
        },
    },
    methods: {
        setAmount(amount) {
            this.chosenAmount = amount;

            if (this.chosenAmount === "Other") {
                this.$nextTick(() => {
                    this.$refs.otherAmountInput.focus();
                });
            }

            if (this.otherAmount) {
                this.otherAmount = "";
            }

            if (this.isOtherAmountEmptyError) {
                this.isOtherAmountEmptyError = false;
            }

            if (this.isAmountZeroError) {
                this.isAmountZeroError = false;
            }
        },
        enterAmount(event) {
            const value = +event.target.value;
            const isNum = /^\d+$/;

            if (!isNum.test(value)) {
                this.otherAmount = "";
                return;
            }

            if (this.isOtherAmountEmptyError) {
                this.isOtherAmountEmptyError = false;
            }

            if (this.isAmountZeroError) {
                this.isAmountZeroError = false;
            }
        },
        donate() {
            if (this.isOtherAmountEmpty) {
                this.isOtherAmountEmptyError = true;
                return;
            }

            if (this.otherAmount === "0") {
                this.isAmountZeroError = true;
                return;
            }

            const password = 'qwerty123',
                amount = this.amount,
                currency = 'EUR',
                merchant_id = 1506288,
                order_desc = 'Donate',
                order_id = new Date().getTime();

            const crypto = require('crypto')
            const SHA_GENERATOR = crypto.createHash('sha1')
            SHA_GENERATOR.update(`${password}|${amount}|${currency}|${merchant_id}|${order_desc}|${order_id}`)
            const signature = SHA_GENERATOR.digest('hex')

            const data = {
                options: {
                    methods: ['card', 'wallets'],
                    methods_disabled: ['banklinks_eu', 'local_methods'],
                    card_icons: ['mastercard', 'visa'],
                    title: 'Donate',
                    link: 'https://www.andersencharity.com',
                    button: true,
                    locales: ['cs', 'de', 'en', 'es', 'fr', 'hu', 'it', 'ko', 'lv', 'pl', 'ro', 'ru', 'sk', 'uk'],
                    email: true
                },
                params: {
                    amount,
                    currency,
                    merchant_id,
                    order_desc,
                    order_id,
                    signature,
                    lang: 'en',
                    // required_rectoken: 'y'
                }
            }

            this.$router.push({
                name: 'payment',
                params: { paymentDetails: data }
            })
        }
    },
};
</script>

<style lang="scss">
.credit-card {
    padding: 40px 0 150px 0;

    .btn {
        transition: all ease 0.3s;
    }

    .active-amount {
        background-color: #feda00;
    }

    &__other-input {
        position: relative;
    }

    &__no-amount {
        position: absolute;
        padding: 0;
        margin: 10px 0 0 0;
        color: #ec4646;
        font-size: 10px;
    }

    input {
        width: 150px;
        height: 56px;
        border: 1px solid #feda00;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 10px;
        outline: none;

        &.error {
            border-color: #ec4646;
        }

        &::placeholder {
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.02em;
            color: #9ca8b6;
        }
    }

    &__main-text {
        font-weight: 400;
        font-size: 18px;
    }

    &__sub-text {
        font-weight: 300;
        font-size: 14px;
        margin-bottom: 0;
    }

    &__btn-wrapper {
        display: flex;
        flex-wrap: wrap;
        margin: 40px 0;

        .btn-hovered-amount {
            &:hover {
                background-color: #c7cacb;
            }
        }
    }

    &__btn {
        background-color: #feda00;
        width: 320px;
        height: 52px;

        &:hover {
            background: #F5CE42;
        }
    }

    .btn {
        padding: 16px 40px;
        margin-right: 16px;
    }
}

@media (min-width:490px) and (max-width:710px) {
    .credit-card__btn-wrapper .btn {

        &:nth-child(4),
        &:nth-child(5) {
            margin-top: 15px;
        }
    }
}

@media screen and (max-width: 490px) {
    .credit-card {
        padding-bottom: 100px;
        // TODO Other width 100%

        &__btn-wrapper {
            display: grid;
            grid-template-rows: 1fr 1fr;
            grid-template-columns: 1fr 1fr;
            grid-gap: 15px;
        }

        .btn {
            width: 100%;
        }
    }
}
</style>