<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignChildren",
    props: {
        isText: {
            type: Boolean,
            default: true,
        }
    },
    components: {
        BaseCampaignResult
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Cherkasy Children's Hospital",
                isText: this.isText,
                text: `During this scary wartime, the load of displaced persons and
                    children who tend to be more physiological unstable
                    increased. The hospital faces difficulties full of stress
                    every day and requires support ...`,
                photosAmount: 14,
                cssClass: 'children-hospital',
                path: 'children',
                videoProps: {
                    photosCount: 17,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15, 16 ],
                    },
                    srcFotos: 'childrens-hospital',
                    videoLink: 'https://www.youtube.com/embed/lPZglgd_VyY'
                }
            }
        }
    },
};
</script>
