<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-cherkasy-dispensary :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignCherkasyDispensary from "@/components/CampaignResults/CampaignCherkasyDispensary.vue";

export default {
    name: "AboutCherkasyDispensary",
    components: {
        BaseCampaignPage,
        CampaignCherkasyDispensary
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Cherkasy Regional Dermatology and Venereology Dispensary",
                    mainText: "For Andersen, human life is the core value. That's why we have launched a charitable project to help Ukrainian medical institutions. As the military action continues, doctors are fighting their own battle, saving civilians and wounded soldiers. We strongly believe that help, support, and solidarity are important in these harsh times. Today, we have helped a dispensary in Cherkasy.",
                    subText: "We have delivered consumables necessary for successful clinical workflows. We are grateful to everyone who assists us in our activities. Together, we are helping healthcare professionals and patients and making the world a better place.",
                    amount: "4,779",
                },
                commentType: 'cherkasy-dispensary'
            };
        },
    }
};
</script>