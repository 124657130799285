<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-chernihiv-third :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignChernihivThird from "@/components/CampaignResults/CampaignChernihivThird.vue";

export default {
    name: "AboutChernihivThird",
    components: {
        BaseCampaignPage,
        CampaignChernihivThird
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Chernihiv 3rd City Hospital",
                    mainText: "Andersen Charity is moving towards its goal - a healthy and peaceful world. This time we decided to help the Chernihiv 3rd City Hospital in a difficult struggle with the help of medical supplies used in everyday life and vital for the successful treatment of every patient.",
                    subText: "Andersen Charity appreciates every contribution to a common peaceful future. With the funds raised, we've been able to deliver essentials and even more! Giving happiness is easy. A healthy future depends on our common efforts. Even the most familiar medical supplies can increase the number of recoveries and staff efficiency.",
                    amount: "4,768",
                },
                commentType: 'chernihiv-third'
            };
        },
    }
};
</script>