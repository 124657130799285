var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"credit-card"},[_c('p',{staticClass:"credit-card__main-text"},[_vm._v("Quick donation with your card")]),_c('p',{staticClass:"credit-card__sub-text"},[_vm._v("Each contribution matters")]),_c('div',{staticClass:"credit-card__btn-wrapper"},[_vm._l((_vm.amountOptions),function(amount,index){return _c('button',{key:index,class:[
                'btn',
                { 'active-amount': _vm.chosenAmount === amount },
                { 'btn-hovered-amount': _vm.chosenAmount !== amount }
            ],on:{"click":function($event){return _vm.setAmount(amount)}}},[_vm._v(" "+_vm._s(amount)+" ")])}),(_vm.chosenAmount !== 'Other')?_c('button',{class:[
                'btn payment-btn',
                { 'active-amount': _vm.chosenAmount === 'Other' },
                { 'btn-hovered-amount': _vm.chosenAmount !== 'Other' }
            ],on:{"click":function($event){return _vm.setAmount('Other')}}},[_vm._v(" Other ")]):_c('div',{staticClass:"credit-card__other-input"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.otherAmount),expression:"otherAmount",modifiers:{"trim":true}}],ref:"otherAmountInput",attrs:{"placeholder":"Amount"},domProps:{"value":(_vm.otherAmount)},on:{"input":[function($event){if($event.target.composing)return;_vm.otherAmount=$event.target.value.trim()},_vm.enterAmount],"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.isOtherAmountEmptyError)?_c('p',{staticClass:"credit-card__no-amount"},[_vm._v(" Please enter amount you would like to donate ")]):_vm._e(),(_vm.isAmountZeroError)?_c('p',{staticClass:"credit-card__no-amount"},[_vm._v(" Please enter a valid sum ")]):_vm._e()])],2),_c('button',{staticClass:"btn credit-card__btn",on:{"click":_vm.donate}},[_vm._v("Donate")])])
}
var staticRenderFns = []

export { render, staticRenderFns }