<template>
    <section id="details" class="foundation-details">
        <div class="foundation-details-container">
            <h4 class="foundation-details__title">Foundation details</h4>
            <span
                :class="[
                    'foundation-details__option',
                    { 'active-option': paymentComponent === 'ByCreditCard' },
                ]"
                @click="setPaymentComponent('ByCreditCard')"
                >Pay by credit card</span
            >
            <span
                :class="[
                    'foundation-details__option',
                    { 'active-option': paymentComponent === 'BySwift' },
                ]"
                @click="setPaymentComponent('BySwift')"
                >SWIFT</span
            >
            <!-- <span
                :class="[
                    'foundation-details__option',
                    { 'active-option': paymentComponent === 'OurContacts' },
                ]"
                >Our contacts</span
            > -->
            <component :is="paymentComponent" />
        </div>
    </section>
</template>

<script>
import ByCreditCard from "./ByCreditCard.vue";
import BySwift from "./BySwift.vue";

export default {
    name: "FoundationDetails",
    components: {
        ByCreditCard,
        BySwift
    },
    data() {
        return {
            paymentComponent: "ByCreditCard",
        };
    },
    methods: {
        setPaymentComponent(method) {
            this.paymentComponent = method;
        }
    },
};
</script>

<style lang="scss">
.foundation-details {
    svg {
        position: absolute;
        z-index: 2;
        transform: translateY(-438px);
    }

    &-container {
        max-width: 1134px;
        margin: 0 auto;
    }

    &__title {
        font-weight: 400;
        font-size: 32px;
        margin-top: 80px;
    }

    &__option {
        font-weight: 500;
        font-size: 16px;
        margin-right: 40px;
        padding-bottom: 8px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        text-transform: uppercase;
    }

    .active-option {
        border-bottom: 2px solid #feda00;
    }

    @media screen and (max-width: 1300px) {
        padding-left: 20px;
        padding-right: 20px;
    }
}
</style>