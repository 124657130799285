<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-eight :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignEight from "@/components/CampaignResults/CampaignEight.vue";

export default {
    name: "AboutEight",
    components: {
        BaseCampaignPage,
        CampaignEight
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Lviv Eighth Hospital",
                    mainText: `Andersen Charity is back in Lviv to save more lives. We have set ourselves the goal of improving the rehabilitation process of the clinic and specific treatment methods by providing a set of medical supplies and fixators for treating fractures of tubular bones.`,
                    subText: `We have been pleased to provide the hospital with crucial aid. With the help of our medical supplies, the clinic can improve the patient's stay and further rehabilitation. Particularly intramedullary rods can improve the recovery process, as, during the war, it's the leading fixation method. Hard times for Ukraine, but we will prevail together anyway!`,
                    amount: "5,278",
                },
                commentType: 'eight-comments'
            };
        },
    }
};
</script>