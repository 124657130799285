<script>
export default {
    name: 'MainStyles',
}
</script>

<style lang="scss">
@font-face {
  font-family: 'e-Ukraine';
  font-weight: 100;
  src: url('../fonts/e-Ukraine/e-Ukraine-Thin.otf') format("opentype");
}

@font-face {
  font-family: 'e-Ukraine';
  font-weight: 300;
  src: url('../fonts/e-Ukraine/e-Ukraine-Light.otf') format("opentype");
}

@font-face {
  font-family: 'e-Ukraine';
  font-weight: 400;
  src: url('../fonts/e-Ukraine/e-Ukraine-Regular.otf') format("opentype");
}

@font-face {
  font-family: 'e-Ukraine';
  font-weight: 500;
  src: url('../fonts/e-Ukraine/e-Ukraine-Medium.otf') format("opentype");
}

@font-face {
  font-family: 'e-Ukraine';
  font-weight: 700;
  src: url('../fonts/e-Ukraine/e-Ukraine-Bold.otf') format("opentype");
}

body {
  // background: #F7F8FA;
}

.slider {
    @keyframes show {
        from {
            transform: scale(0);
        } to {
            transform: scale(1);
        }
    }

    &__modal-video {
        background-color: rgba(0,0,0,0.3);
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        display: flex;
        justify-content: center;
        align-items: center;

        iframe {
            border-radius: 16px;
            animation-name: show;
            animation-duration: .5s;
        }

        &__header {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 15px;
            button {
                cursor: pointer;
                width: 48px;
                height: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                border-radius: 50%;
            }
        }
    }
  }

.quote {
  background-image: url('~@/assets/documents-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;
  position: relative;
}

* {
  box-sizing: border-box;
}

.justify-between {
  justify-content: space-between;
}

.feature-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.become-a-donor {
  padding: 50px 0;
  padding-top: 200px;
  background-color: #fff9eb;
  //background-image: url('@/assets/hairs-corner.png');
  //background-repeat: no-repeat;
  //background-position: 0 0;
  //background-size: 1400px;

  &__description {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #647282;
  }

  &__subscription {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    color: #353C45;
    margin-bottom: 40px;
  }

  &__left-column {
    width: 60%;
    padding-right: 50px;

    h2 {
      margin: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 48px;
      letter-spacing: -0.02em;
      color: #353C45;
    }
  }

  &__right-column {
      // display: flex;
    // margin: 50px 0;
    @keyframes renderPlanet {
    //   20% {
    //     top: 0px;
    //   } 40% {
    //     top: 20px;
    //   } 60% {
    //     top: 0px;
    //   } 80% {
    //     top: -20px;
    //   } 100% {
    //     top: 0;
    //   }
    0%, 100% {
        top: 0;
    }
    50% {
        top: 30px;
    }
    }

    @keyframes renderShadow {
    //   20% {
    //     width: 130px;
    //     // height: 35px;
    //   }
    //   40% {
    //     width: 170px;
    //     // height: 50px;
    //   }
    //   60% {
    //     width: 130px;
    //     // height: 45px;
    //   }
    //   80% {
    //     width: 125px;
    //     // height: 30px;
    //   }
    //   100% {
    //     width: 100px;
    //     // height: 20px;
    //   }

      0%, 100% {
        width: 170px;
    }
    25% {
        width: 145px;
    }
    50% {
        width: 120px;
    }
    }

    img {
      width: 600px;
      position: relative;
      top: 0;
    //   animation: 5s linear .5s infinite alternate renderPlanet;
      animation: renderPlanet 5s linear infinite;
    }

    &--shadow {
        margin: 0 auto;
        margin-top: 20px;
        border-radius: 50%;
        width: 150px;
        height: 15px;
        background-color: #cfcfcf;
        transform: translateX(45px);
        // padding-top: 10px;
        // animation: 5s linear .5s infinite alternate renderShadow;
        animation: renderShadow 5s linear infinite;
    }
  }
}

.fade-right {
  position: absolute;
  right: 20px;
  top: -240px;
  z-index: 3;
}

.fade-left {
  position: absolute;
  right: 535px;
  z-index: 3;
  bottom: -15px;
}

.main-section {
  padding: 30px 0;
  min-height: 90vh;
  position: relative;

  &__image {
    position: absolute;
    // left: 0;
    height: 106vh;
    top: 8.5vh;
    right: 150px;
    // margin-right: 70px;
    // left: 0;
    // transform: translateX(-20px);
    // width: 100%;
    // max-width: 1920px;
    // min-width: 1200px;
  }
  //background-position: 0px 130px;

  h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 56px;
    line-height: 86px;
    /* or 154% */

    letter-spacing: -0.02em;

    color: #353C45;
  }

  &__andersen-link#andersen-link {
    background: #FFFFFF;
    border: 1px solid #E8ECEF;
    border-radius: 32px;
    display: flex;
    align-items: center;
    padding: 11px 18px;
    width: fit-content;
    cursor: pointer;
    color: #647282;
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    /* identical to box height, or 183% */

    &:hover {
      background-color: #E8ECEF;
    }

    &:active {
      background-color: #DBDBDB;
    }

    letter-spacing: 0.02em;
    text-transform: uppercase;

    color: #647282;

    img {
      margin-right: 10px;
    }
  }

  &__container {
     max-width: 1134px;
     margin: 0 auto;
     position: relative;
  }
}

.accordion {
  margin-top: 90px;

  @keyframes show {
    from {
      opacity: 0;
    } to {
      opacity: 1;
    }
  }

  &-item {
    border-top: 1px solid #E8ECEF;

    &:nth-last-child(1) {
      border-bottom: 1px solid #E8ECEF;
    }

    &__title-block {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 26px;
      letter-spacing: -0.02em;
      color: #647282;
      padding: 12px 30px;
      cursor: pointer;
      text-decoration: none;

      img {
        transition: .3s;
      }

      &.active {
        img {
          transform: rotate(90deg);
        }
      }
    }

    &__content {
      padding: 10px 30px 20px 30px;
      animation-name: show;
      animation-duration: .4s;
    }
  }
}

.about-us {
  background-color: white;
  padding-top: 150px;
  background-image: url('~@/assets/backgounds/about-us-bottom-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
    background-position-y: 19px;
  background-color: transparent;
  position: relative;
  top: -100px;
  z-index: 6;

  &__container {
  max-width: 1134px;
    margin: 0 auto;
    display: flex;
  }

  &__subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
    margin-top: 100px;
    color: #353C45;
  }

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.02em;
    color: #353C45;
    margin-top: 0;
  }

  &__left-column {
    padding-right: 120px;
  }

  p {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #647282;
  }

  .mobile-layout-btn {
    display: none;
  }
}

body {
  font-family: 'e-Ukraine', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #6D6D6D;

  &::-webkit-scrollbar {
      width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(44, 44, 44);
  }
}

.features {
  background-image: url('~@/assets/backgounds/what-we-do-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 125%;
  min-height: 1110px;
  position: relative;

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.02em;
    color: #353C45;
  }

  .section__container {
    padding: 30px 0;
  }
}

.white-button {
  background-color: #FFFFFF !important;

  &:hover:not(:disabled) {
    background-color: #E0E4E8 !important;
  }

  &:active:not(:disabled) {
    background-color: #DBDBDB !important;
  }
}

.slider__section {
  padding-bottom: 100px;
  position: relative;
  z-index: 1;
  height: 935px;

  background-image: url('~@/assets/features/orange-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 100%;
}

.section {
  background-color: #FFF;
  padding: 120px 33px;

  &--color--gray {
    background-color: #F3F3F3;
  }
}

.nft {
  .section__container {
    display: grid;
    grid-template-columns: repeat(14, [col-start] 1fr);
    grid-gap: 32px;
  }
}

.section__container {
  .feature-list {
    padding-top: 20px;
    display: grid;
    grid-template-columns: repeat(14, [col-start] 1fr);
    grid-gap: 32px;
    grid-auto-rows: 1fr;
  }

  max-width: 1134px;
  margin: 0 auto;
}

.title {
  font-size: 32px;
  line-height: 48px;
  font-weight: 300;
  letter-spacing: -0.02em;
  color: #353C45;
  margin: 16px 0;

  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 40px;
  /* identical to box height, or 143% */

  letter-spacing: -0.02em;

  color: #353C45;

  &--h2 {
    font-size: 40px;
    line-height: 52px;
  }

  &--h4 {
    font-size: 24px;
    line-height: 32px;
  }

  &--color--white {
    color: #FFF;
  }
}

.text {
  font-size: 15px;
  line-height: 28px;
  font-weight: 300;
  letter-spacing: -0.02em;
  color: #647282;
  margin: 16px 0;

  &--color--white {
    color: #C5C5C5;
  }
}

.section__img {
  grid-column: col-start / span 6;
  grid-row: 1/3;

  .sunflowers-img {
     position: relative;
     width: 649px;
     height: 435px;
     border-radius: 16px;
      overflow: hidden;

     div {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 10px;
      line-height: 22px;
      position: absolute;
      left: 24px;
      top: 24px;
      background: #FFDB5D;
      z-index: 1;
      padding: 4px 15px;
      color: #353C45;
      border-radius: 24px;
      letter-spacing: 0.02em;
     }
  }

  img {
    width: 100%;
    height: 100%;
  }
}

.section__description {
  grid-column: col-start 7 / span 6;
  margin: 10px 0 8px;
  padding-left: 130px;
  padding-right: 60px;
  padding-bottom: 10px;

  .title {
    margin-top: 12px;
    font-size: 24px;
    margin-bottom: 10px;

    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: -0.02em;
    color: #3E4956;
  }

  .text {
    margin: 0;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.02em;
    color: #647282;
  }
}

.section__title {
  grid-column: col-start / span 12;
  grid-row: 1;
  margin: 0;
}

.subtitle {
  font-size: 11px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #808080;
  opacity: 0.6;
  margin: 0 0 12px;

  &--type--dot {
    padding-left: 20px;
    position: relative;

    &:before {
      content: '';
      width: 12px;
      height: 12px;
      background-color: #FFDB00;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}

.align-center {
  align-items: center;
}

.jusify-center {
  justify-content: center;
}

.row {
  display: flex;
  width: 100%;
}

.donate {
  padding-top: 87px;
  padding-bottom: 20px;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  position: relative;
  top: -270px;
  height: 800px;

  &-bg {
    position: absolute;
    right: 0;
    bottom: -110vh;
    width: 100%;
  }

  .section__description {
    max-width: 750px;
    margin: 0 auto;
    text-align: center;

    p {
      margin-bottom: 50px;
    }
  }

  .section__container {
    .section__img, .section__description {
      z-index: 1;
    }
  }
}

.donation {
  grid-column: col-start 7 / span 7;
  grid-row: 2;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 0px 52px rgba(154, 168, 196, 0.16);
  z-index: 2;
  margin-top: 40px;
  max-width: 552px;
  min-width: 552px;
  max-height: 485px;
  margin-left: 385px;

  &__link {
    display: block;
    text-align: center;
    padding: 20px 0;
    text-transform: uppercase;
  }

  .title {
    margin-top: 8px;
    margin-bottom: 8px;
  }
}

.donation__info {
  padding: 24px 24px 24px;
  padding-bottom: 0px;

  p {
    margin: 0;
  }

  &--subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 20px;
    /* identical to box height, or 182% */

    letter-spacing: 0.02em;
    text-transform: uppercase;

    color: #9CA8B6;
  }

  &--title {
    font-style: normal;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 40px;
    /* identical to box height, or 182% */

    letter-spacing: -0.02em;

    color: #353C45;
  }

  &--text {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: #647282;
    margin-bottom: 25px !important;
  }
}

.donation__btns {
  margin-top: 34px;
  display: flex;
}

.donation__option, .donation__input {
  background: #E8ECEF;
  border-radius: 56px;
  padding: 16px 0;
  margin-right: 10px;
  min-width: 52px;
  height: 52px;
  box-sizing: border-box;
  border: 0;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
  color: #585858;
  transition: background-color .3s;

  &::placeholder {
    color: #9CA8B6;
    font-size: 14px;
    letter-spacing: -0.03em;
    font-size: 300;
  }

  &:hover {
    background: #d0d0d0;
    cursor: pointer;
  }
}

.donation__option {
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  letter-spacing: -0.02em;

  color: #353C45;
}

.donation__input-box {
  display: inline;
  position: relative;

  .unit {
    position: absolute;
    display: block;
    color: #585858;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.donation__input {
  padding: 16px 10px 16px 30px;
  background: white;
  min-width: 145px;
  border: 1px solid #E8ECEF;
  font-size: 14px;
}

.btn {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border: 0;
  border-radius: 8px;
  padding: 16px 50px;

  &:hover {
    cursor: pointer;
  }
}

.donation__btn {
  color: #242424;
  background: #FFDB5D;
  right: 0;
  min-width: 163px;
  box-sizing: border-box;
  height: 52px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #353C45;
  text-align: center;
  text-decoration: none;

  &:hover {
    background: #F5CE42;
  }

  &:active {
    background: #F1C62E;
  }

  &:disabled {
    background: #F9DC76;
    color: #BBA557;
  }
}

.gray-button {
  background-color: #E9ECF0;

  &:hover {
    background-color: #E0E4E8 !important;
  }

  &:active {
    background-color: #D3D9DE !important;
  }

  &:disabled {
    background-color: #E8ECEF !important;
    color: #A6ADB5;
  }
}

.nft-bg-svg {
  display: none;
}

.link {
  font-size: 15px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: -0.02em;
  color: #808080;
  text-decoration: underline 0.15em;
  text-decoration-color: rgba(128, 128, 128, 0);
  transition: text-decoration-color .3s;

  &:hover {
    text-decoration-color: rgba(128, 128, 128, 1);
  }

  &::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 12px;
    height: 12px;
    margin-bottom: 5px;
    margin-left: 2px;
    background-image: url('~@/assets/link-arrow.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }

  &--color--white {
    color: #fff;
    text-decoration-color: rgba(255, 255, 255, 0);

    &:hover {
      text-decoration-color: rgba(255, 255, 255, 1);
    }

    &::after {
      background-image: url('~@/assets/link-arrow-white.svg');
    }
  }
}

.donation__link {
  font-weight: 500;
  font-size: 11px;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #647282;
  margin-left: 25px;
  margin-right: 25px;
}

.donation__progress {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  overflow: hidden;
}

.progress {
  position: relative;
  display: flex;
  background-color: white;
  border-bottom: 1px solid #E8ECEF;
}

.progress__color {
  position: absolute;
  background: linear-gradient(90deg, #FFD02C 50%, #FFF095 100%);
  width: 10%;
  height: 100%;
}

.main-section__mobile-title {
  display: none;
}

.progress__text {
  padding: 10px 20px;
  padding-top: 15px;
  font-weight: 300;
  z-index: 1;

  &--type--current {
    color: #242424;
  }

  &--type--goal {
    margin-left: auto;
    text-align: right;
    color: #585858;
  }
}
.progress__text-title {
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  text-transform: uppercase;
  color: #647282;
}

.progress__text-number {
  line-height: 60px;
  letter-spacing: -0.02em;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 52px;
  color: #353C45;
}

.articles {
  padding-top: 66px;
  min-height: 500px;

  .section__container {
    padding: 48px 0;
  }

  &-button {
    background-color: #E9ECF0;
    padding: 16px 41px;
    border-radius: 8px;
    cursor: pointer;
    white-space: nowrap;
    border: none;
    transition: .4s;
    margin-top: 40px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #353C45;

    &:hover {
      background-color: #d1d1d1;
    }
  }
}

.articles .section__container>.title {
  text-align: center;
}

.article {
  grid-column: span 6;
  border-radius: 16px;
  background: #fff;
  overflow: hidden;
}

.article__description {
  padding: 16px 32px 32px;
}

.nft {
  padding-top: 0px;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
  background-color: #f7f8fa;
  top: -130px;

  &-lines {
    width: 750px;
    position: absolute;
    right: 5%;
    top: 0;
  }

  .nft-egg {
    position: absolute;
    right: 200px;
    top: 20px;
    width: 400px;
    height: 430px;
    z-index: 2;
    transition: 1s;
  }

  &-bg {
    position: absolute;
    width: 100%;
    top: -565px;
    height: 1075px;
    z-index: 1;
  }
}

@keyframes nftAnimation {
  0% {
    background-position: 95% center, right top;
  } 50% {
    background-position: 98% center, right top;
  } 100% {
    background-position: 95% center, right top;
  }
}

.nft .section__container {
  row-gap: 0;
  border-radius: 16px;
  z-index: 1;
  position: relative;
  padding-right: 20px;

  * {
    z-index: 1;
  }
}

.nft__title {
  grid-row: 1;
  grid-column: span 7;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: -0.02em;
}

.nft__text {
  grid-row: 2;
  grid-column: span 7;
  margin: 24px 0 40px;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 26px;
  letter-spacing: -0.02em;
  color: #C5CCD4;
}

.nft__counter {
  grid-row: 3;
  grid-column: span 3;
  margin-bottom: 64px;
}

.nft__counter-number {
  font-weight: 400;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 100;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
}

.nft__counter-text {
  font-weight: 400;
  letter-spacing: -0.02em;
  font-style: normal;
  font-weight: 300;
  font-size: 13px;
  line-height: 25px;
  color: #C5CCD4;
}

.nft__btn, .nft__link {
  grid-row: 4;
  grid-column: span 3;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #FFFFFF;
}

.nft__btn {
  background-color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */

  text-align: center;

  color: #353C45;

  &:hover {
    background-color: #EEEEEE;
  }

  &:active {
    background-color: #DBDBDB;
  }
}

.nft__link {
  margin: auto 0;
  font-weight: 500;
  font-size: 11px;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-style: normal;
  color: #fff;
  text-decoration: none;

  &:hover {
    color: #ECBC15;

    &::after {
      background-image: url('~@/assets/link-arrow-yellow.svg');
    }
  }
}

.link-button {
  &:hover {
    color: #ECBC15 !important;
  }

  &:active {
    color: #D8A907 !important;
  }

  &:disabled {
    color: #9CA8B6 !important;
  }
}

.become-a-donor-section--mobile {
  display: none;
}

.nft__counters {
  display: none;
}

@media screen and (max-width: 1500px) {
  .main-section__image {
    right: 20px;
  }
}

@media screen and (max-width: 1320px) {
  // .fade-left {
  //   bottom: -120px;
  // }

  .main-section__image {
    height: 95vh;
  }

  .main-section {
    min-height: 81.5vh;
  }
}

@media screen and (max-width: 1250px) {
  .donation {
    margin-left: 0;
  }
}

@media screen and (max-width: 1200px) {
  .nft-lines {
    width: 600px;
  }

  .nft .nft-egg {
    right: 140px;
    width: 340px;
    height: 370px;
    z-index: 3;
  }

  .fade-right {
    top: -100px;
  }

  .fade-left {
    right: 370px;
  }
}

@media screen and (max-width: 1180px) {
  .nft .section__container {
    top: 85px;
  }

  .nft-lines {
    top: 5% !important;
  }

  .fade-left, .fade-right {
    display: none;
  }

.slider__section {
  background-position-y: 85%;
  height: 1000px;
}

  .about-us__container {
        padding: 0 20px;
  }

  #nft .nft-bg {
    top: -345px;
  }

  .section__slider .slider-button {
    margin: 0 auto;
  }

  .section__slider .section__container {
      padding-left: 0 !important;
      width: 70%;

      .slider__row {
        text-align: center;
      }

      .slider__text {
        width: 100% !important;
      }
  }

  .slider__row {
    flex-direction: column !important;

    .slider__start-block {
      order: 2;
      margin-right: 0;
      margin-top: 30px;
      width: 100%;
    }

    .slider__block {
      order: 1;
    }
  }

  .section__container {
    padding: 0 90px;
  }

  .become-a-donor-section {
    .become-a-donor__right-column {
      img {
        width: 400px;
      }

      &--shadow {
        transform: translateX(31px);
    }
    }
  }

  .main-section__container {
    padding-left: 20px;

    h1 {
      font-size: 45px;
    }
  }

  .main-section__image {
    height: 90vh;
    right: 20px;
  }

  .main-section {
    min-height: 77.5vh;
  }
}

@media screen and (max-width: 1100px) {
  .features {
    background-position-y: 155%;
  }

  .main-section__image {
    height: 84vh;
  }

  .main-section {
    min-height: 73vh;
  }
}

@media screen and (max-width: 1080px) {
  .nft-lines {
    width: 480px;
    top: 25% !important;
  }

  .nft .nft-egg {
    width: 290px;
    height: 320px;
  }
}

@media screen and (max-width: 1050px) {
  .about-us__container {
    flex-direction: column;

    .about-us__left-column {
      order: 2;
    }

    .about-us__right-column {
      order: 1;
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      img.about-us--image {
        width: 50% !important;
      }

      .accordion {
        margin-top: 0;
        margin-left: 20px;

        .accordion-item {
          img {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .header .header__container {
    .header-links {
      li {
        padding: 0 10px;
      }
    }
  }

  .main-section__image {
    height: 75vh;
  }

  .main-section {
    min-height: 66vh;
  }
}

@media screen and (max-width: 975px) {
  .features .section__container {
    padding-bottom: 100px;
  }

  #nft {
    height: 930px;
  }

  #nft .nft-bg {
    height: 1270px;
    top: -250px;
  }

  .nft .section__container {
      top: 490px;
      grid-template-columns: repeat(8, [col-start] 1fr);
  }

  .quote {
    margin-top: -140px !important;
  }

  .nft-lines {
    width: 530px;
    top: 11% !important;
    left: calc(50% - 275px);
  }

  .nft .nft-egg {
    top: 130px !important;
    right: calc(50% - 170px);
  }
}

@media screen and (max-width: 950px) {
  .section__slider {
    .section__container {
      width: 90%;
    }
  }

  .donation {
    grid-column: col-start 2/span 7;
    min-width: 580px;
  }

  .features .section__container {
    padding-bottom: 100px;
  }

  .main-section__image {
    height: 70vh;
  }

  .main-section {
    min-height: 62vh;
  }
}

@media screen and (max-width: 870px) {
  .section__img .sunflowers-img {
    width: 610px;
    height: 420px;
  }

  .donation {
    min-width: 545px;
  }

  .become-a-donor {
    padding-top: 80px;

    .section__container {
      flex-direction: column;

      .become-a-donor__left-column {
        order: 2;
        width: 100%;
        padding-right: 0;
      }

      .become-a-donor__right-column {
        order: 1;
      }
    }
  }

  .become-a-donor-section {
    .become-a-donor__right-column {
      img {
        width: 400px;
      }
    }
  }

  .main-section__container {
    padding-left: 10px;
    h1 {
      font-size: 40px;
      line-height: 65px;
    }
  }

  .main-section__image {
    height: 65vh;
  }

  .main-section {
    min-height: 58.5vh;
  }
}

@media screen and (max-width: 830px) {
  .section__img .sunflowers-img {
      width: 560px;
      height: 370px;
  }

  .donation {
      min-width: 495px;
  }
}

@media screen and (max-width: 800px) {
  .main-section__image {
    height: 60vh;
  }

  .main-section {
    min-height: 54.5vh;
  }
}

@media screen and (max-width: 780px) {
  .about-us__container .about-us__right-column {
    display: block;
  }

  .about-us__left-column {
    padding-right: 0;
  }

  .about-us__container .about-us__right-column img.about-us--image {
    width: 100% !important;
  }

  .accordion {
    margin-left: 0 !important;
    margin-top: 40px;
  }

  .section__img .sunflowers-img {
    width: 500px;
    height: 310px;
  }

  .donation {
      min-width: 435px;
  }
}

@media screen and (max-width: 730px) {
  #nft {
    height: 960px;
  }

  .nft-bg {
    height: 1310px;
  }

  .main-section__image {
    height: 55vh;
  }

  .main-section {
    min-height: 51vh;
  }
}

@media screen and (max-width: 710px) {
  .section__img {
    grid-column: col-start/span 8;
    grid-row: 1/3;
  }

  .section__description {
    padding-left: 75px;
  }

  .section__img .sunflowers-img {
    width: 450px;
    height: 280px;
  }

  .donation {
      min-width: 380px;
  }

  .donate {
    height: 870px;
  }
}

@media screen and (max-width: 640px) {
  .section__quote__author {
        bottom: 20% !important;
  }

  .section__description {
    padding-left: 75px;
  }

  .slider__section {
    height: 1100px;
  }

  .section__img {
    grid-column: col-start/span 8;
  }

  .section__img .sunflowers-img {
    width: 405px;
    height: 270px;
  }

  .donate .section__container {
    grid-template-columns: repeat(8, [col-start] 1fr);
    padding: 0;
  }

  .donation {
    min-width: 495px;
    margin-top: 95px;
    margin-right: 25px;
  }

  .sunflowers-img {
    margin: 0 auto;
  }
}

@media screen and (max-width: 670px) {
  .main-section__container h1 {
    font-size: 32px;
    line-height: 55px;
  }

  .main-section__image {
    height: 50vh;
  }

  .main-section {
    min-height: 47vh;
  }

  .main-section__andersen-link#andersen-link {
    font-size: 10px;

    img {
      width: 28px;
    }
  }
}

@media screen and (max-width: 610px) {
  .section__img .sunflowers-img {
    width: 385px;
    height: 260px;
  }

  .donation {
    min-width: 450px;
    margin-top: 95px;
    margin-right: 47px;
  }

  .sunflowers-img {
    margin: 0 auto;
  }
}

@media screen and (max-width: 570px) {
  #current-campaign {
    padding-left: 0;
    padding-right: 0;
  }

  .section__img .sunflowers-img {
    width: 100%;
    height: 380px;
  }

  .main-section__image {
    height: 45vh;
  }

  .main-section {
    min-height: 42.5vh;
  }
}

@media screen and (max-width: 570px) {
  .donation {
    min-width: 410px;
    margin-top: 95px;
    margin-right: 65px;
  }

  .donate {
    height: 910px;
  }
}

@media screen and (max-width: 510px) {
  .nft-lines {
    width: 100%;
    top: 9% !important;
    left: auto;
  }

  .nft .section__container {
    padding-left: 30px;
  }
}

@media screen and (max-width: 510px) {
  .donation {
    min-width: 380px;
  }

  #nft {
    height: 1040px;
  }

  .nft-bg {
    height: 1390px;
  }
}

@media screen and (max-width: 480px) {
  .quote {
    margin-top: -210px !important;
  }

  .section__quote__author {
        bottom: 14% !important;
        right: 10%;
  }

  .donation {
    min-width: 350px;
    margin-right: 50px;
  }

  .donate {
    height: 990px;
  }

  .section__img .sunflowers-img {
    width: 100%;
    height: 330px;
  }
}

@media screen and (max-width: 430px) {
   .quote {
    margin-top: -205px !important;
  }
  // #nft .nft-bg {
  //   height: 1290px;
  //   top: -240px;
  // }
  // .nft-lines {
  //   top: 14% !important;
  // }
  // #nft {
  //   height: 1070px;
  // }
  .donation {
    min-width: 310px;
    margin-right: 50px;
  }

  .donate {
    height: 990px;
  }

  .section__img .sunflowers-img {
    width: 100% !important;
    border-radius: 0;
    height: 300px;
  }
}

@media screen and (max-width: 490px) {
  .main-section__container h1 {
    font-size: 26px;
    line-height: 46px;
  }

  .main-section__image {
    height: 40vh;
    top: 8.1vh;
    right: 0;
  }

  .main-section {
    padding-top: 0 !important;
    min-height: 39vh;
  }

  .main-section__container {
    display: none;
  }

  .main-section__mobile-title {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #FFF9EB;
    margin-bottom: 20px;
    padding-bottom: 40px;
    padding-top: 20px;

    h1 {
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 50px;
    /* or 156% */

    text-align: center;
    letter-spacing: -0.02em;

    color: #353C45;
  }

  .main-section__andersen-link#andersen-link {
    background: #FFFFFF;
    border: 1px solid #E8ECEF;
    border-radius: 32px;
    display: flex;
    align-items: center;
    padding: 10px;
    width: fit-content;
    cursor: pointer;
    color: #647282;
    text-decoration: none;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    /* identical to box height, or 183% */

    letter-spacing: 0.02em;
    text-transform: uppercase;

    color: #647282;

    img {
      margin-right: 10px;
      width: 24px;
    }
  }
  }

  .become-a-donor__right-column--shadow {
    transform: translateX(28px);
  }

  .become-a-donor-section {
    display: none !important;
  }

  .become-a-donor {
    padding-top: 50px;
    padding-bottom: 10px;
  }

  .become-a-donor-section--mobile {
    padding: 20px;
    display: block;

    img {
      width: 100%;
      margin: 0 auto;
      width: 100%;
      margin-bottom: 10px;
    }

    h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 40px;
      /* identical to box height, or 167% */

      letter-spacing: -0.02em;

      color: #3E4956;
    }

    .become-a-donor-section--mobile-description {
      font-style: normal;
      font-weight: 300;
      font-size: 14px;
      line-height: 26px;
      /* or 186% */

      letter-spacing: -0.02em;

      color: #647282;
    }

    .become-a-donor-section--mobile-subscription {
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 28px;
      /* or 175% */

      color: #353C45;
    }

    button {
      margin-top: 40px;
    }
  }

  #become-a-donor {
    // margin-top: ;
    .section__container {
      padding: 0 30px;
    }

    .become-a-donor__left-column {
      margin-top: 30px;
      h2 {
        font-size: 24px !important;
      }
    }

    .become-a-donor__right-column {
      transform: translateX(-20px);
    }

    // .bnt .donation-btn {
    //   width: 100% !important;
    // }
  }

  #what-we-do {
    .section__title {
      font-size: 24px;
    }

    .feature-list {
      display: flex;
    }
  }

  .normal-layout-btn { display: none; }
  .mobile-layout-btn {
    margin-top: 30px;
    display: block !important;
  }

   .section__quote {
    justify-content: flex-start !important;
    min-height: 580px !important;

    &__handlebar__text {
      font-size: 16px;
      color: #677585;
      // text-align: center;
    }
    &__author {
      right: 0 !important;
      bottom: 20% !important;
      // left: 50%;
      // transform: translate(-50%, 0) !important;
      width: 100%;
      text-align: center;
      color: #9CA8B6;
    }
  }

  .nft-lines {
    top: 17% !important;
  }

  .nft .nft-egg {
    top: 180px !important;
  }

  .nft .section__container {
    top: 480px;

    // .nft__btn {
    //   // grid-column: span 8;
    // }
    .link--color--white {
      width: 111px;
    }

  }

  .nft__title {
    font-size: 24px;
  }

  .campaigns-results {
    &__head-title {
      padding-left: 25px;
      text-align: left;
      font-size: 24px !important;
    }

    &__choose-campaign {
        padding: 0 7px;
    }

    &__container {
      padding: 0 25px;
      .media-block__video,
      .media-block__video-maternity {
        width: 365px;
      }
    }
  }
}

@media screen and (max-width: 376px) {
  // .nft .section__container {
  //   top: 85px;
  // }

  // .nft .nft-egg {
  //   top: 170px !important;
  // }

  // #nft {
  //   height: 1110px;
  // }

  // .nft-bg-svg {
  //   height: 1180px !important;
  // }

  .slider__section {
    height: 830px;
  }

  .features .section__container {
    padding: 30px 0px;
  }

  .section__container {
    padding: 0 0px;
  }

  .main-section__image {
      position: absolute;
      // left: -160px;
      height: 45vh;
      top: 8.3vh;
      // right: 0-;
      // width: 143%;
  }

  .main-section {
    min-height: 43vh;
  }

  .main-section__container {
    display: none;
  }

  // .main-section__mobile-title {
  //   display: flex;
  //   justify-content: center;
  //   flex-direction: column;
  //   align-items: center;
  //   text-align: center;
  //   background-color: #FFF9EB;
  //   margin-bottom: 20px;
  //   padding-bottom: 40px;
  //   padding-top: 20px;

  //   h1 {
  //   font-style: normal;
  //   font-weight: 300;
  //   font-size: 32px;
  //   line-height: 50px;
  //   /* or 156% */

  //   text-align: center;
  //   letter-spacing: -0.02em;

  //   color: #353C45;
  // }

  // .main-section__andersen-link#andersen-link {
  //   background: #FFFFFF;
  //   border: 1px solid #E8ECEF;
  //   border-radius: 32px;
  //   display: flex;
  //   align-items: center;
  //   padding: 10px;
  //   width: fit-content;
  //   cursor: pointer;
  //   color: #647282;
  //   text-decoration: none;
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 12px;
  //   line-height: 22px;
  //   /* identical to box height, or 183% */

  //   letter-spacing: 0.02em;
  //   text-transform: uppercase;

  //   color: #647282;

  //   img {
  //     margin-right: 10px;
  //     width: 24px;
  //   }
  // }
  // }

  // .become-a-donor__right-column--shadow {
  //   transform: translateX(28px);
  // }

  // .become-a-donor-section {
  //   display: none !important;
  // }

  // .become-a-donor {
  //   padding-top: 50px;
  //   padding-bottom: 10px;
  // }

  // .become-a-donor-section--mobile {
  //   padding: 20px;
  //   display: block;

  //   img {
  //     width: 100%;
  //     margin: 0 auto;
  //     width: 100%;
  //     margin-bottom: 10px;
  //   }

  //   h2 {
  //     font-style: normal;
  //     font-weight: 400;
  //     font-size: 24px;
  //     line-height: 40px;
  //     /* identical to box height, or 167% */

  //     letter-spacing: -0.02em;

  //     color: #3E4956;
  //   }

  //   .become-a-donor-section--mobile-description {
  //     font-style: normal;
  //     font-weight: 300;
  //     font-size: 14px;
  //     line-height: 26px;
  //     /* or 186% */

  //     letter-spacing: -0.02em;

  //     color: #647282;
  //   }

  //   .become-a-donor-section--mobile-subscription {
  //     font-style: normal;
  //     font-weight: 300;
  //     font-size: 16px;
  //     line-height: 28px;
  //     /* or 175% */

  //     color: #353C45;
  //   }

  //   button {
  //     margin-top: 40px;
  //   }
  // }

  .features {
    padding: 15px !important;

    h2 {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 40px;
      /* identical to box height, or 167% */

      letter-spacing: -0.02em;

      color: #3E4956;
    }

    .feature {
      margin-top: 0;
    }
  }

  .section__container {
    display: block;
  }

  .donate {
    top: 0;
  }

  .donate {
    height: 1080px;
    .section__description {
      text-align: left;
      padding: 0;
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  #current-campaign {
    padding-left: 0;
    padding-right: 0;
  }

  .section__img .sunflowers-img {
    width: 100% !important;
    border-radius: 0;
    height: 270px;
  }

  .slider__section {
    top: 0;
    padding-bottom: 0;
  }

  .donation {
    min-width: calc(100% - 30px);
    max-width: 100%;
    width: calc(100% - 30px);
    box-shadow: 0px 0px 52px rgba(154, 168, 196, 0.16);
    margin: -40px auto;
    max-height: 800px;
  }

  .section__slider .section__container {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  // .nft-bg, .nft {
  //   top: -45px !important;
  // }

  // .nft-bg {
  //   display: none;
  // }

  // .nft-bg-svg {
  //   display: block;
  //   height: 1000px;
  //   width: 100%;
  // }

  // .nft-lines {
  //   position: relative;
  //   top: 25px;
  //   right: 0;
  //   width: 100%;
  //   margin-top: 10px;
  // }

  // .nft .nft-egg {
  //   position: absolute;
  //   right: calc(50% - 150px);
  //   top: 20px;
  //   width: 300px;
  //   height: auto;
  //   z-index: 2;
  //   transition: 1s;
  // }

  .slider__text {
    width: 100% !important;
  }

  .slider__start-block {
    min-width: 100% !important;
  }

  // .nft__title {
  //   font-style: normal;
  //   font-weight: 400;
  //   font-size: 24px;
  //   line-height: 40px;
  //   /* identical to box height, or 167% */

  //   letter-spacing: -0.02em;

  //   color: #FFFFFF;
  // }

  // #nft .section__container {
  //   padding: 10px;
  // }

  // .nft__text {
  //   font-style: normal;
  //   font-weight: 300;
  //   font-size: 14px;
  //   line-height: 26px;
  //   /* or 186% */

  //   letter-spacing: -0.02em;

  //   color: #C5CCD4;
  // }

  // .nft__counter {
  //   display: none;
  // }

  // .nft__btn {
  //   width: 100% !important;
  // }

  // .nft__link {
  //   width: 100% !important;
  //   padding: 20px;
  //   display: block;
  //   text-align: center;
  // }

  // .nft__counters {
  //   display: flex;

  //   .nft__counter {
  //     display: block;
  //     width: 50%;
  //     margin-bottom: 30px;
  //   }
  // }

  .section__quote {
    min-height: 580px !important;

    &__author {
      right: 0 !important;
      bottom: 18% !important;
    }
  }
  .campaigns-results__container .text-block {
    margin-top: 20px;
  }
}

@media screen and (min-width: 2020px) {
  .main-section {
      &__image {
      right: 0;
      left: 0;
    }
  }

  // .footer__container,
  // .about-us__container,
  // .section__container,
  // .campaigns-results__container,
  // .main-section__container,
  // .header__container {
  //   // max-width: 2000px !important;
  //   // padding: 0 100px;
  // }
  // .features .section__container {
  //   padding: 30px 100px;
  // }
}

@media screen and (min-width: 2300px) {
  .main-section {
    min-height: 108vh
  }

  .main-section__image {
    height: 129vh;
    top: 8.8vh;
  }
}

@media screen and (min-width: 2450px) {
  // .main-section__container {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: end;

  //   #andersen-link {
  //     margin-right: 190px;
  //   }
  // }

  .aboutus-page {
    max-width: 2450px;
    &__container {
      width: 2450px;
      padding: 0 100px;
      max-width: unset;
    }
  }

  .footer__transfer-info--requisites-first .w-116 {
    width: 100% !important;
  }
}

  @media (max-height: 950px) and (min-width: 1900px) {
      .main-section {
        &__image {
          right: 275px;
        }
      }
  }

@media (max-height: 880px) and (min-width: 1900px) {
    .main-section {
      &__image {
        right: 300px;
      }
    }
}

@media (max-height: 870px) and (min-width: 1900px) {
    .main-section {
      &__image {
        right: 332px;
      }
    }
}

@media (max-height: 850px) and (min-width: 1900px) {
    .main-section {
      &__image {
        right: 400px;
      }
    }
}
</style>
