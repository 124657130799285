<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignFamily",
    components: {
        BaseCampaignResult,
    },
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Chernihiv Family Hospital",
                isText: this.isText,
                text: `Besides children, the family clinic provides care for adults too. That is the only hospital where one-day surgery is available. Hospitals' efficiency suffers not only because of the war but also because of seasonal diseases that make the situation harder. It requires our support to improve the recovery process ...`,
                photosAmount: 13,
                cssClass: 'family',
                path: 'family',
                videoProps: {
                    photosCount: 16,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15 ],
                    },
                    srcFotos: 'family-hospital',
                    videoLink: 'https://www.youtube.com/embed/rq6j3mwMEzc'
                }
            }
        }
    }
};
</script>
