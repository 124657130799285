<template>
    <base-campaign-result v-bind="resultProps" />
</template>

<script>
import BaseCampaignResult from '@/components/Base/BaseCampaignResult.vue'

export default {
    name: "CampaignCity",
    components: {
        BaseCampaignResult,
    },
    props: {
        isText: {
            type: Boolean,
            default: () => true,
        },
    },
    computed: {
        resultProps() {
            return {
                title: "Charity for Cherkasy Second City Hospital",
                isText: this.isText,
                text: `The hospital has always been helping people from every corner of Ukraine to feel life again after long-term treatment.
                    When it comes to ordering new equipment, the clinic faces financial problems. Care is vital, but the recovery process is not less crucial ...`,
                photosAmount: 14,
                cssClass: 'city',
                path: 'city',
                videoProps: {
                    photosCount: 17,
                    fotos: {
                        firstFotos: [ 0, 1, 2, 3, 4, 5, 6 ],
                        secondFotos: [ 7, 8, 9, 10, 11, 12, 13 ],
                        thirdFotos: [ 14, 15, 16 ],
                    },
                    srcFotos: 'city-hospital',
                    videoLink: 'https://www.youtube.com/embed/AZbB0eiTkkQ'
                }
            }
        }
    }
};
</script>
