<template>
    <div
        class="slider__photos-img-wrapper"
        :style="`background-image: url('${getImage(foto)}')`"
        @click="$emit('setActiveFoto', foto)"
    >
    <slot />
    </div>
</template>

<script>
export default {
    name: 'ActiveFoto',
    props: {
        foto: {
            type: Number,
            required: true
        },
        type: {
            type: String,
            required: true
        }
    },
    methods: {
        getImage(num) {
            return require(`@/assets/${this.type}/pic_${num}.jpg`)
        }
    }
};
</script>

<style>
</style>