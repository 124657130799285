<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-maternity :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignMaternity from "@/components/CampaignResults/CampaignMaternity";

export default {
    name: "AboutMaternity",
    components: {
        BaseCampaignPage,
        CampaignMaternity
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Cherkasy city maternity hospital",
                    mainText: `
                        AnAndersen Charity focused on improving childbirth quality.
                        We've put a goal of helping the hospital to provide more lives
                        and give the necessary care to newborns with an extraordinary
                        array of resources.`,
                    subText: `Andersen Charity was happy to deliver a crucial set of medical supplies to the hospital.
                        With the help of this array of resources, women of any age and newborns can
                        get aid. Сollaboratively we helped to improve pregnancy management,
                        childbirth assistance, and follow‐up care.
                        The hospital got day-to-day supplies and minimized its needs.`,
                    amount: "4,300",
                },
                commentType: 'maternity-comments'
            };
        },
    }
};
</script>