<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-prince-leo :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignPrinceLeo from "@/components/CampaignResults/CampaignPrinceLeo.vue";

export default {
    name: "AboutPrinceLeo",
    components: {
        BaseCampaignPage,
        CampaignPrinceLeo
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Lviv clinic of Prince Leo",
                    mainText: "By providing constant medical assistance to the country's defenders and the civilian population, employees show resilience, national dignity, and responsibility for saving human life. Andersen Charity has focused on improving the hospital's needs with material support to reduce staff overload.",
                    subText: 'Andersen Charity is happy to support the clinic with urgent medical supplies and an operating table. We hope that such small but no less valuable help can increase the recovery rate and give support in performing complex surgical operations. The hospital feels more confident with everything they need to help others.',
                    amount: "5,017",
                },
                commentType: 'prince-leo-comments'
            };
        },
    }
};
</script>