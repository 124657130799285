<template>
    <base-campaign-page v-bind="campaignPageProps">
        <template v-slot:campaign>
            <campaign-infectious :isText="false"/>
        </template>
    </base-campaign-page>
</template>

<script>
import BaseCampaignPage from "../components/Base/BaseCampaignPage.vue";
import CampaignInfectious from "@/components/CampaignResults/CampaignInfectious.vue";

export default {
    name: "AboutInfectious",
    components: {
        BaseCampaignPage,
        CampaignInfectious
    },
    computed: {
        campaignPageProps() {
            return {
                text: {
                    title: "Charity for Cherkasy Infectious Diseases Hospital",
                    mainText: `The Andersen Charity has set itself the goal of meeting the current needs of the hospital.
                        We focused on helping to cope with the shortage of supplies due to the sharp increase in the number of patients.`,
                    subText: `Together we could deliver as much as an entire minibus with crucial medical supplies.
                        The hospital received supplies, monitors and pulse oximeters necessary to provide high-quality medical care.
                        Thus, we have satisfied the current needs of the medical staff.`,
                    amount: "5,099",
                },
                commentType: 'infectious-hospital'
            };
        },
    }
};
</script>