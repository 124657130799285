<template>
    <base-modal
        :isOpen="isOpen"
        @closeModal="handleClose"
    >
        <div class="payment-modal">
            <by-credit-card />
        </div>
    </base-modal>
</template>

<script>
import ByCreditCard from '../components/FoundationDetails/ByCreditCard.vue'
import BaseModal from '../components/Base/BaseModal.vue'

export default {
    name: 'PaymentModal',
    components: {
        BaseModal,
        ByCreditCard
    },
    props: {
        isOpen: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        handleClose() {
            this.$emit('closeModal')
        }
    }
}
</script>

<style lang="scss">
.payment-modal {
    .credit-card {
        padding-bottom: 80px;

        .btn {
            padding: 14px 30px;
        }

        &__btn-wrapper {
            justify-content: space-between;
            gap: 20px;

            .btn {
                margin-right: 0;
            }
        }

        &__btn {
            width: 100%;
            margin-right: 0;
        }
    }
}

@media only screen and (orientation: landscape) {
    .payment-modal .credit-card {
        padding-bottom: 25px;
    }
    .credit-card {
        padding-top: 10px;
    }
}
</style>
