var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nft-page"},[_c('div',{staticClass:"nft-page__container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"nft-page__left-column"},[_c('div',{staticClass:"nft-page__back-link",on:{"click":_vm.goBack}},[_vm._m(1)]),_c('h1',{staticClass:"nft-page__title"},[_vm._v("Traditional NFT art")]),_c('p',{staticClass:"nft-page__description"},[_vm._v(" With this NFT, you will accomplish two things simultaneously. First, you will help save the lives of Ukrainians. All the proceeds will go to the Andersen Charity foundation, which is supplying equipment to healthcare providers operating in Ukraine; we will deliver it to where it is needed most. Second, you will obtain a piece of unique Ukrainian culture. It will remind you that there aren’t only horrible things in this world, there are also beautiful ones! ")]),_c('div',{staticClass:"nft-page__options nft-desktop"},_vm._l((_vm.options),function(option,index){return _c('button',{key:index,class:{ active: option.id === _vm.activeOptionId },on:{"click":function($event){return _vm.handleChooseOption(option.id)}}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0),_c('hr'),_c('h2',{staticClass:"nft-page__subtitle nft-desktop"},[_vm._v(" "+_vm._s(_vm.getActiveOption().subtitle)+" ")]),_c('p',{staticClass:"nft-page__description nft-desktop"},[_vm._v(" "+_vm._s(_vm.getActiveOption().text)+" ")])]),_c('div',{staticClass:"nft-page__right-column"},[_c('div',{staticClass:"nft-page__nft-bg",on:{"mousemove":_vm.handleMouseMove}},[(_vm.getActiveOption())?_c('img',{style:({
                            top: `calc(50% - ${200 + _vm.offsetY / 30}px)`,
                            left: `calc(50% - ${200 + _vm.offsetX / 30}px)`,
                        }),attrs:{"src":_vm.getActiveOption().img}}):_vm._e()]),_c('div',{staticClass:"nft-page__options nft-mobile"},_vm._l((_vm.options),function(option){return _c('button',{key:option.id,class:{ active: option.id === _vm.activeOptionId },on:{"click":function($event){return _vm.handleChooseOption(option.id)}}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0),_c('h2',{staticClass:"nft-page__subtitle nft-mobile"},[_vm._v(" "+_vm._s(_vm.getActiveOption().subtitle)+" ")]),_c('p',{staticClass:"nft-page__description nft-mobile"},[_vm._v(" "+_vm._s(_vm.getActiveOption().text)+" ")]),(false)?_c('div',{staticClass:"nft-page__socials nft-mobile"},[_c('p',{staticClass:"nft-page__socials--title"},[_vm._v("Share")]),_c('div',{staticClass:"nft-page__socials--list"},[_c('base-socials')],1)]):_vm._e()])])]),_c('footer',{staticClass:"nft-page__footer"},[_c('div',{staticClass:"nft-page__footer--container"},[(false)?_c('div',{staticClass:"nft-page__socials nft-desktop"},[_c('p',{staticClass:"nft-page__socials--title"},[_vm._v("Share")]),_c('div',{staticClass:"nft-page__socials--list"},[_c('base-socials')],1)]):_vm._e(),_vm._m(2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nft-go-back"},[_c('a',{staticClass:"breadcrumbs-link",attrs:{"href":"/"}},[_c('img',{attrs:{"src":require("../assets/icons/left-arrow-link.png")}}),_vm._v(" Go Back")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"breadcrumbs-link"},[_c('img',{attrs:{"src":require("../assets/icons/left-arrow-link.png")}}),_vm._v(" Go Back")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nft-page__footer--action"},[_c('img',{attrs:{"src":require("../assets/opensea-logo.png")}}),_c('a',{staticClass:"btn donation__btn",attrs:{"href":"https://opensea.io/SunCharitybyAndersen","target":"_blank"}},[_vm._v("Go to NFT market")])])
}]

export { render, staticRenderFns }