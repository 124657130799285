<template>
    <section id="results" class="campaigns-results">
        <h2 class="campaigns-results__head-title">Campaigns results</h2>
        <div class="campaigns-results__choose-campaign">
            <button
                v-for="result in resultsMain"
                :key="result.component"
                :class="[{ 'active-campaign': campaignComponent === result.component },
                    'campaigns-results__choose-campaign-btn']"
                @click="setCampaign(result.component)"
            >
                {{ result.title }}
            </button>
            <button
                class="campaigns-results__choose-campaign-btn"
                @click="showDropDown = !showDropDown"
                v-click-outside="closeDropDown"
            >More Projects
                <img src="../../assets/arrow-down.png">

                <div v-if="showDropDown" class="campaigns-results__drop-list" >
                    <div class="drop-list-wrapper">
                        <button
                            v-for="result in resultsSecondary"
                            :key="result.component"
                            :class="[{ 'active-campaign-list': campaignComponent === result.component },
                                { 'btn-hovered-list': campaignComponent !== result.component },
                                'campaigns-results__choose-campaign-btn']"
                            @click="setCampaign(result.component)"
                        >
                            {{ result.title }}
                        </button>
                    </div>
                </div>
            </button>
        </div>
        <component :is="campaignComponent" />
    </section>
</template>

<script>
import CampaignChildrenClinic from './CampaignChildrenClinic.vue'
import CampaignOnco from './CampaignOnco.vue'
import CampaignMaternity from './CampaignMaternity.vue'
import CampaignMaternityLviv from './CampaignMaternityLviv.vue'
import CampaignChildren from './CampaignChildren.vue'
import CampaignCity from './CampaignCity.vue'
import CampaignInfectious from './CampaignInfectious.vue'
import CampaignMaternityChernihiv from './CampaignMaternityChernihiv.vue'
import CampaignChildrenRegional from './CampaignChildrenRegional.vue'
import CampaignFamily from './CampaignFamily.vue'
import CampaignRailway from './CampaignRailway.vue'
import CampaignChildrenCenter from './CampaignChildrenCenter.vue'
import CampaignFourthLviv from './CampaignFourthLviv.vue'
import CampaignSixthLviv from './CampaignSixthLviv.vue'
import CampaignFirstCherkasy from './CampaignFirstCherkasy.vue'
import CampaignEight from './CampaignEight.vue'
import CampaignChernihiv from './CampaignChernihiv.vue'
import CampaignSecond from './CampaignSecond.vue'
import CampaignChernihivThird from './CampaignChernihivThird.vue'
import CampaignPrinceLeo from './CampaignPrinceLeo.vue'
import CampaignChernihivSecond from './CampaignChernihivSecond.vue'
import CampaignCherkasyDispensary from './CampaignCherkasyDispensary.vue'
import CampaignCentralHospital from './CampaignCentralHospital.vue'
import CampaignGynecologyAssociation from './CampaignGynecologyAssociation.vue'
import CampaignChildrenPolyclinic  from './CampaignChildrenPolyclinic.vue'
import CampaignCityPolyclinic  from './CampaignCityPolyclinic.vue'
import CampaignRegionalCenter from './CampaignRegionalCenter.vue'
import CampaignChernihivFive from './CampaignChernihivFive.vue'
import CampaignChernihivFourth from './CampaignChernihivFourth.vue'
import CampaignMedicalCenter from './CampaignMedicalCenter.vue'
import CampaignMilitaryHospital from './CampaignMilitaryHospital.vue'
import CampaignCentralCityClinic from './CampaignCityClinic.vue'

export default {
    name: 'CampaignResults',
    components: {
        CampaignChildrenClinic,
        CampaignOnco,
        CampaignMaternity,
        CampaignMaternityLviv,
        CampaignChildren,
        CampaignCity,
        CampaignInfectious,
        CampaignMaternityChernihiv,
        CampaignChildrenRegional,
        CampaignFamily,
        CampaignRailway,
        CampaignChildrenCenter,
        CampaignFourthLviv,
        CampaignFirstCherkasy,
        CampaignEight,
        CampaignChernihiv,
        CampaignSecond,
        CampaignChernihivThird,
        CampaignSixthLviv,
        CampaignPrinceLeo,
        CampaignChernihivSecond,
        CampaignCherkasyDispensary,
        CampaignCentralHospital,
        CampaignGynecologyAssociation,
        CampaignChildrenPolyclinic,
        CampaignCityPolyclinic,
        CampaignRegionalCenter,
        CampaignChernihivFourth,
        CampaignChernihivFive,
        CampaignMedicalCenter,
        CampaignMilitaryHospital,
        CampaignCentralCityClinic
    },
    data() {
        return {
            campaignComponent: 'CampaignChernihivFourth',
            resultsMain: [
                { title: "Regional Hospital", component: "CampaignChernihivFourth" },
                { title: 'Medical Center', component: 'CampaignMedicalCenter' },
                { title: 'Military Hospital', component: 'CampaignMilitaryHospital' },
                { title: 'City Clinic', component: 'CampaignCentralCityClinic' },
                
            ],
            resultsSecondary: [
                { title: 'Psychoneurological Hospital', component: 'CampaignChernihivFive' },
                { title: "Regional Center", component: 'CampaignRegionalCenter' },
                { title: "Children's Polyclinic", component: 'CampaignChildrenPolyclinic' },
                { title: "City Polyclinic", component: 'CampaignCityPolyclinic' },
                { title: "Central hospital", component: 'CampaignCentralHospital' },
                { title: "Gynecology Association", component: 'CampaignGynecologyAssociation' },
                { title: "Regional Dispensary", component: 'CampaignCherkasyDispensary' },
                { title: "City Hospital", component: 'CampaignPrinceLeo' },
                { title: "City Clinic", component: 'CampaignSixthLviv' },
                { title: "Second Hospital", component: 'CampaignSecond' },
                { title: "City Hospital", component: 'CampaignChernihivSecond' },
                { title: "Regional Hospital", component: 'CampaignChernihiv' },
                { title: "Eighth Hospital", component: 'CampaignEight' },
                { title: "City Hospital", component: 'CampaignChernihivThird' },
                { title: "Children’s Center", component: 'CampaignChildrenCenter' },
                { title: "Family Hospital", component: 'CampaignFamily' },
                { title: "Railway Hospital", component: 'CampaignRailway' },
                { title: "Children's Hospital", component: 'CampaignChildrenRegional' },
                { title: "City Hospital", component: 'CampaignFourthLviv' },
                { title: "Infectious hospital", component: 'CampaignInfectious' },
                { title: "City Hospital", component: 'CampaignFirstCherkasy' },
                { title: "Maternity Hospital", component: 'CampaignMaternityChernihiv' },
                { title: "Children's Clinic", component: 'CampaignChildrenClinic' },
                { title: "Maternity Clinic", component: 'CampaignMaternityLviv' },
                { title: "City Hospital", component: 'CampaignCity' },
                { title: "Children's Hospital", component: 'CampaignChildren' },
                { title: "Maternity hospital", component: 'CampaignMaternity' },
                { title: "Oncological clinic", component: 'CampaignOnco' }
            ],
            showDropDown: false
        }
    },
    methods: {
        showCampaigns(value) {
            this.$nextTick(() => {
                this.showAllCampaigns = value
            })
        },
        setCampaign(campaign) {
            this.campaignComponent = campaign
            if (this.showDropDown) this.showDropDown = false
        },
        closeDropDown() {
            this.showDropDown = false
        }
    }
}
</script>
